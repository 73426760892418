.window-editor {
  .partition-variables-editor,
  .sort-variables-editor {
    margin-bottom: 30px;
  }
  .variables-editor {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
