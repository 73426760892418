.component-editor {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  height: 100%;
  label:not(.list-group-item) {
    display: inline-block;
    padding-left: 5px;
    border: 1px solid transparent;
    border-radius: 4px;
    padding-right: 3px;
    input[type='checkbox'] {
      margin-right: 3px;
    }
    &.focus {
      border-color: @brand-primary;
    }
  }

  .dynamic-connection {
    .dynamic-connection-checkbox {
      margin-left: 13px;
      margin-top: 20px;
      margin-bottom: 10px;
      display: block;
    }
  }
  .component-editor-footer {
    background-color: #fff;
    position: relative !important;
    padding: 0 40px;
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .component-editor-footer-icon {
      svg {
        transform: translate(0, 5px);
      }

      .modal-icon-use-background {
        pointer-events: none;
        fill: #3d4651;
      }

      .modal-icon-use {
        transform: scale(0.6) translate(15px, 15px);
        fill: transparent;
        stroke: #ffffff;
        stroke-width: 1.5;
        stroke-linecap: round;
        stroke-linejoin: round;

        &.icon-file-mover {
          fill: #ffffff;
        }
      }
    }
    .component-editor-footer-title {
      flex: 1 auto;
      padding: 0 20px;
      font-size: 20px;
      font-weight: 500;

      h3 {
        font-size: 20px;
        font-weight: 500;
        margin: 0;
      }
    }
  }
  .component-editor-body {
    padding: 30px 40px;
    flex: 1 auto;
    overflow-y: auto;

    .form-group {
      small {
        padding: 5px !important;
        &.hint {
          margin: 0 5px !important;
          font-weight: 300;
        }
      }
    }
    .form-group-checkbox {
      .input-checkbox-wrapper {
        padding: 0 10px;
      }
    }
  }
  .refresh-button {
    padding-top: 27px;
    button {
      line-height: 25px;
    }
  }
}

.component-editor-workflow {
  .variables-editor-remove {
    display: none !important;
  }
}

.component-editor-transformation {
  padding: 30px;
}

.component-editor-destination {
  position: relative;
  z-index: 1;
}

.connection-picker {
  .dropdown-menu {
    top: 110%;
  }
  .connection-picker-item {
    display: flex;
    flex-direction: row;
    padding: 5px;
    .connection-picker-item-image {
      width: 32px;
      margin-right: 10px;
      img {
        width: 100%;
      }
    }
    .connection-picker-item-name {
      height: 32px;
      line-height: 32px;
      font-weight: 600;
    }
  }
  .connection-picker-container {
    border: 1px solid @gray-lighter;
    background-color: @gray-lighter;
    border-radius: 4px;
    padding: 3px 10px 5px;
    margin: 10px 0 5px;
  }
  .connection-picker-buttons {
    position: relative;
    padding-top: 10px;
  }
}

.cloud-storage-source-editor {
  .record-type-select {
    overflow: visible;
    width: max-content;
    min-width: 350px;
  }
}

.lambda-transformation-editor {
  .test-code-button {
    float: right;
    margin-bottom: 10px;
  }

  .lambda-transformation-editor-form {
    padding-bottom: 80px;
  }

  h4 {
    margin-bottom: 5px;
  }

  hr {
    margin-top: 0;
  }

  .test-payload-container {
    .col-md-12 {
      overflow-x: auto;

      .mat-mdc-table thead .mat-mdc-header-cell {
        padding: 0 10px !important;
      }
    }
  }

  .batch-size-label {
    margin-bottom: -10px;
    display: block;
  }

  .open-code-editor-dialog,
  .test-code-button {
    width: 150px;
  }

  .CodeMirror-line {
    padding-left: 7px;
  }
  .CodeMirror-linewidget {
    width: 100% !important;
  }
  .lint-error {
    background-color: lighten(@brand-info, 30%);
    color: @brand-danger;
    border: 1px solid lighten(@brand-danger, 10%);
    padding: 10px;
    margin: 7px;
    span.fa {
      margin-right: 10px;
    }
    overflow: auto;
  }
}
