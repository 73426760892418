.modal .modal-dialog {
  .workspace-form,
  .package-workspace-editor {
    .select-picker-mat {
      margin-right: 0;
    }

    .modal-icon-use.small {
      transform: scale(0.6) translate(8px, 8px);
    }

    .step {
      xp-step-index {
        display: none;
      }

      margin-bottom: 60px;

      .step-header .step-header-title {
        padding-left: 0;
      }

      .step-body .step-body-container {
        padding: 0;
      }
    }
  }
}
