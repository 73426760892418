xp-alias-editor-title {
  width: 100%;
  display: block;
  .alias-editor-title {
    display: flex;
    flex-direction: row;
    width: 100%;
    .editor-button {
      flex: 0 auto;
      display: block;
    }
    span {
      display: block;
      flex: 1 auto;
      line-height: 32px;
    }
  }
}
