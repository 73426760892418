.connection {
  .panel {
  }

  .list-group-item {
  }
}

xp-connection {
  display: block;
  border-radius: 0;
  border-bottom: 0 none;
  background: #ffffff;
  padding: 15px;

  &:first-child {
    border-radius: 8px 8px 0 0;
  }
  &:last-child {
    border-radius: 0 0 8px 8px;
    border-bottom: 1px solid @gray-light;
  }
  &:only-child {
    border-radius: 8px;
  }

  .connection-header {
    display: flex;
  }

  .connection-header-icon {
    flex: 0 auto;
    width: 30px;

    .icon {
      width: 24px;
      height: 24px;
    }
  }

  .connection-header-info {
    margin-left: 10px;
    align-items: center;
    display: flex;
    flex-flow: row;
    width: 100%;
  }

  .connection-header-buttons {
    flex: 0 auto;

    .fa {
      font-size: 16px;
    }
  }

  .connection-header-name {
    font-weight: 900;
    font-size: 16px;
    line-height: 30px;
    padding: 0 7px;
  }
}
