@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-ExtraBold.ttf');
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-Bold.ttf');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-Medium.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-Regular.ttf');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-Light.ttf');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Inter';
  src: url('../../fonts/Inter-ExtraLight.ttf');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'OpenSans';
  src: url('../../fonts/OpenSans-Light.ttf');
  font-weight: 400;
  font-style: normal;
}

.load-font-faster-element {
  opacity: 0;
  height: 0;
  width: 0;
}

.load-opens-sans-font-faster {
  font-family: 'OpenSans', sans-serif;
}

.load-inter-300-font-faster {
  font-family: 'Inter', sans-serif;
  font-weight: 300;
}

.load-inter-400-font-faster {
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.load-inter-500-font-faster {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.load-inter-600-font-faster {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
}

.load-inter-700-font-faster {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}

.load-inter-800-font-faster {
  font-family: 'Inter', sans-serif;
  font-weight: 800;
}
