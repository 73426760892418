.base-input {
  width: 100%;
}

.placeholder {
  color: @gray-light;
}

.base-input-control {
  width: 100%;
  border: 0 none;
  font-size: 14px;
  line-height: 26px;
  background-color: transparent;
  padding: 3px 15px 3px 10px;

  &.editable {
    border-color: @gray-light !important;
    color: @text-color !important;
  }
  &.error {
    // border-color: @brand-warning !important;
    // background-color: lighten(@brand-warning, 25%) !important;
  }
  &.textview {
    border-color: @gray-light !important;
    color: @text-color !important;
  }
  &.button {
    border-color: @gray-light !important;
    color: @text-color !important;
    cursor: pointer;
    user-select: none;
  }
  &.readonly {
    user-select: none;
    pointer-events: none;
  }
  &:hover {
    //background-color: @gray-lighter;
  }
  &:focus {
  }
  &::-webkit-input-placeholder {
    .placeholder;
    font-weight: 400;
    font-size: 14px;
  }
  &:-moz-placeholder {
    .placeholder;
    font-weight: 400;
    font-size: 14px;
  }
  &::-moz-placeholder {
    .placeholder;
    font-weight: 400;
    font-size: 14px;
  }
  &:-ms-input-placeholder {
    .placeholder;
    font-weight: 400;
    font-size: 14px;
  }
  &::-ms-input-placeholder {
    .placeholder;
    font-weight: 400;
    font-size: 14px;
  }
  &::placeholder {
    .placeholder;
    font-weight: 400;
    font-size: 14px;
  }
}
