.fields-collection-errors {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.fields-collection-errors-box {
  margin-bottom: 40px;
  position: relative;
  width: 100%;
  margin-top: 20px;

  &-list {
    height: 88px;
    min-height: 0;
    overflow: auto;
    background-color: lighten(@brand-warning, 45%);
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 1 auto;
    border-radius: 4px;
    &-item {
      display: block;
      color: @brand-warning;
      padding: 2px 2px;
      border-radius: 4px;
      &-index {
        display: inline-block;
        min-width: 18px;
        text-align: center;
      }
      &:hover {
        background-color: lighten(@brand-warning, 30%);
        color: @brand-warning;
      }
    }
  }
  &-total {
    position: absolute;
    top: 35px;
    left: -60px;
    height: 20px;
    width: 88px;
    transform: rotate(-90deg);
    background-color: @brand-warning;
    border-radius: 4px;
    color: #fff;
    text-align: center;
  }
  &.empty {
    display: none;
    pointer-events: none;
  }
}
