.shortcut-list {
  padding: 30px;
  .shortcut-group {
    h3 {
      padding: 0;
      margin: 0 0 20px;
    }
    margin-bottom: 20px;
    .shortcut-item {
      display: flex;
      border-bottom: 1px solid @gray-lighter;
      line-height: 2;
      &.header {
        margin-bottom: 0;
      }
      .shortcuts-commands {
        flex: 1 auto;
        min-width: 200px;
        text-align: right;
        font-size: @font-size-base * 1.1;
        padding: 7px 5px 8px 5px;
      }
      .shortcuts-description,
      .shortcuts-header {
        flex: 0 auto;
        text-align: left;
        padding: 9px 5px 8px 5px;
        font-size: @font-size-base * 1.1;
        flex-shrink: 0;
      }
      .shortcut-symbol {
        @radius: 3px;
        background: @gray-lighter;
        padding: 0 9px;
        font-size: @font-size-base;
        font-weight: normal;
        min-width: 24px;
        height: 28px;
        margin: 0 2px;
        border-radius: @radius;
        color: @text-color;
        border: 1px solid @gray-light;
        box-shadow: 0 1px 0 @gray-light;
        text-shadow: none;
        display: inline-block;
        text-align: center;
        vertical-align: middle;
        line-height: 2;
      }
    }
  }
}
