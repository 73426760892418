.help {
  z-index: 10;
  position: inherit;
  align-items: center;

  @media (max-width: @screen-sm) {
    width: 100%;

    &.open {
      display: block;
    }
  }

  .main-wrapper help-menu > & {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: @zindex-select-dropdown;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    padding-bottom: 5px;

    @media (max-width: @screen-sm) {
      left: -100vw;
      transition: all 200ms ease-in;

      &.open {
        width: 300px;
        height: auto;
        margin: auto;
        left: 0;
        right: 0;
      }
    }
  }

  .dropdown-toggle {
    cursor: pointer;
    text-decoration: none;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: 1px;
    line-height: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: transparent;
    color: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 3px;
    margin: 0;
    width: auto;
    padding: 5px;
    height: auto;
    transition: all 200ms ease-in;

    &:hover {
      background-color: #ffffff;
      color: #000000;
      border: 1px solid #ffffff;
    }

    @media (max-width: @screen-sm) {
      width: 100%;
      padding: 30px;
      font-size: 20px;
    }
  }

  .auth-container & {
    .dropdown-toggle {
      background-color: @brand-info;
      color: darken(@brand-info, 45%);
      &:hover {
        background-color: @brand-primary;
        color: darken(@brand-primary, 25%);
      }
    }
  }

  .app help-menu > &,
  .auth-container & {
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: @zindex-select-dropdown;
    > .dropdown-toggle {
      display: flex;
      justify-content: center;
      text-align: center;
      border-radius: 100%;
      height: 50px;
      box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
    }
  }
}

.help-menu {
  li {
    padding: 0;

    .mdc-list-item__primary-text,
    .mat-mdc-menu-item-text {
      display: block;
      height: 48px;
      width: 100%;
    }

    a {
      display: flex;
      align-items: center;
      clear: both;
      font-weight: 400;
      line-height: 1.42857143;
      color: #3f3f3f;
      white-space: nowrap;
      height: 100%;
      padding: 0 16px;

      svg,
      i {
        position: absolute;
        left: 16px;
        height: @line-height-computed;
        margin: auto;
        top: 0;
        bottom: 0;

        &.fa {
          font-size: 18px;
        }
      }
      span {
        padding-left: 25px;
      }
    }
  }
}
