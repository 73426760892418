.execute-sql-editor {
  .loader {
    border: 1px solid @gray-lighter;
    width: 100%;
    padding: 20px 0;
    border-radius: 5px;
    .loader-inner {
      text-align: center;
    }
  }
  .well {
    text-align: center;
  }
  a {
    &.disabled {
      color: @gray-light;
      pointer-events: none;
    }
  }
}
