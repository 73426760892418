.facebook-editor {
  .facebook-fields {
    position: relative;
    border-radius: 5px;
    border: 1px solid @gray-light;
    padding-top: 40px;
    overflow: hidden;
    .facebook-fields-search {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-bottom: 1px solid @gray-light;
      z-index: 1;
      i {
        position: absolute;
        top: 12px;
        left: 11px;
        color: @gray-light;
      }
      input {
        width: 100%;
        border: 0 none;
        padding: 10px 10px 10px 32px;
      }
    }
    .facebook-fields-list {
      max-height: 240px;
      min-height: 240px;
      overflow-y: auto;
      .list-group-item {
        padding-left: 10px !important;
        padding-right: 10px !important;
        cursor: pointer;
        input {
          margin-right: 6px;
        }
        &:hover {
          background-color: lighten(@gray-lighter, 5%);
        }
        .highlighted {
          background-color: lighten(@brand-info, 10%);
        }
        span {
          font-weight: 400;
          color: @text-color;
        }
        .label {
          font-weight: 300;
          color: @gray;
          font-size: 12px;
          background-color: #eee;
        }
        &.selected {
          background-color: lighten(@brand-primary, 45%);
        }
      }
    }
  }
}
