&.clusters {
  &:first-child {
    border-top: 0 !important;
  }
}

@cluster-list-item-image-width: 42px;
@cluster-list-item-actions-width: 10px;
.cluster-list-item(@generic-list-width) {
  &.clusters {
    margin: 0 0 80px 20px;
    width: 245px;
    height: 212px;
    float: left;
    &:first-child {
      border-top: 0;
    }
  }
  .cluster-list-square {
    width: 240px;
    margin-bottom: 85px;
    position: relative;
    background: #ffffff;
    border: 1px solid #eceef0;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.06);
    border-radius: 8px;

    &.selected {
      border: 1px solid @brand-primary;
      .cluster-list-item-header {
        border-bottom: 1px solid @brand-primary;
      }
      .cluster-list-item-body {
        background-color: @brand-primary;
        strong,
        small {
          color: #fff !important;
        }
      }
    }
    .cluster-list-item-header {
      position: relative;
      padding: 8px 14px;
      height: 70px;
      width: 100%;
      flex-direction: column;
      border-bottom: 1px solid #eceef0;
      border-radius: 5px 5px 0 0;
      strong {
        display: block;
        width: 90%;
        font-size: 14px;
        font-weight: 700;
        line-height: 26px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      small {
        display: block;
        width: 100%;
        font-size: 14px;
        font-weight: 300;

        strong {
          display: inline;
          width: auto;
          font-weight: 700;
          font-size: 14px;
          opacity: 0.6;
        }
      }
      i {
        position: absolute;
        top: 14px;
        right: 14px;
        width: 14px;
        height: 14px;
        display: block;
        border-radius: 50%;
        &.pending_terminate,
        &.terminating,
        &.terminated {
          background-color: @brand-warning;
        }
        &.creating,
        &.pending {
          animation: cluster-creation-animation 700ms infinite;
        }
        &.available {
          background-color: #2cce75;
        }
        &.idle {
          background-color: @brand-primary;
        }
        &.error {
          background-color: @brand-danger;
        }
      }
    }
    .cluster-list-item-body {
      min-height: 100px;
      position: relative;
      button {
        position: absolute;
        top: 14px;
        left: 14px;
        padding: 3px;
        font-size: 14px;
        background-color: transparent;
        border: 0 none;
        font-weight: 600;
        color: @gray-dark;
        z-index: 3;
      }
      strong {
        display: block;
        position: absolute;
        top: 12px;
        left: 0;
        right: 14px;
        width: 100%;
        font-size: 60px;
        line-height: 100%;
        font-weight: 300;
        text-align: center;
        z-index: 2;
        &.production {
        }
        &:not(.production) {
          top: 50%;
          transform: translate(0, -50%);
          font-size: 20px;
          font-weight: 600;
        }
      }
      small {
        display: block;
        position: absolute;
        bottom: 10px;
        left: 0;
        right: 0;
        width: 100%;
        font-size: 14px;
        font-weight: 600;
        color: #000000;
        text-align: center;
        z-index: 1;
      }
    }
    .cluster-list-item-footer {
      display: flex;
      flex-direction: row;
      border-radius: 0 0 8px 8px;
      button {
        width: 100%;
        border: 0 none;
        height: 42px;
        padding: 10px 0;
        background-color: #fff;
        border-top: 1px solid #eceef0;
        svg {
          margin: 0 auto;
        }
        &.cluster-list-item-terminate {
          border-bottom-left-radius: 8px;
          &.creating,
          &.pending,
          &.pending_terminate,
          &.terminating,
          &.terminated {
            cursor: default;
            path {
              stroke: @gray-light !important;
              &.toggle {
                stroke: none !important;
                fill: @gray-light !important;
              }
            }
          }
          &:hover:not(.creating):not(.pending):not(.pending_terminate):not(.terminated):not(.error):not(.terminating) {
            background-color: lighten(@brand-danger, 38%);
          }
        }
        &.cluster-list-item-run {
          border-left: 1px solid #eceef0;
          border-bottom-right-radius: 8px;
          &.error,
          &.pending_terminate,
          &.terminating,
          &.terminated {
            pointer-events: none;
            cursor: default;
            path {
              fill: @gray-light !important;
            }
          }
          &:hover:not(.terminated):not(.error):not(.pending_terminate):not(.terminating) {
            background-color: lighten(@brand-success, 38%);
          }
        }
      }
    }
    .cluster-list-item-info {
      position: absolute;
      margin-top: 12px;
      left: 5px;
      right: 5px;
      small {
        color: @gray;
        font-size: 13px;
        font-weight: 300;
      }
      a {
        color: @gray;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@-webkit-keyframes cluster-creation-animation {
  .cluster-creation-animation-frames;
}

@-moz-keyframes cluster-creation-animation {
  .cluster-creation-animation-frames;
}

@-o-keyframes cluster-creation-animation {
  .cluster-creation-animation-frames;
}

@keyframes cluster-creation-animation {
  .cluster-creation-animation-frames;
}

.cluster-creation-animation-frames () {
  0% {
    background-color: @brand-primary;
    border-color: #eceef0;
  }
  50% {
    background-color: #fff;
    border-color: #000;
  }
  100% {
    background-color: @brand-primary;
    border-color: #eceef0;
  }
}

.generic-list-body.clusters {
  max-width: 100%;
  flex-wrap: wrap;
  margin-left: 0;
  border: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 240px);
  column-gap: 80px;
}
