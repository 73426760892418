.packages-picker-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  .packages-picker-form-body {
    flex: 1 auto;
    overflow-y: auto;
    .packages-picker-form-body-content {
      padding: 20px;

      .steps {
        xp-step:nth-child(3) {
          .step-body-container {
            padding: 10px;
          }
        }
      }
      .variables-editor-remove {
        display: none !important;
      }
      .packages-picker-form-variables-validator {
        display: none !important;
      }
    }
  }

  .variables-override-container {
    display: flex;
    align-items: baseline;
    padding-left: 80px;

    .fa {
      margin-left: 5px;
    }
  }
  .packages-picker-form-footer {
    position: relative !important;
    flex: 0 auto !important;
    height: 80px !important;
    min-height: 80px !important;
    .btn {
      margin-left: 20px;
    }
  }
  .tabs {
    flex: 0 auto;
    height: 48px;
    min-height: 48px;
    display: flex;
    padding: 0;
    margin: 0;
    list-style-type: none;
    li {
      flex: 1;
      text-align: center;
      padding: 12px 0;
      text-transform: uppercase;
      font-size: 12px;
      cursor: pointer;
      line-height: 24px;
      border: 1px solid transparent;
      background-color: @gray-lighter;
      strong {
        font-weight: 700;
        margin-right: 5px;
      }
      span {
        font-weight: 400 !important;
      }
      svg {
        transform: translate(-2px, 4px);
      }
      &.active {
        background-color: #fff;
        border-right: 1px solid @gray-light;
        border-bottom: 0 none;
      }
      &.disable {
        cursor: default;
        strong,
        span {
          color: @gray-light;
        }
      }
      &:first-child {
        border-left: 0 none !important;
      }
    }
  }

  .input-checkbox {
    margin-bottom: 20px;
  }
}
