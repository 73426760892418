.url-editor {
  position: relative;
  .input-group-btn {
    vertical-align: top;
  }
  .btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    background-color: @gray-light;
    font-size: 13px;
    font-weight: 600;
    padding: 11px 15px 11px;
    border: 1px solid #cccccc;

    .caret {
      margin-left: 7px;
      color: @gray;
    }
  }
  .form-control {
    border-left: 0 none;
    border-bottom-right-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  .fa {
    position: absolute;
    top: 13px;
    right: -25px;
  }
}

.rest-api-headers {
  position: relative;
  display: flex;
  flex-flow: column;

  .fa.fa-exclamation-circle {
    position: absolute;
    right: -11px;
    top: 55px;
  }

  .input-error-message {
    display: block;
    margin-top: -30px;
    margin-bottom: 20px;
    margin-left: 20px;
  }
}
