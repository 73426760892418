.global-variables-container {
  max-width: 1000px;

  .variables-editor.variables-editor-wrapper {
    padding-bottom: 15px;
    padding-top: 15px;
    margin-top: 30px;
  }

  .btn-success {
    margin-top: 20px;
  }
}

.variables-editor {
  border-radius: 10px;

  .variables-editor-wrapper {
    border-radius: 5px;
  }
  .variables-editor-headers {
    display: flex;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 12px;

    &[hidden] {
      display: none;
    }
    .variables-variable {
      flex: 1 !important;
      padding-left: 65px;
    }
    .variables-expression {
      flex: 1 !important;
      padding-right: 0;
      padding-left: 15px;
    }
    .variables-alias {
      flex: 1 !important;
      padding-right: 0;
      padding-left: 25px;
    }
    .sort-by {
      flex: 0 0 435px !important;
      padding-left: 65px;
    }
    .partition-by {
      flex: 0 0 435px !important;
      padding-left: 65px;
    }
    .sort-direction {
      flex: 0 auto !important;
      padding-left: 25px;
    }
    .cube-type {
      flex: 0 0 165px !important;
      padding-left: 65px;
    }
    .cube-fields {
      flex: 0 0 !important;
    }
    .union-field-1 {
      flex: 0 0 280px !important;
      padding-left: 45px;
    }
    .union-field-2 {
      flex: 0 0 145px !important;
    }
    &.variables-editor-headers-schema {
      padding-left: 60px;
      .schema-name {
        flex: 1 0 !important;
      }
      .schema-alias {
        padding-left: 75px;
        flex: 1 0 !important;
      }
      .schema-datatype {
        padding-left: 70px;
        flex: 1 0 !important;
      }
    }
  }
  &.editable {
    .variables-editor-headers {
      .variables-expression {
        flex: 1 !important;
        padding-right: 15px;
        padding-left: 0;
      }
    }
  }
  .bootstrap-select {
    height: 42px;
    button {
      span {
        color: @text-color;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
  &.package-variables-editor {
    .tabs {
      li {
        border: 0 none !important;
        a {
          border: 0 none !important;
          text-decoration: none;
        }
        &:hover {
          a {
            color: darken(@brand-primary, 10%);
          }
        }
      }
    }
    .tab-content {
      background-color: #fff;
      padding: 15px;
      .tab-pane {
        background-color: @gray-lighter;
        border-radius: 5px;
        padding: 5px;
      }
    }
  }
  .message-info {
    margin: 10px 0 35px 30px;
  }
  .variables-editor-highlight {
    height: 58px;
    border-radius: 5px;
    background-color: @gray-lighter;
  }
  .variables-editor-sortable {
    padding: 5px;
  }
  .variables-editor-empty-text {
    text-align: center;
    padding: 20px 20px;
    h4 {
      padding: 0;
      margin: 0;
      font-size: 16px;
      color: @gray;
    }
  }
  .variables-editor-add {
    padding-right: 35px;
    padding-left: 28px;
    margin-bottom: 5px;
    button {
      text-align: left;
      background-color: @gray-light;
      font-size: 13px;
      padding: 12px 0 12px 12px;
      transition: background-color 150ms ease;
      svg {
        transform: translate(0, -1px);
      }
      span {
        display: inline-block;
        padding-left: 10px;
        line-height: 18px;
      }
      &:hover {
        background-color: @gray-light;
      }
    }
    &.filter-add {
      padding-right: 20px;
      button {
        position: relative;
        flex: 1 auto;
        margin-right: 15px;
        .arrow {
          position: absolute;
          top: 0;
          right: 0;
        }
      }
    }
  }
  .cube-type {
    flex: 0 auto !important;
    width: 100px;
  }
}

.variables-editor-row {
  display: flex;
  padding: 7.5px 0 7.5px 7.5px;
  border-radius: 5px;
  width: 100%;
  z-index: 999999 !important;

  .variables-editor-row-number {
    flex: 0 0 20px;
    font-size: 11px;
    opacity: 0.5;
    line-height: 43px;
    pointer-events: none;
    user-select: none;
    transition: opacity 150ms ease;
  }
  .variables-editor-cell {
    flex: 0 auto;
    &.variables-editor-button {
      button {
        padding: 10px 12px;
      }
    }
    &.variables-editor-move {
      button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        padding: 8px 12px 3px 12px;
        min-height: 43px;
        background-color: @gray-light;
      }
      &.locked {
        button {
          padding: 8px 8px 3px 9px;
        }
      }
    }

    &.variables-editor-hint {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
    }
    &.variables-editor-remove,
    &.variables-editor-undo {
      button {
        height: 42px;
        padding: 2px 10px 0;
        border: none;

        i {
          color: @gray;
        }
        &[disabled] {
          i {
            color: @gray-light;
          }
          &:hover {
            i {
              color: @gray-lighter;
            }
          }
        }
        &:hover {
          i {
            color: @text-color;
          }
        }
      }
    }
  }
  .variables-editor-cell-input,
  .variables-editor-cell-range,
  .variables-editor-cell-offset,
  .variables-editor-cell-ntiles,
  .variables-editor-cell-default-value {
    flex: 1 auto;

    select,
    option,
    input {
      font-size: 15px;
      height: 43px;
      line-height: 43px;
      padding: 0 10px;
      font-family: 'OpenSans', sans-serif;
      letter-spacing: 1.5px;

      &.icon-right {
        padding-right: 35px;
      }
    }
    &.variables-editor-key {
      input {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0 none;
      }
      select {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0 none;
      }
    }
  }
  .variables-editor-cell-checkbox {
    flex: 0 auto;
    width: 30px;
  }
  .variables-editor-cell-range,
  .variables-editor-cell-offset,
  .variables-editor-cell-ntiles,
  .variables-editor-cell-default-value {
    flex: 0 auto;
    input {
      text-align: center;
      width: 50px;
      padding-right: 0;
      padding-left: 0;
      &.input-range-before {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0 none;
      }
      &.input-range-after {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
  input {
    box-shadow: 0 0;
    background-color: rgba(255, 255, 255, 0.75);
    &.invalid {
      background-color: lighten(@brand-warning, 45%);
    }
    &:focus {
      background-color: rgba(255, 255, 255, 1);
      border-color: @gray-light;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 1);
      border-color: @gray-light;
    }
    &.invalid:hover {
      background-color: lighten(@brand-warning, 45%);
    }
    &[disabled] {
      color: #6e6e6e !important;
      -webkit-text-fill-color: #6e6e6e !important;

      &:hover,
      &:focus {
        background-color: #e6e6e6;
        border-color: @gray-light;
      }
    }
    &[readonly] {
      color: #6e6e6e !important;
      -webkit-text-fill-color: #6e6e6e !important;

      &:hover,
      &:focus {
        background-color: #e6e6e6;
        border-color: @gray-light;
      }
    }
  }
  .select-box {
    position: relative;
    select {
      -webkit-appearance: none;
      appearance: none;
      border-color: @gray-light;
      box-shadow: 0px 0 0 0px rgba(0, 0, 0, 0.3);
      user-select: none;
      &:focus {
        #gradient > .horizontal(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));;
      }
    }
    svg {
      position: absolute;
      top: 19px;
      right: 12px;
      pointer-events: none;
    }
    .expression-editor-icon,
    .edit-icon,
    .cancel-icon {
      position: absolute;
      top: 5px;
      right: 5px;
      padding: 10px;
      cursor: pointer;
      color: @gray-light;
      z-index: 1;
      &:hover {
        color: @gray;
      }
    }
  }
  .variables-editor-cell-padding {
    div.cell-container {
      padding-right: 15px;
    }
    svg {
      right: 27px;
    }
  }
  transition: background-color 150ms ease;
  &.danger {
    background-color: lighten(@brand-danger, 30%);
  }
  &.move {
    background-color: lighten(@gray, 35%);
  }
  &.ui-sortable-helper {
    background-color: fade(@gray, 20%);
  }
}
