.job-list-item(@generic-list-width) {
  .job-list {
    padding-left: 40px;
    width: @generic-list-width;
    display: grid;
    grid-template-columns: [job] 180px [package] 1fr [cluster] 200px [runstime] 240px [status] 130px [details] 80px [dropdown] 40px [end];

    @media (max-width: @screen-sm) {
      padding-left: 5px;
      grid-template-columns: [package] 1fr [runstime] 100px [status] 100px [details] 60px [end];
    }

    &.item {
      .job,
      .package,
      .cluster,
      .runtime,
      .status {
        border: none;
        .clickable {
          display: inline-block;
        }
      }

      @media (max-width: @screen-sm) {
        .cluster,
        .job,
        .execution_time {
          display: none;
        }
      }
    }
    &.header {
      border-bottom: 1px solid @gray-lighter;
      padding-bottom: 10px;
      text-transform: uppercase;
      font-size: 12px;
    }
    &.item {
      border-bottom: 2px solid #ddd;
      height: 127px;
      position: relative;
      div {
        &.job {
          display: flex;
          align-items: center;

          @media (max-width: @screen-sm) {
            display: none;
          }

          .job-info-container {
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: center;

            @media (max-width: @screen-sm) {
              align-items: center;
            }
          }

          svg {
            fill: @gray;
            float: left;
            margin-right: 15px;
            margin-bottom: 25px;
            width: 30px;
            height: 30px;

            @media (max-width: @screen-sm) {
              margin: 0 0 5px;
            }
          }
          span {
            color: @gray;
            font-size: 16px;
            font-weight: 300;
            line-height: 20px;
            &.job-date {
              font-size: 13px;
              word-break: break-word;

              @media (max-width: @screen-sm) {
                text-align: center;
              }

              a {
                color: @gray;
                &:hover {
                  text-decoration: underline;
                }
              }
              span {
                font-size: 12px;
              }
            }
            &.job-item {
              display: block;
              a {
                color: @gray-base;
                font-size: 16px;
                font-weight: 600;
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }
        }
        &.package {
          display: flex;
          align-items: center;
          max-height: 120px;
          overflow: hidden;
          padding-bottom: 30px;

          @media (max-width: @screen-sm) {
            display: flex;
            flex-flow: column;
            padding-top: 5px;
            align-items: center;
            justify-content: center;
            padding-bottom: 0;
          }

          .package-name {
            display: flex;
            height: 60px;
            align-items: flex-start;
            padding-top: 15px;
          }

          .package-icon {
            width: 30px;
            min-width: 30px;
            flex: 0 auto;
            margin-right: 10px;

            @media (max-width: @screen-sm) {
              height: 30px;
            }

            svg {
              fill: @gray;
              float: left;
              width: 30px;
              height: 30px;
            }
          }
          .package-name {
            flex-flow: column;
            display: flex;
            word-break: break-word;
            white-space: nowrap;
            overflow: hidden;

            @media (max-width: @screen-sm) {
              max-width: 100%;
              text-align: center;
              width: auto;
              flex: none;
              padding-top: 5px;
            }

            a {
              color: @gray-base;
              font-size: 16px;
              font-weight: 600;
              word-wrap: break-word;
              padding-right: 15px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;

              @media (max-width: @screen-sm) {
                padding: 0;
              }

              &:hover {
                text-decoration: underline;
              }
            }

            .package-version {
              color: #808080;
              font-weight: 300;
              font-size: 12px;
              line-height: 20px;
              width: fit-content;
            }
          }
        }
        &.cluster {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-start;
          padding-bottom: 10px;

          @media (max-width: @screen-sm) {
            padding-top: 5px;
            display: none;
          }
          strong {
            display: block;
            padding-right: 15px;
            color: @gray-base;
            font-size: 16px;
            font-weight: 600;
            word-break: break-all;
            word-wrap: break-word;
            a {
              color: @gray-base;
              &:hover {
                text-decoration: underline;
              }
            }
          }
          p {
            color: @gray;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
          }
        }
        &.runtime,
        &.execution_time {
          display: flex;
          flex-flow: column;
          justify-content: center;
          align-items: flex-start;

          @media (max-width: @screen-sm) {
            padding-top: 5px;
            text-align: center;
            display: flex;
            flex-flow: column;
            align-items: center;
            justify-content: center;
          }

          p {
            font-size: 16px;
            margin-bottom: 0;
            word-break: break-all;
            word-wrap: break-word;
            &.small {
              color: @gray;
              font-weight: 300;
              font-size: 12px;
              line-height: 20px;

              @media (max-width: @screen-sm) {
                line-height: 13px;
                margin-top: 4px;
              }

              .date-value {
                &.full-date {
                  @media (max-width: @screen-sm) {
                    display: none;
                  }
                }

                &.short-date {
                  display: none;

                  @media (max-width: @screen-sm) {
                    display: block;
                  }
                }
              }
            }
          }
        }
        &.status {
          position: relative;
          display: flex;
          align-items: center;

          .status-box,
          .status-label {
            @media (max-width: @screen-sm) {
              margin-left: 5px;
            }
          }
          .status-box {
            height: 75px;
            width: 110px;
            background: #f9fafb;
            border-radius: 8px;
            display: flex;
            flex-flow: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 10px;

            @media (max-width: @screen-sm) {
              width: 110px;
            }

            .job-status-percent {
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: #344054;
            }

            .job-status-indicator-container {
              display: flex;
              flex-flow: row;
              width: 100%;
              align-items: center;

              .jobs-status-indicator-background {
                background: #eceef0;
                border-radius: 4px;
                height: 8px;
                position: relative;
                width: 50px;
                margin-right: 10px;

                .jobs-status-indicator {
                  position: absolute;
                  left: 0;
                  top: 0;
                  border-radius: 4px;
                  height: 8px;
                }
              }
            }

            .job-status-name {
              border-radius: 16px;
              font-size: 12px;
              line-height: 18px;
              text-align: center;
              font-weight: 500;
              background-color: @gray;
              color: #fff;
              user-select: none;
              cursor: default;
              padding: 2px 10px;
            }
            &.failed {
              .job-status-name {
                color: @brand-danger;
                background-color: @brand-danger-background;
              }
              .jobs-status-indicator {
                background-color: @brand-danger;
              }
            }
            &.completed {
              .job-status-name {
                color: @brand-success-green;
                background-color: @brand-success-green-background;
              }
              .jobs-status-indicator {
                background-color: @brand-success-green;
              }
            }
            &.stopping,
            &.pending_stoppage,
            &.stopped {
              .job-status-name {
                color: @brand-warning;
                background-color: @brand-warning-background;
              }
              .jobs-status-indicator {
                background-color: @brand-warning;
              }
            }
            &.running,
            &.queued {
              .job-status-name {
                color: @brand-primary;
                background-color: @brand-info;
              }
              .jobs-status-indicator {
                background-color: @brand-primary;
              }
            }
          }
          .status-label {
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            text-align: center;
            cursor: default;
            user-select: none;
            height: 75px;
            width: 110px;
            background: #f9fafb;
            border-radius: 8px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;

            &.failed {
              color: @brand-danger;
              background-color: @brand-danger-background;
            }
            &.completed {
              color: @brand-success-green;
              background-color: @brand-success-green-background;
            }
            &.stopping,
            &.pending_stoppage,
            &.stopped {
              color: @brand-warning;
              background-color: @brand-warning-background;
            }
            &.running,
            &.queued {
              color: @brand-primary;
              background-color: @brand-info;
            }
          }
        }

        &.details {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        &.dropdown {
          @media (max-width: @screen-sm) {
            display: none;
          }
        }

        .status-button {
          font-size: 13px;
          color: @brand-primary;
          line-height: 22px;
          user-select: none;
          display: flex;
          align-items: center;
          font-weight: 500;
          cursor: pointer;

          @media (max-width: @screen-sm) {
            transform: rotate(-90deg);
          }

          &:hover {
            text-decoration: underline;
          }

          .icon {
            width: 15px;
            margin-right: 5px;
          }
        }
      }
    }
    .status-progress-bar {
      position: absolute;
      bottom: -2px;
      width: 0;
      left: 0;
      height: 2px;
      transition: width 750ms;

      &.progress-bar-primary {
        background-color: @brand-primary;
      }
      &.progress-bar-success {
        background-color: @brand-success-green;
      }
      &.progress-bar-info {
        background-color: @brand-info;
      }
      &.progress-bar-warning {
        background-color: @brand-warning;
      }
    }
    // bottom left indicator
    &.failed,
    &.completed,
    &.running,
    &.stopping,
    &.pending_stoppage,
    &.stopped,
    &.queued {
      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-right: 10px solid transparent;
      }
    }
    &.failed {
      &:before {
        border-bottom: 10px solid @brand-danger;
      }
    }
    &.completed {
      &:before {
        border-bottom: 10px solid @brand-success-green;
      }
    }
    &.stopping,
    &.pending_stoppage,
    &.stopped {
      &:before {
        border-bottom: 10px solid @brand-warning;
      }
    }
    &.running,
    &.queued {
      &:before {
        border-bottom: 10px solid @brand-primary;
      }
    }
  }
}
