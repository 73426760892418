.job-errors {
  padding: 20px 30px;
  flex: 1 auto;
  display: flex;
  flex-flow: column;

  .explain-error-container {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    align-items: center;

    @media (max-width: @screen-sm) {
      flex-flow: column;
    }
  }

  .explain-error {
    min-width: 200px;
    margin-right: 20px;
    border-radius: 8px;
    width: 760px;

    @media (max-width: @screen-sm) {
      min-width: 0;
      width: 100%;
    }
    .fa {
      margin-right: 5px;
    }

    &.summary-container {
      display: flex;
      flex-flow: column;
    }

    &.summary {
      color: @brand-danger;
      background-color: @brand-danger-background;
      padding: 10px;
    }

    .explain-error-title {
      display: block;
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 15px;
    }
  }

  .action-items {
    display: flex;
    flex-flow: column;
    color: @brand-neutral;
    border-color: @brand-neutral;
    background-color: @brand-neutral-background;
    padding: 10px;

    .action-item {
      display: block;
    }
  }

  .loader {
    margin-left: 500px;
  }

  .btn {
    height: 40px;
    width: 200px;

    @media (max-width: @screen-sm) {
      margin-top: 20px;
    }
  }

  .feedback-container {
    display: flex;
    flex-flow: column;
    width: 200px;
    align-items: center;

    &.help-container {
      align-items: flex-start;
      width: 760px;
      border-color: #4a98f4;
      background-color: #f9fafb;
      padding: 10px;
      border-radius: 8px;
      color: #282828cc;
    }

    .loader {
      margin: 0;
    }

    .feedback-text {
      font-size: 14px;
      opacity: 0.8;
      text-align: center;
    }

    .feedback-buttons {
      display: flex;
      flex-flow: row;
      justify-content: center;
      margin-top: 5px;
      background: #e7e7e7;
      width: fit-content;
      border-radius: 8px;

      .fa {
        cursor: pointer;
        font-size: 20px;
        transition: all 0.2s ease-in-out 0s;
        padding: 5px 15px;
        border-radius: 8px;

        &:first-child {
          border-right: 1px solid #cccccc;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          color: @brand-success-green;
        }

        &:nth-child(2) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          color: @brand-danger;
        }

        &:hover {
          background: rgba(143, 143, 143, 0.2);
        }
      }
    }

    .feedback-help-title {
      font-weight: 600;
      margin-bottom: 5px;
      font-size: 15px;

      .fa {
        margin-right: 5px;
      }
    }

    .feedback-help-text {
      .feedback-help-text-link {
        cursor: pointer;
        color: @link-color;
        text-decoration: underline;
        transition: all 0.2s ease-in-out 0s;

        &:hover {
          color: darken(@link-color, 20%);
        }
      }
    }
  }

  pre {
    margin: 0 !important;
    border-radius: 0 !important;
    display: flex !important;
    min-height: 100%;
    flex-direction: column;
    white-space: break-spaces;

    .highlight-error {
      color: #ff5454;
      margin: 10px 0;
      font-family: @font-family-monospace;
    }
  }
}
