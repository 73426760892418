.hook-form {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .form-group .form-group {
    margin-bottom: 0;
  }

  .hook-form-body {
    flex: 1 auto;
    overflow-x: hidden;
    overflow-y: auto;

    .mat-slide-toggle {
      margin-top: 8px;
    }
  }
  .hook-form-footer {
    position: static !important;
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
  }
  h2 {
    margin: 10px 0 30px 0;
  }
  .hook-type-header {
    display: flex;
    padding-bottom: 30px;
    .hook-logo {
      float: left;
      margin-right: 20px;
      width: 50px;
      height: auto;
      align-self: flex-start;
    }
    strong {
      font-size: 18px;
      display: block;
      padding: 0 0 5px;
    }
  }
  .hook-groups-list {
    display: flex;
    flex-wrap: wrap;
    .hooks-group-item {
      flex: 1 auto;
      width: 32%;
      margin-right: 1%;
      margin-bottom: 30px;
      strong {
        font-size: 21px;
        font-weight: 300;
        display: block;
        margin-bottom: 20px;
      }
      .hook-item {
        cursor: pointer;
        margin: 10px 0;
        padding: 10px;
        border-radius: 3px;
        img {
          width: 32px;
          margin-right: 5px;
          //filter: grayscale(100%);
        }
        span {
          font-size: 14px;
        }
        &:hover {
          background-color: @list-group-hover-bg;
          img {
            filter: none;
          }
        }
      }
    }
  }
  .loader {
    margin: 50px;
  }
  .hook-events-editor {
    margin: 20px 0 10px;
    h4 {
      padding: 0;
      margin: 20px 0;
    }
    .hook-events {
      padding: 10px 0;
      input[type='checkbox'] {
        margin-right: 4px;
      }
      .hook-events-children {
        padding-left: 18px;
        .checkbox {
          font-weight: 400;
        }
      }
    }
  }
}
