.connections {
  &.generic-list-body {
    border-bottom: 1px solid #cccccc;
  }

  .connections-form {
    display: none;
    h3 {
      img {
        width: 32px;
        margin: -5px 5px 0 0;
      }
      span {
        line-height: 32px;
      }
    }
    .checkbox {
      label {
        padding-left: 0;
      }
    }
  }

  .loader {
    margin: 20px auto;
  }
}
