.account {
  margin: 37px;

  .gavatar {
    text-align: center;

    img {
      background-color: #fff;
      padding: 5px;
      border: 1px solid #ccc;
      margin-bottom: 10px;
    }
  }

  .invite-email {
    width: 320px;
  }

  th {
    text-align: center;
    background-color: lighten(@brand-primary, 40%);
    border-bottom-width: 0 !important;
  }
  td {
    vertical-align: middle !important;
    &:not(:first-child) {
      text-align: center;
    }

    &.user {
      padding: 10px;
      img {
        float: left;
        width: 42px;
        height: 42px;
        margin-right: 10px;
      }
    }
  }
}
