@account-list-item-image-width: 42px;
@account-list-item-actions-width: 340px;
.account-list-item(@generic-list-width) {
  .account-list-item {
    position: relative;
    padding: 35px;
    grid-template-columns: 100px 1fr 80px;

    @media (max-width: @screen-sm) {
      grid-template-columns: 80px 1fr 40px;
    }

    .account-body {
      display: flex;
      flex-flow: column;
      width: 100%;
    }

    .account-item-name {
      margin-bottom: 5px;
      a {
        color: @gray-base;
        font-size: 16px;
        font-weight: 600;
        word-wrap: break-word;
        word-break: break-all;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .account-info {
      color: darken(@gray-light, 20%);
      line-height: 1;
      margin: 0;
      font-weight: 300;
    }
  }
}
