.dropdown-menu {
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.175);
  padding: 0;
  margin: 0;
  min-width: 100%;
  li span.text-warning {
    color: @brand-warning;
  }
  li span.text-danger {
    color: @brand-danger;
  }
  li span.text-success {
    color: @brand-success;
  }
  li span.text-primary {
    color: @brand-primary;
  }
  li {
    a {
      padding: 10px 20px;
    }
  }

  .dropdown-header {
    padding: 10px 20px 5px 20px;
    span {
      font-size: @font-size-base;
    }
  }
}

.dropdown-menu .divider {
  margin: 0;
}

.dropdown-toggle:hover,
.dropdown-toggle:focus,
.dropdown-toggle:active {
  outline: 0 !important;
  border-color: @brand-primary;
  background-color: #e6e6e6;
}
