.salesforce-source-editor {
  .refresh-button {
    padding-top: 27px;
    button {
      line-height: 25px;
    }
  }
  .chunk-size {
    margin-bottom: 10px;
  }
}
