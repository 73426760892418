.component-form {
  .list-group {
    li {
      border: 0 none;
      cursor: pointer;
      background-color: @body-bg;
      &.active {
        color: @brand-primary;
        &:hover {
          background-color: transparent;
        }
      }
    }
  }
  .component-form {
    strong {
    }
  }
}
