@schema-importer-item-height: 28px;
.visible {
  .schema-importer {
    margin-left: -90px;
    margin-right: -60px;
  }
}

schema-importer {
  .mat-mdc-row,
  .mat-mdc-select,
  .base-input {
    height: 28px !important;
    min-height: 28px;
  }

  .mat-mdc-select {
    padding-left: 0;
  }

  .base-input-control {
    font-size: 13px;
    line-height: 23px;
    padding: 3px;
  }
}

.schema-importer-select.mat-select-panel {
  mat-option.mat-mdc-option {
    height: 36px;

    .mat-mdc-option-text {
      font-size: 12px;
    }
  }
}

.placeholder() {
  font-weight: 300;
  font-size: 13px;
  color: @gray-light;
}

.schema-importer-table-tabs {
  display: flex;
  flex-direction: row;
  background-color: #ffffff;
  padding: 2px 0 0 3px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  height: 32px;
  border: 1px solid #cccccc;
  border-bottom-style: none;
  background-color: #f9fafb;

  .schema-importer-table-tab {
    height: 32px;
    flex: 0 auto;
    padding: 4px 15px 7px;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
    cursor: pointer;
    color: @gray;
    margin-right: 3px;

    span {
      user-select: none;
      display: inline-block;
      font-size: 13px;
      font-weight: 300;
      color: @gray;
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.75);
      span {
        color: @gray-dark;
      }
    }
    &.active {
      cursor: default;
      pointer-events: none;
      background-color: #fff;

      span {
        color: @brand-primary;
        font-weight: 600;
        border-bottom: 2px solid @brand-primary;
      }
    }
  }
}

.schema-importer-table {
  overflow: hidden !important;
  .schema-importer-table-search {
    position: relative;
    height: 32px;
    .schema-importer-table-search-input {
      padding-top: 5px;
      border: 0 none; //border-bottom: 1px solid @gray-lighter;
      width: 100%;
      height: 32px;
      max-height: 32px;
      padding-left: 28px;
      padding-right: 28px;
      line-height: 40px;
      z-index: 0;
      &::-webkit-input-placeholder {
        .placeholder;
      }
      &::-moz-placeholder {
        .placeholder;
      }
      &:-ms-input-placeholder {
        .placeholder;
      }
      &:-moz-placeholder {
        .placeholder;
      }
    }
    .schema-importer-table-search-icon {
      position: absolute;
      z-index: 1;
      top: 10px;
      left: 7px;
      color: @gray-light;
      pointer-events: none;
    }
    .schema-importer-table-cancel-icon {
      position: absolute;
      z-index: 1;
      top: 7px;
      right: 7px;
      color: @gray-light;
      cursor: pointer;
    }
  }
  table {
    margin-bottom: 0;
    max-width: 100%;
    background: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);

    thead {
      tr {
        th {
          background: #eceef0;
          border-bottom: 1px solid #eceef0;
          border-top: 1px solid #eceef0;
          text-align: center;
          padding: 6px 4px;
          font-size: 13px;
          font-weight: 600;

          &.header-field-index {
            width: 32px;
            max-width: 32px;
            min-width: 32px;
          }
          &.header-field-move {
            width: 25px;
            min-width: 25px;
            max-width: 25px;
          }
          &.header-field-name {
          }
          &.header-field-alias {
          }
          &.header-field-data-type {
            width: 85px !important;
            min-width: 85px !important;
            max-width: 85px !important;
          }
          &.header-field-add-between,
          &.header-field-remove,
          &.header-field-add {
            width: 25px !important;
            min-width: 25px !important;
            max-width: 25px !important;
          }
        }
        td {
          color: @gray;
          font-weight: 300;
          font-size: 12px;
          padding: 1px 3px;
        }
        &.table-headers {
          th {
            border-top: 1px solid @gray-lighter;
            background-color: #fff;
            color: @gray;
            text-align: center;
            padding: 4px;
            font-size: 11px !important;
            &:first-child {
              border-left: 0 none;
            }
          }
        }
      }
    }
    tbody {
      tr {
        height: @schema-importer-item-height;
        max-height: @schema-importer-item-height;
        th,
        td {
          position: relative;
          height: @schema-importer-item-height;
          max-height: @schema-importer-item-height;
          line-height: @schema-importer-item-height;
          padding: 0 4px;
          border-right: 1px solid @gray-lighter;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 13px;
          input {
            padding-left: 3px;
            border: 0 none;
            height: @schema-importer-item-height;
            max-height: @schema-importer-item-height;
            line-height: @schema-importer-item-height;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 100%;
            background-color: transparent;
          }
          select {
            width: 105%;
            background-color: transparent;
            border: 0 none;
            border-radius: 0;
            padding: 0;
            color: @gray;
            text-transform: uppercase;
            margin: 0 -4px;
            option {
              padding: 0;
              color: @gray;
            }
          }
          &.field-name {
            min-width: 160px;
            .field-name-text {
              position: absolute;
              width: calc(100% - 3px);
              top: 0;
              right: 0;
              bottom: 0;
              left: 5px;
              display: block;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
          &.invalid {
            background-color: lighten(@brand-warning, 20%);
          }
          &.field-btn-sm {
            text-align: center;
            width: 25px !important;
            min-width: 25px !important;
            max-width: 25px !important;
            padding: 0 !important;
            i {
              cursor: pointer !important;
              width: 23px !important;
              height: 23px !important;
              line-height: 23px !important;
            }
            &:hover {
              i {
                color: @brand-danger !important;
              }
            }
          }
          &:last-child {
            border-right: 0;
          }
          &.field-index {
            text-align: center;
            font-weight: 400;
            min-width: 32px;
          }
          &.field-add-between {
            i {
              transform: translateY(2px);
            }
            &:hover {
              i {
                color: darken(@brand-success, 20%) !important;
              }
            }
          }
          &.field-move {
            cursor: move;
            &.field-move-disable {
              cursor: default;
              pointer-events: none;
              i {
                display: none;
              }
            }
            min-width: 32px;
          }
          &.field-data-type {
            font-size: 12px;
            text-transform: uppercase;
            width: 85px !important;
            min-width: 85px !important;
            max-width: 85px !important;
            span {
              display: inline-block;
              padding: 0 3px;
              pointer-events: none;
              user-select: none;
            }
            select {
              option {
                text-transform: uppercase;
              }
            }

            .mat-mdc-select-value {
              font-size: 12px;
              text-transform: uppercase;
              width: 60px !important;
              margin-left: -3px;
            }

            .mat-mdc-select {
              min-height: 28px;
            }
          }
          .highlighted {
            background-color: lighten(@brand-info, 10%);
          }
        }
        &:first-child {
          th,
          td {
            border-top: 0;
          }
        }
        &.added {
          opacity: 0.2;
          pointer-events: none;
          cursor: pointer;
        }
        &.selected-list-item-highlight {
          td {
            background-color: lighten(@brand-info, 20%);
          }
        }
        &.ui-sortable-helper {
          background-color: #fff;
          box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
        }
      }
    }
  }
}

.columns-schema-container {
  display: flex;
  width: 100%;
  flex-flow: column;

  &.disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
  }

  .errors-notify {
    position: inherit !important;
    margin: 0 10px 30px 10px !important;
  }
}

.columns-schema {
  display: flex;
  width: 100%;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: 5px;

  .columns-schema-items-container {
    display: flex;
    width: 100%;
    flex-flow: row;
    justify-content: center;
    align-items: flex-start;
    margin-bottom: 20px;
  }

  .data-preview {
    width: 100%;
    height: 480px;
    overflow: auto;
    border-radius: 10px;

    &.schema-importer {
      height: 430px;
    }
  }

  .split-handler {
    width: 1px;
    background-color: rgba(0, 0, 0, 0.05);
    height: 90%;
  }

  .connection-columns-search {
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    background: #ffffff;
    border: 1px solid #eceef0;
    border-right-color: #cccccc;
    border-left-color: #cccccc;

    .form-control {
      border: none;
      width: calc(100% - 20px);
    }

    .fa {
      color: #cccccc;
      pointer-events: none;
      margin-left: 5px;
    }
  }

  .columns-schema-item {
    width: 50%;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    margin: 0 10px;
    border-radius: 10px;

    table {
      border: 1px solid #cccccc !important;
    }

    connection-schema {
      width: 100%;
    }

    .columns-schema-header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      padding: 10px;
      height: 54px;
    }

    .field-btn-sm {
      cursor: pointer;
      width: 25px !important;
      padding-left: 10px !important;
      padding-right: 10px !important;

      &.disabled {
        cursor: auto;
        pointer-events: none;
      }
    }

    .field-type {
      width: 80px !important;
    }

    .field-name {
      background-color: rgba(0, 0, 0, 0.05);
      font-weight: 600;
      min-width: 140px;
      max-width: 140px;
    }

    .field-alias {
      min-width: 150px;
    }

    .field-sort {
      cursor: pointer;
      color: @gray-light;
      font-size: 12px;
      &:hover {
        color: @gray;
      }
    }

    .empty-list {
      width: 100%;
      padding: 20px;
      text-align: center;
      border: 1px solid #cccccc;
      border-top-style: none;
      background-color: #ffffff;
    }
  }
}

.mat-elevation-z8 {
  box-shadow: none !important;
  border: none !important;
}

.mat-mdc-paginator {
  border: none;
}

.mat-mdc-row {
  height: 28px !important;

  &.selected,
  &.disabled {
    opacity: 0.2;
    pointer-events: none;
    cursor: not-allowed;
  }

  &.error {
    .field-alias {
      background-color: #ffe8d4 !important;
    }
  }
}

.mat-mdc-header-row {
  height: 28px !important;
}

.mat-mdc-header-cell {
  text-align: center !important;
  border: none;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  color: #344054;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background: #d9d9d9;
  border-radius: 10px;
}

.btn-add-to-end {
  padding: 3px 10px !important;
  margin: 15px 0 20px 0;

  i {
    margin-right: 2px;
    font-size: 10px;
    cursor: pointer;
  }
}

.mat-mdc-paginator-container {
  justify-content: flex-start !important;
}
