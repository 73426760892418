.file-names-pattern {
  .file-name {
    margin-bottom: 10px;
    width: 380px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    &:last-child {
      margin-bottom: 0;
    }

    .fa {
      margin-right: 5px;
    }
  }
}
