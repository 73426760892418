@errorBase: @brand-warning;
@errorLight: lighten(@errorBase, 30%);
@errorText: @errorBase;

@successBase: darken(@brand-success, 10%);
@neutralBase: @brand-primary;

.input-error-message {
  display: block;
}

textarea,
select,
input[type='text'],
input[type='email'],
input[type='password'],
input[type='number'],
.form-control.ng-invalid {
  &.ng-invalid.ng-touched.ng-dirty {
    border-color: @errorBase;
    background-color: lighten(@brand-warning, 45%) !important;
    color: @brand-warning;
  }
}

.error-message,
.input-error-message {
  color: @errorBase;
  font-style: italic;
  padding: 5px 0 0 0;

  &.higher-message {
    margin-top: -10px;
    margin-bottom: 10px;
    padding: 0;
    display: block;
  }
}

.form-control {
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }

  &:hover {
    border-color: @neutralBase;
  }
}

.bootstrap-select {
  &:focus,
  &:hover {
    outline: none;

    & > button.dropdown-toggle {
      border-color: @neutralBase;
    }
  }

  & > button.dropdown-toggle:focus {
    outline: none !important;
    border-color: @neutralBase;
  }

  & > button {
    font-size: @font-size-base !important;
  }
}

@inactive: @gray-light;

.pwd-strength {
  font-size: 0;
  margin-top: 10px;
  .pwd-description {
    display: none;
    font-size: @font-size-small;
    text-align: right;
    margin-top: 5px;
  }
}
.pwd-strength.pwd-very-weak,
.pwd-strength.pwd-weak,
.pwd-strength.pwd-better,
.pwd-strength.pwd-good,
.pwd-strength.pwd-strong {
  .pwd-description {
    display: block;
  }
}
.pwd-mark-weak,
.pwd-mark-better,
.pwd-mark-good,
.pwd-mark-strong {
  background-color: @inactive;
  color: @inactive;
  display: inline-block;
  height: 2px;
  margin: 0 2px;
  width: ~'calc(25% - 4px)';
}
.pwd-empty {
  color: @inactive;
  .pwd-mark-weak,
  .pwd-mark-better,
  .pwd-mark-good,
  .pwd-mark-strong {
    background-color: @inactive;
  }
}

.pwd-very-weak {
  color: @inactive;
  .pwd-mark-weak,
  .pwd-mark-better,
  .pwd-mark-good,
  .pwd-mark-strong {
    background-color: @inactive;
  }
}
.pwd-weak {
  color: @brand-danger;
  .pwd-mark-weak {
    background-color: @brand-danger;
  }
  .pwd-mark-better,
  .pwd-mark-good,
  .pwd-mark-strong {
    background-color: @inactive;
  }
}
.pwd-better {
  color: @errorBase;
  .pwd-mark-weak,
  .pwd-mark-better {
    background-color: @errorBase;
  }
  .pwd-mark-good,
  .pwd-mark-strong {
    background-color: @inactive;
  }
}
.pwd-good {
  color: @successBase;
  .pwd-mark-weak,
  .pwd-mark-better,
  .pwd-mark-good {
    background-color: @successBase;
  }
  .pwd-mark-strong {
    background-color: @inactive;
  }
}
.pwd-strong {
  color: @brand-success-green;
  .pwd-mark-weak,
  .pwd-mark-better,
  .pwd-mark-good,
  .pwd-mark-strong {
    background-color: @brand-success-green;
  }
}
