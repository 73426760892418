.name-description-editor {
  button {
    padding: 0;
    border: 0;
    text-decoration: none;
    &:focus,
    &:active,
    &:hover {
      text-decoration: none;
    }
    span {
      text-decoration: none;
    }
  }
  .description-toggle {
    div {
      cursor: pointer;
      span {
        position: relative;
        color: @brand-primary;
        padding-left: 25px;
        font-weight: 500;
        line-height: 20px;

        &:before {
          font-family: 'FontAwesome', sans-serif;
          left: 5px;
          position: absolute;
          top: -3px;
          font-size: 20px;
        }
        &.add {
          &:before {
            content: '+';
          }
        }
        &.remove {
          &:before {
            content: '-';
          }
        }
      }
    }
  }
  textarea {
    min-width: 100%;
    max-width: 100%;
  }
  .form-group {
    overflow: hidden;
  }
}
