.rest-api-source-editor {
  .CodeMirror-gutters {
    width: 29px !important;
  }

  .fields-collection-editor.standalone {
    background: #ffffff;
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 10px;

    &.with-border {
      border: 1px solid #cccccc;
      border-radius: 8px;
    }

    .field-picker,
    .editor-picker {
      width: 100%;
    }
  }

  .code-editor {
    position: relative;

    .editor-button {
      position: absolute;
      z-index: 2;
      right: 5px;
      top: 5px;
    }
  }

  .auth-token-value {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .add-to-headers {
    margin-left: 10px;
  }
}

.rest-api-destination-editor {
  hr {
    margin: 10px 0;
  }

  h4 {
    &.middle-header {
      margin-top: 30px;
    }
  }
}
