.launchpad {
  padding-top: 50px;
  min-height: 100vh;
  background: #f9fafb;

  @media (max-width: @screen-sm) {
    padding-top: 0;
  }

  .panel {
    border: 0 none;
    margin: 0 auto 70px;
    width: 500px;
    border-radius: 8px;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1);
    padding: 20px;

    @media (max-width: @screen-sm) {
      width: 100vw;
    }

    .loader {
      padding: 50px 0;
    }
    .panel-heading {
      background: #ffffff;

      .apps-button {
        cursor: inherit;
        border-left: none;
        padding: 0;
        margin-bottom: 20px;

        .icon {
          width: 30px;
          height: 30px;
          background-size: 30px 30px;
        }
      }

      .welcome-text {
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 130%;
        letter-spacing: -0.02em;
        color: #3d4651;
      }

      .welcome-description {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 160%;
        color: #8a919c;
        margin-top: 10px;
      }
    }

    .panel-body {
      border: 1px solid #eceef0;
      border-radius: 8px;
      padding: 0;
      margin: 20px 0;

      .list-group {
        padding: 0;
        margin: 0;
        overflow-y: scroll;
        max-height: 500px;

        @media (max-width: @screen-sm) {
          max-height: calc(100vh - 370px);
        }

        @media (max-height: 900px) {
          max-height: calc(100vh - 400px);
        }

        .list-group-item {
          border-top: 1px solid #eceef0;
          border-bottom: none 0;
          padding: 0;
          cursor: pointer;
          margin: 0;
          width: 100%;

          &:first-child {
            border-top: none;
          }

          &:hover {
            background-color: #f5f7f9;

            .login-link {
              display: flex;
            }
          }

          .list-group-item-link {
            width: 100%;
            border-top: 1px solid #eceef0;
            border-bottom: none 0;
            padding: 20px;
            cursor: pointer;
            display: grid;
            grid-template-columns: 60px 1fr 100px;
            align-items: center;
          }

          .avatar-icon {
            margin-bottom: -7px;
            filter: drop-shadow(3px 3px 2px rgba(16, 24, 40, 0.08));
          }
          .account-name {
            padding: 0;
            margin: 0;
            line-height: 44px;
            font-weight: 400;
          }

          .login-link {
            display: none;
            padding-left: 15px;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            text-align: center;
            color: #177af0;
            align-items: center;

            .fa {
              margin-left: 5px;
            }
          }
        }
      }

      .new-account-button {
        font-weight: 500;
        font-size: 16px;
        line-height: 18px;
        color: #3d4651;
        padding: 20px;
        display: flex;
        align-items: center;
        border: none;
        border-top: 1px solid #eceef0;
        width: 100%;
        border-top-right-radius: 0;
        border-top-left-radius: 0;

        &:hover {
          background: #f5f7f9;
        }

        svg {
          margin-right: 20px;
        }
      }
    }

    .panel-footer {
      width: 100%;
      display: flex;
      justify-content: space-between;
      background-color: #ffffff;

      .btn-logout {
        svg {
          margin-right: 10px;
        }
      }

      .powered-by {
        width: 100px;
        height: 32px;
      }
    }
  }
}
