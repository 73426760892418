.hook-list-item(@generic-list-width) {
  .hook-list-item {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 35px;
    justify-content: space-between;
    grid-template-columns: 1fr 80px;

    @media (max-width: @screen-sm) {
      grid-template-columns: 1fr 40px;
    }

    .hook-container {
      display: flex;
      flex-flow: row;
    }

    .hook-body {
      .hook-body-container {
        margin-bottom: 5px;
        word-break: break-all;
        word-wrap: break-word;
      }
      .hook-name {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
      .inactive {
        color: @gray;
        font-weight: 300;
        font-size: 12px;
        line-height: 20px;
        margin-left: 3px;
      }
      .hook-type {
        color: #999999;
        line-height: 1;
        margin: 0;
        font-weight: 300;
      }
    }
    .hook-img {
      img {
        width: 50px;
        margin-right: 20px;
      }
    }
    .loader {
      margin-right: 20px;
      margin-top: 5px;
    }
    .alert {
      margin: 0 15px;
    }
  }
}
