.packages-selector {
  .packages-selector-item {
    border-bottom: 1px solid @gray-lighter;
    //background-color: lighten(@gray-lighter, 8%);
    &:last-child {
      border-bottom: 0 none;
      border-radius: 0 0 5px 5px;
    }
    .packages-selector-item-variables-wrapper {
      display: none;
      //box-shadow: inset 0px 0px 15px rgba(0, 0, 0, 0.1);
      background-color: #fff;
      padding: 20px;
      .variables-editor-headers {
        .variables-expression {
          padding-right: 0 !important;
          padding-left: 15px !important;
        }
      }
      .variables-editor-remove {
        display: none !important;
      }
    }
    .packages-selector-item-variables {
      border-left: 1px solid transparent !important;
      border-right: 1px solid transparent !important;
    }
    &.open {
      background-color: lighten(@gray-lighter, 5%);
      .packages-selector-item-variables {
        background-color: #fff;
        border-left: 1px solid @gray-lighter;
        border-right: 1px solid @gray-lighter;
      }
      .packages-selector-item-name,
      .packages-selector-item-date,
      .packages-selector-item-remove {
        border-bottom: 1px solid @gray-lighter;
      }
    }
  }
  .packages-selector-list {
    border: 1px solid @gray-light;
    border-top: 0 none;
    //border-bottom: 0 none;
    border-radius: 0 0 5px 5px;
    .packages-selector-item {
      .packages-selector-item-variables,
      .packages-selector-item-remove,
      .packages-selector-item-icon,
      .packages-selector-item-name,
      .packages-selector-item-date {
        padding: 0 12px;
      }
      .packages-selector-item-variables,
      .packages-selector-item-remove {
        display: block;
        padding: 0 10px;
        i {
          color: @gray-light;
        }
      }
      .packages-selector-item-remove {
        margin-right: 6px;
        i {
          color: @brand-danger;
        }
      }
      .packages-selector-item-icon {
        width: 24px;
        height: 30px;
        padding: 7px !important;
      }
    }
    .packages-selector-item-variables-wrapper {
      display: block;
      .modal-footer {
        border-radius: 0 0 5px 5px;
      }
    }
  }
  .alert {
    margin-top: 20px;
  }
  &.packages-selector-empty {
    .packages-selector-list {
      border: 0 none;
    }
  }
  &.packages-selector-full {
    .packages-selector-list {
      border-radius: 5px;
      border: 1px solid @gray-light;
    }
  }
  svg {
    color: #cccccc !important;
    fill: #8c8c8c !important;
  }
}

.packages-selector-item-wrapper {
  display: flex;
  flex-direction: row;
  .packages-selector-item-icon,
  .packages-selector-item-name,
  .packages-selector-item-date,
  .packages-selector-item-variables,
  .packages-selector-item-remove {
    line-height: 42px;
    cursor: pointer;
  }

  .packages-selector-item-variables,
  .packages-selector-item-remove {
    display: none;
    cursor: pointer;
    &:hover {
      i {
        color: @text-color;
      }
    }
  }

  .packages-selector-item-icon {
    flex: 0 auto;
    width: 30px;
    height: 30px;
    padding: 6px 0 0 0 !important;
    svg {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
  }

  .packages-selector-item-name {
    flex: 1 auto;
    font-weight: 600;
  }

  .packages-selector-item-date,
  .packages-selector-item-variables,
  .packages-selector-item-remove {
    flex: 0 auto;
  }
}
.packages-selector-generic-list {
  .generic-list-item {
    cursor: pointer;
  }

  .package-list-item {
    pointer-events: none;
  }

  .package-run-job,
  .package-menu {
    display: none !important;
  }
}
