.offline-ui .offline-ui-retry:before {
  content: 'Try now';
}
.offline-ui.offline-ui-up .offline-ui-content:before {
  content: 'Your computer is back online.';
}
@media (max-width: 1024px) {
  .offline-ui.offline-ui-up .offline-ui-content:before {
    content: 'Your computer is back online.';
  }
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-up .offline-ui-content:before {
    content: 'Your computer is back online.';
  }
}
.offline-ui.offline-ui-down .offline-ui-content:before {
  content: 'Your computer seems to be offline.';
}
@media (max-width: 1024px) {
  .offline-ui.offline-ui-down .offline-ui-content:before {
    content: 'Your computer seems to be offline.';
  }
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-down .offline-ui-content:before {
    content: 'Your computer seems to be offline.';
  }
}
.offline-ui.offline-ui-down.offline-ui-connecting .offline-ui-content:before,
.offline-ui.offline-ui-down.offline-ui-connecting-2s .offline-ui-content:before {
  content: 'Reconnecting...';
}
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit='second']:before {
  content: 'Reconnecting in ' attr(data-retry-in-value) ' seconds...';
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit='second']:before {
    content: 'Reconnecting in ' attr(data-retry-in-value) 's...';
  }
}
.offline-ui.offline-ui-down.offline-ui-waiting
  .offline-ui-content[data-retry-in-unit='second'][data-retry-in-value='1']:before {
  content: 'Reconnecting in ' attr(data-retry-in-value) ' second...';
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit='second'][data-retry-in-value='1']:before {
    content: 'Reconnecting in ' attr(data-retry-in-value) 's...';
  }
}
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit='minute']:before {
  content: 'Reconnecting in ' attr(data-retry-in-value) ' minutes...';
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit='minute']:before {
    content: 'Reconnecting in ' attr(data-retry-in-value) 'm...';
  }
}
.offline-ui.offline-ui-down.offline-ui-waiting
  .offline-ui-content[data-retry-in-unit='minute'][data-retry-in-value='1']:before {
  content: 'Reconnecting in ' attr(data-retry-in-value) ' minute...';
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit='minute'][data-retry-in-value='1']:before {
    content: 'Reconnecting in ' attr(data-retry-in-value) 'm...';
  }
}
.offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit='hour']:before {
  content: 'Reconnecting in ' attr(data-retry-in-value) ' hours...';
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-down.offline-ui-waiting .offline-ui-content[data-retry-in-unit='hour']:before {
    content: 'Reconnecting in ' attr(data-retry-in-value) 'h...';
  }
}
.offline-ui.offline-ui-down.offline-ui-waiting
  .offline-ui-content[data-retry-in-unit='hour'][data-retry-in-value='1']:before {
  content: 'Reconnecting in ' attr(data-retry-in-value) ' hour...';
}
@media (max-width: 568px) {
  .offline-ui.offline-ui-down.offline-ui-waiting
    .offline-ui-content[data-retry-in-unit='hour'][data-retry-in-value='1']:before {
    content: 'Reconnecting in ' attr(data-retry-in-value) 'h...';
  }
}
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s.offline-ui-waiting .offline-ui-retry {
  display: none;
}
.offline-ui.offline-ui-down.offline-ui-reconnect-failed-2s .offline-ui-content:before {
  content: 'Reconnecting...';
}
