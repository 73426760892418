@account-picker-width: 150px;
.account-picker {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  width: @account-picker-width;
  min-width: @account-picker-width;
  height: @navbar-height;
  background-color: @brand-primary;
  -webkit-transition: opacity 150ms ease-in;
  -moz-transition: opacity 150ms ease-in;
  -o-transition: opacity 150ms ease-in;
  transition: opacity 150ms ease-in;
  img {
    display: none;
    width: 18px;
    height: 18px;
    margin: 0 10px 0 2px;
  }
  .picker {
    cursor: pointer;
    padding: 13px 12px;
    width: @account-picker-width;
    span {
      color: #fff;
      display: block;
      width: 110px;
      overflow: hidden;
      float: left;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    i {
      color: @gray-base;
      float: right;
      line-height: 20px;
    }
  }
  .dropdown-toggle {
    height: @navbar-height;
  }
  .dropdown-menu {
    border: 0 none;
    top: 56px !important;
    opacity: 1;
    left: 10px;
    background-color: #fff;
    -webkit-transition: opacity 150ms ease-in;
    -moz-transition: opacity 150ms ease-in;
    -o-transition: opacity 150ms ease-in;
    transition: opacity 150ms ease-in;
    .header {
      padding: 5px;
      background-color: @gray-darker;
      position: relative;
      .btn {
        text-align: left;
        border: 0 none;
        i {
          float: right;
          line-height: 22px;
        }
      }
      .arrow-up {
        position: absolute;
        top: -5px;
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid @gray-darker;
      }
    }
    li {
      &.dropdown-header {
        border-bottom: 1px solid lighten(@gray-light, 20%);
        padding: 10px 15px;
      }
      a {
        padding: 10px 15px;
        cursor: pointer;
        color: @text-color;
        background-color: transparent;
      }
      &:hover {
        a {
          color: @brand-primary;
        }
      }
      &.active {
        a {
          background-color: lighten(@gray-light, 20%);
          cursor: default;
        }
      }
    }
  }
  &.open {
    .dropdown-menu {
      top: @navbar-height + @nav-main-margin;
      opacity: 1;
    }
  }
}
