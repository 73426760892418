.package-name-editor,
.package-workspace-editor,
.workspace-edit,
.workspace-form {
  margin: 0 !important;
  width: 100% !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  .package-name-editor-body,
  .package-workspace-editor-body,
  .workspace-edit-body,
  .workspace-form-body {
    flex: 1 auto;
    overflow-y: auto;
  }
  .package-name-editor-footer,
  .package-workspace-editor-footer,
  .workspace-form-footer {
    position: relative !important;
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
  }
}
