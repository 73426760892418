.nav-stacked {
  li {
    z-index: 1;
    i,
    span {
      color: #fff;
      float: left;
    }

    i {
      text-align: center;
    }

    span {
      display: inline-block;
    }
  }

  &.moving-selector {
    .selector {
      top: 0;
      z-index: 0;
      background-color: rgba(0, 0, 0, 0.2);
      border-left: 3px solid @brand-primary;
      -webkit-transition: top 250ms cubic-bezier(0.18, 1, 0.87, 0.98);
      -moz-transition: top 250ms cubic-bezier(0.18, 1, 0.87, 0.98);
      -o-transition: top 250ms cubic-bezier(0.18, 1, 0.87, 0.98);
      transition: top 250ms cubic-bezier(0.18, 1, 0.87, 0.98);
    }
  }
}
