.designer-controls {
  min-width: 540px;
  float: right;
  .margin-right {
    margin-right: 10px;
  }
  .btn {
    border: 0 none;
  }
  a {
    cursor: pointer;
  }
}

.packages-list {
  .packages-list-heading {
    padding-bottom: 20px;
    .packages-list-search-icon {
      position: absolute;
      top: 10px;
      left: 30px;
      font-size: 26px;
      opacity: 0.1;
    }
  }
}
