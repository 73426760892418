.component {
  border: 1px solid @gray-light;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  width: 240px;
  min-height: 48px;
  &-icon {
    flex: 0 auto;
    width: 26px;
    height: 26px;
    max-width: 26px;
    stroke: #9399a0;
    fill: transparent;

    svg {
      width: 26px;
      height: 26px;
    }
  }
  &-title {
    flex: 1 auto;
    line-height: 26px;
    padding: 0 10px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-empty {
    width: 100%;
    text-align: center;
    height: 26px;
    line-height: 26px;
  }
  &.empty {
    background-color: @gray-lighter;
  }
}
