.rest-api-authentication {
  .lp-ignore div[data-lastpass-icon-root] {
    display: none !important;
  }

  .btn-group-select {
    margin-bottom: 20px;
  }
  .form-control-feedback {
    pointer-events: auto !important;

    .btn-link {
      padding: 10px 12px;
      border: 1.5px solid #bdc5cf;
      background-color: #f5f7f9;

      &:hover {
        border-radius: 8px;
        border: 1.5px solid #bdc5cf;
      }
    }

    .fa-eye-slash {
      color: @gray;
    }
  }
}
