.generic-list-modal {
  overflow: auto;
  .generic-list-search {
    margin: 0 !important;
  }
  .package-list-item {
    height: 110px;
  }
  padding: 10px 50px;
  &.package {
    .generic-list-headers {
      display: none !important;
    }
    .generic-list-item {
      &.packages {
        &:first-child {
          border-top: 0;
        }
        &:hover {
          background: #fff !important;
        }
        flex: 1;
        transform: scale(1);
        cursor: pointer;
        margin: 8px auto 25px auto;
        transition: all 0.2s ease;
        .dropdown {
          display: none;
        }
        .package-list-item {
          border-bottom: 0;
          .package-name {
            strong {
              &:hover {
                text-decoration: none;
              }
              a {
                pointer-events: none;
              }
            }
          }
        }
        .package-list-item {
          &:not(.selected),
          &.selected {
            transition: all 0.2s ease;
            border: 1px solid @gray-light;
            border-radius: @border-radius-large*3;
            overflow: hidden;
            &:hover {
              transform: scale(1.02);
              .box-shadow(0 0 10px rgba(0, 0, 0, 0.15));
            }
            svg {
              color: #999;
            }
          }
          &.selected {
            border: 1px solid @brand-primary;
            svg {
              color: @brand-primary;
            }
          }
        }
      }
    }
  }
  &.generic-list-connections {
    padding: 0 30px;
    .generic-list-body {
      padding: 20px 0 !important;
    }
    .generic-list-search {
      margin-right: 30px !important;
    }
  }
}
