@designer-component-width: 28px * 5;
@designer-component-height: 28px * 3;
@designer-component-stroke-width: 3;
@designer-port-width: 10px;
@designer-port-height: 8px;
@designer-component-border-color: #a5a5a5;
@test: #ddd;

xp-designer-package-item {
  z-index: 2;
}

xp-designer-edge {
  z-index: 1;
}

xp-designer-package-item,
xp-designer-edge {
  position: absolute;
  top: 0;
  left: 0;

  svg {
    overflow: visible !important;
  }
}

.mat-mdc-menu-item {
  &.failure {
    span {
      color: @brand-danger;
    }
  }
  &.completion {
    span {
      color: @brand-primary;
    }
  }
  &.success {
    span {
      color: @brand-success-green;
    }
  }
  &.remove {
    span {
      color: @text-color;
    }
  }
}

.dropdown-menu-new {
  .mat-mdc-menu-item {
    display: flex;
    align-items: center;
    min-height: 30px;
    padding: 5px 10px;
    height: auto;
  }
  li {
    svg {
      margin-right: 10px;
    }

    a {
      cursor: pointer;
    }
    &.failure {
      a {
        color: @brand-danger;
      }
    }
    &.completion {
      a {
        color: @brand-primary;
      }
    }
    &.success {
      a {
        color: @brand-success-green;
      }
    }
    &.remove {
      a {
        color: @text-color;
      }
    }
  }
}

.designer-component-icon {
  transform: translate(57px, 21px) scale(0.8);
  opacity: 0.8;
  pointer-events: none;
  fill: transparent;
  stroke: #9399a0;
  stroke-width: 1;
  stroke-linecap: round;
  stroke-linejoin: round;
  transition: all 0.2s ease-in;

  &.icon-file-mover {
    fill: #9399a0;
  }

  .is-component-valid & {
    stroke: @brand-primary;
  }

  .components-list & {
    stroke: @brand-primary;
    transform: translate(6px, 3px) scale(0.8);

    &.icon-file-mover {
      fill: @brand-primary;
      stroke: none;
      stroke-width: 1.33333;
    }
  }
}
.designer-component-icon-background {
  transform: translate(49px, 14px);
  opacity: 0.1;
  pointer-events: none;
  fill: #9399a0;

  .is-component-valid & {
    fill: @brand-primary;
  }
}

.designer {
  font-family: @font-family-sans-serif;
  position: fixed;
  width: calc(100vw - 50px);
  height: 100%;
  top: 0;

  .designer-scroll {
    overflow: scroll;
    position: relative;
    width: 100%;
    height: 100%;

    .designer-context-menu {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      transform: translate(-45px, 32px);

      .dropdown-menu {
        display: block !important;
        min-width: auto;
      }

      .dropdown-menu {
        li {
          a {
            cursor: pointer;
          }
          &.failure {
            a {
              color: @brand-danger;
            }
          }
          &.completion {
            a {
              color: @brand-primary;
            }
          }
          &.success {
            a {
              color: @brand-success-green;
            }
          }
          &.remove {
            a {
              color: @text-color;
            }
          }
        }
      }
    }
  }
  .designer-controls;
  .designer-stage {
    width: 100%;
    height: 100%;
    position: relative;

    .designer-grid-rect {
      width: 100%;
      height: 100%;
      background: url('../../../assets/img/designer-grid.svg');
      background-size: 28px;
    }
    .designer-selector {
      fill: fadeout(@brand-primary, 80%);
      stroke: @brand-primary;
      stroke-width: 0.5;
      pointer-events: none;
    }
    .designer-selector-new {
      background: fadeout(@brand-primary, 80%);
      border: 1px solid @brand-primary;
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 99999998;
    }
    .designer-note;
    .designer-edge {
      .designer-edge-path {
        stroke: @gray-light;
        stroke-width: 2;
        fill: none;
        &.designer-edge-path-dashed {
          stroke: @gray-light;
          //stroke-dasharray: 3, 3;
          opacity: 1;
          &.hover {
            stroke: @gray-dark;
          }
        }
      }
      .designer-edge-connector {
        width: 4px;
        height: 6px;
        fill: @gray-dark;
      }
      .designer-edge-toggle {
        cursor: pointer;
        z-index: 999999999999999;
        display: block;
        position: absolute;

        circle {
          fill: @gray-light;
        }
        path {
          fill: transparent;
        }
        &:hover {
          circle {
            fill: @gray-dark;
          }
          path {
            fill: transparent;
          }
        }
      }
      .designer-edge-port {
        width: @designer-port-width;
        height: @designer-port-height;
        fill: @gray-dark;
        &.designer-edge-port-source {
          -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
          transform: translate(-@designer-port-width / 2, -8px) rotate(0deg);
        }
        &.designer-edge-port-target {
          -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
          transform: translate(-@designer-port-width / 2, 0px) rotate(0deg);
          display: none !important;
        }
      }
      &.connector {
        .designer-edge-connector {
          fill: @brand-primary;
        }
      }
      &.failure {
        .designer-edge-path {
          stroke: @brand-danger;
        }
        .designer-edge-toggle {
          circle {
            fill: @brand-danger;
          }
        }
      }
      &.completion {
        .designer-edge-path {
          stroke: @brand-primary;
        }
        .designer-edge-toggle {
          circle {
            fill: @brand-primary;
          }
        }
      }
      &.success {
        .designer-edge-path {
          stroke: @brand-success-green;
        }
        .designer-edge-toggle {
          circle {
            fill: @brand-success-green;
          }
        }
      }
    }
    .designer-component {
      .designer-component-edit {
        .designer-component-edit-hit-area {
          width: 50px;
          height: 30px;
          pointer-events: none;
          fill: transparent;
        }
        .designer-component-edit-text {
          transform: translate(10px, 10px) scale(1.3);
          pointer-events: none;
          fill: transparent;
          user-select: none;
        }
      }
      .designer-component-bg {
        fill: #fff;
        cursor: grab;
        width: @designer-component-width;
        height: @designer-component-height;
      }
      .designer-component-bg-new {
        cursor: grab;
        width: @designer-component-width;
        height: @designer-component-height;
        transition: all 0.2s ease-in;
        filter: drop-shadow(3px 3px 2px rgba(16, 24, 40, 0.08));
      }
      .designer-component-border {
        fill: #fff;
        pointer-events: none;
      }
      .designer-component-name {
        font-size: 13px;
        font-weight: 500;
        fill: @gray;
        user-select: none;
        pointer-events: none;
        transform: translate(0, 5px);
      }
      .designer-component-add {
        transform: translate(0, 81px);
        cursor: pointer;
        opacity: 1;

        &.invisible {
          opacity: 0;
        }

        .rect {
          fill: #bdc5cf;
          width: 140px;
          height: 0;
          transform: translate(-3px) scaleY(0) scaleX(1.025);
        }
        .bottom-bar {
          transform: translate(0, 0px);
          fill: #177af0;
        }
        .plus-sign {
          transform: translate(63px, 9px);
          fill: #ffffff;
          opacity: 0;
          pointer-events: none;
        }
        &.ui-draggable-dragging {
          cursor: move;
          rect {
            fill: @brand-primary;
            width: 28px;
            height: 28px;
            display: none !important;
            transition: none;
          }
          transition: none;
        }
      }

      .designer-component-remove,
      .designer-component-duplicate {
        cursor: pointer;
        opacity: 0;
        transition: opacity 150ms;
        display: none;
      }
      .designer-component-hover {
        fill: transparent;
        transform: translate(0, -44px);
      }

      .designer-component-buttons-container {
        fill: #f5f7f9;
        transform: scaleX(0) translate(100px, -1px);
        transition: all 0.2s ease-in;
        opacity: 0;
      }

      .designer-component-remove {
        transform: translate(150px, 9px);

        &:hover {
          .designer-component-remove-icon {
            opacity: 1;
          }
        }

        .designer-component-remove-icon {
          stroke: @brand-danger;
          opacity: 0.7;
          stroke-width: 1.25;
          stroke-linecap: round;
          stroke-linejoin: round;
          fill: transparent;
        }
      }
      .designer-component-duplicate {
        transform: translate(150px, 40px);

        &:hover {
          .designer-component-duplicate-icon {
            stroke: #3d4651;
          }
        }

        .designer-component-duplicate-icon {
          stroke: #bcc6d3;
          stroke-width: 1.25;
          stroke-linecap: round;
          stroke-linejoin: round;
          fill: transparent;
        }
      }
      .designer-component-port-target {
        transform: translate(65px, -@designer-port-height);
        polygon {
          pointer-events: none;
          fill: #bdc5cf;
        }
      }
      .designer-component-port-source {
        transform: translate(65px, @designer-component-height);
        rect {
          pointer-events: none;
          width: @designer-port-width;
          height: @designer-port-height;
        }
      }
      &.hover {
        .designer-component-buttons-container {
          transform: scaleX(1) translate(100px, -1px);
          opacity: 1;
        }
        .designer-component-edit-text {
          stroke: @brand-primary;
          fill: @brand-primary;
        }

        .designer-component-bg-new {
          fill: #ffffff;
          opacity: 1;
        }
      }
      &:active {
        .designer-component-bg {
          cursor: grabbing;
        }
      }
      &.invalid {
        .designer-component-bg {
          opacity: 0.75;
          fill: url('#component-invalid');
        }
      }
      &.target {
        .designer-component-port-target {
          polygon {
            fill: @brand-primary;
          }
        }
      }
      &.hover:not(.connecting) {
        .designer-component-remove,
        .designer-component-duplicate {
          opacity: 1;
          display: block;
        }
        .designer-component-add {
          .rect {
            height: 28px;
            transform: translate(-3px) scaleY(1) scaleX(1.025);
          }
          .bottom-bar {
            transform: translate(0, 27px);
            display: none;
          }
          .plus-sign {
            opacity: 1;
          }
        }
        .designer-component-bg {
          opacity: 1;
        }
      }
      &.selected {
        .designer-component-border {
          fill: @gray;
        }
        .designer-component-icon {
          opacity: 1;
        }
        .designer-component-name {
          fill: @gray-base;
        }
      }
      &.selected,
      &.connectable {
        .designer-component-border {
          fill: @brand-primary;
        }
        .designer-component-name {
          fill: @brand-primary;
        }
      }
      &.connectable {
        .designer-component-port-target {
        }
      }
    }
    &.selecting {
      cursor: default;
      .designer-component {
        pointer-events: none;
      }
      .designer-note {
        pointer-events: none;
      }
      .designer-edge {
        pointer-events: none;
      }
    }
  }
  .designer-map-new {
    bottom: 64px;
    right: 28px;
  }

  .designer-map-new {
    position: absolute;
    padding: 2px 0 0 40px;
    background: #ffffff;
    border: 1px solid #ffffff;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    z-index: 99999;
    transform: translate(13px, 13px);

    .designer-map-wrapper {
      background-color: #fff;
      position: relative;
      .designer-map-viewport-new {
        cursor: move;
        background: transparent;
        border: 1px dashed @gray;
        position: absolute;
      }

      .designer-map-svg {
        z-index: 0;
        .designer-map-viewport {
          cursor: move;
          fill: transparent; //
          stroke-width: 1;
          stroke: @gray;
          @media (min-width: @screen-sm-min) {
            stroke-dasharray: 3, 1;
          }
          @media (min-width: @screen-md-min) {
            stroke-dasharray: 4, 2;
          }
          @media (min-width: @screen-lg-min) {
            stroke-dasharray: 5, 3;
          }
        }
        .designer-map-overlay {
          fill: fade(#e6e6e6, 80%);
        }
        .designer-map-edge {
          stroke-width: 0.5;
          stroke: @gray-darker;
          pointer-events: none;
        }
        .designer-map-component {
          fill: #bdc5cf;
          pointer-events: none;
          &.selected {
            fill: @brand-primary;
          }
        }
        .designer-map-note {
          fill: @brand-info;
          &.selected {
            fill: darken(@brand-info, 20%);
          }
        }
      }
      .designer-map-buttons {
        position: absolute;
        bottom: 4px;
        left: -35px;
        z-index: 1;
        display: flex;
        flex-direction: column;

        #button-terminal,
        #button-align {
          width: 25px;
          height: 25px;
          border: 0 none;
          background-color: transparent;
          padding: 0;
          margin-top: 4px;
          outline: none !important;

          &:hover {
            path,
            rect {
              stroke: #3d4651;
            }
          }

          path,
          rect {
            stroke: #bdc5cf;
            stroke-width: 1;
            fill: transparent;
          }
        }
      }
    }
  }
  .designer-debug {
    position: fixed;
    top: 100px;
    right: 20px;
    width: 300px;
    .panel {
      margin-bottom: 5px;
      .panel-heading,
      .panel-body {
        padding: 5px;
      }
      .panel-heading {
        .label {
          font-size: 12px;
          font-weight: 400;
        }
      }
      .panel-body {
        .panel-row {
          display: flex;
          .panel-cell {
            flex: 1;
          }
        }
      }
    }
  }
}

@keyframes dash {
  to {
    stroke-dashoffset: -6;
  }
}

.designer-package-view {
  position: absolute;
  top: 80px;
  left: 18px;
  z-index: 10;
  .designer-package-view-wrapper {
    position: relative;
    overflow: scroll;
    max-height: 800px;
    max-width: 520px;
    overflow-y: auto;
    border-radius: 5px;
    .angular-json-explorer {
      background-color: rgba(255, 255, 255, 0.2);
      &:hover {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }
}

.designer-controls() {
  .designer-controls {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
    left: 0;
    pointer-events: none;
    padding: 5px 31px 17px 17px;
    z-index: 99999998;
    .fa-pencil,
    .fa-undo {
      color: @gray-light;
    }
    button {
      margin-top: 12px;
    }
    .designer-controls-main {
      display: flex;
      flex-flow: column;
      background-color: #3d4651;
      border-radius: 8px;
      width: 300px;
      margin-top: 15px;

      .name-button {
        background-color: rgba(255, 255, 255, 0.1);
        color: #ffffff;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        border-radius: 8px 8px 0 0;
        margin: 0;
        cursor: pointer;
        transition: all 0.2s ease-in;

        &:hover {
          background-color: transparent;

          svg {
            path {
              stroke: #ffffff;
            }
          }
        }

        svg {
          margin-top: 7px;
          margin-right: -5px;

          path {
            transition: all 0.2s ease-in;
            stroke: #bdc5cf;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 1;
          }
        }
      }

      .version-button {
        color: #8a919c;
        display: flex;
        flex-flow: row;
        justify-content: space-between;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        transition: all 0.2s ease-in;

        &:hover {
          color: #ffffff;

          svg {
            path {
              stroke: #ffffff;
            }
          }
        }

        svg {
          path {
            transition: all 0.2s ease-in;
            stroke: #8a919c;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
      }
    }
    .btn-group {
      margin-left: 0;
    }
    button {
      font-weight: 400;
      font-size: 15px;
      border-radius: 10px;
      pointer-events: auto;
      border: 0 none !important;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
      height: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 15px;
    }
    .tooltip {
      white-space: nowrap;
    }
    .btn-run-job {
      position: relative;
      padding-right: 36px;

      &[hidden] {
        display: none;
      }

      &:after {
        font-family: 'FontAwesome', sans-serif;
        content: '\f054';
        font-size: 12px;
        top: 16px;
        right: 16px;
        position: absolute;
        line-height: 100%;
      }
    }
    .btn-add-component,
    #import-export-select {
      svg {
        margin-right: 7px;
      }
    }
    .link-save.disabled,
    .link-version-validate.disabled {
      opacity: 0.65;
    }
    .dropdown {
      button {
        position: relative;
        padding-right: 32px;
        span {
          font-weight: 500;
          background-color: @gray;
        }
        &:after {
          position: absolute;
          top: 15px;
          right: 16px;
          color: #3d4651;
          font-family: 'FontAwesome', sans-serif;
          content: '\f078';
          font-size: 10px;
          line-height: 100%;
        }
        &.btn-success:after {
          color: #3f3f3f;
        }
      }
      .dropdown-menu {
        background-color: #fff;
        border: 0 none;
        pointer-events: auto;
        width: 86px;
        top: 58px;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
        li {
          a {
            color: @text-color;
            padding: 5px 10px;
            line-height: 16px;
            cursor: pointer;
            &:hover {
              background-color: @dropdown-link-hover-bg;
            }
            svg {
              margin-right: 5px;
              transform: translate(0, 2px);
            }
          }
        }
      }
      button[aria-expanded='true'] {
        &:after {
          color: @gray-base;
          content: '\f077';
        }
      }
    }
  }
}

.dropdown-menu-new {
  li {
    &.disabled {
      opacity: 0.65;
      pointer-events: none;
      cursor: not-allowed;
      background-color: #ffffff;
    }

    a {
      color: @text-color;
      padding: 5px 10px;
      line-height: 16px;
      cursor: pointer;
      width: 100%;
      font-size: 14px;

      &:hover {
        background-color: @dropdown-link-hover-bg;
      }
      svg {
        margin-right: 5px;
        transform: translate(0, 2px);
      }
    }
  }
}

.selected {
  .designer-note-header {
    fill: darken(#fffd45, 20%);
  }
}

.cdk-drag-dragging {
  .designer-note-body,
  .designer-note-header,
  .designer-component-bg-new {
    cursor: grabbing !important;
  }
}

.designer-note() {
  .designer-note {
    text,
    p,
    textarea {
      background-color: transparent;
      border: 1px solid transparent;
      resize: none;
      overflow: hidden;
      width: auto;
      min-height: 100px;
      font-size: 14px;
      line-height: 22px;
      color: @gray !important;
      font-weight: 500;
      pointer-events: none;
    }
    foreignObject {
      pointer-events: none;
    }
    p,
    text {
      white-space: pre-wrap;
      position: absolute;
    }
    .designer-note-body {
      opacity: 1;
      cursor: grab;
      filter: drop-shadow(3px 3px 2px rgba(16, 24, 40, 0.08));
    }
    .designer-note-header {
      fill: #fffd45;
      cursor: grab;
    }
    .designer-note-resize {
      opacity: 0;
      cursor: se-resize;
    }
    .designer-note-duplicate {
      opacity: 0.5;
      transform: translate(158px, 13px);
      cursor: pointer;

      .designer-note-duplicate-icon {
        stroke: #3d4651;
        stroke-width: 1;
        fill: transparent;
      }

      &:hover {
        opacity: 1;
      }
    }
    .designer-note-remove {
      opacity: 0.5;
      transform: translate(184px, 14px);
      cursor: pointer;

      .designer-note-remove-icon {
        stroke: @brand-danger;
        stroke-width: 1;
        fill: transparent;
      }

      &:hover {
        opacity: 1;
      }
    }
    .designer-note-title {
      pointer-events: none;
      text {
        text-anchor: middle;
        user-select: none;
      }
      transform: translate(25px, 24px);
    }
    .designer-note-text {
      transform: translate(10px, 61px);
      pointer-events: none;
      text {
        font-size: 14px;
        fill: @gray;
        user-select: none;
      }
    }
    &.selected {
      .designer-note-header {
        fill: darken(#fffd45, 20%);
      }
    }
  }
}
