.cluster {
  .success {
    //color: @brand-success;
  }

  .primary {
    color: @brand-primary;
  }

  .cluster-type {
    padding: 2px 5px;
    display: inline-block;
    width: 90px;
    text-align: center;
    &.production {
      background-color: @gray-lighter;
    }

    &.sandbox {
      background-color: @gray-light;
      color: #fff;
    }
  }

  .status {
    &.terminated {
      color: @gray-light;
    }

    &.runing {
      color: @brand-success;
    }
  }

  border: 1px solid transparent;

  *[contenteditable] {
    cursor: default;
  }

  *[contenteditable='true'] {
    border: 1px dashed @gray-light;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px 7px;
    display: inline-block;
    width: 100%;
    cursor: default;
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }

  &.list-group-item {
    display: table;
    width: 100%;
    margin-bottom: 1px;
    border-left: 9px solid @brand-warning;
    padding: 0;

    .list-group-item-heading,
    .list-group-item-body,
    .list-group-item-footer {
      padding: 10px 15px;
      display: table-cell;
      vertical-align: middle;

      span {
        margin-right: 10px;
        display: inline-block;
        white-space: nowrap;
        overflow: hidden !important;
        text-overflow: ellipsis;
      }
    }

    .list-group-item-heading {
      width: 20%;
      cursor: pointer;
    }

    .list-group-item-body {
      width: 33%;
      cursor: pointer;
    }
    .list-group-item-footer {
      width: 34%;
    }
  }

  &.panel {
    .panel-heading {
      background-color: @brand-warning;
      position: relative;
      .cluster-type {
        position: absolute;
        top: 10px;
        right: 10px;
      }
    }
    .panel-body {
      span {
        display: block;
      }
    }
    .panel-footer {
    }
  }

  .btn-group-default {
    .btn {
      text-align: center;
    }
  }
}
