.account-list {
  img {
    margin-right: 10px;
    width: 48px;
    height: auto;
  }
  .btn-group {
    margin-top: 8px;
    button {
      color: @gray;
    }
  }
}
