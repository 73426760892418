#account-usage {
  .loader {
    margin-top: 100px;
  }
  fieldset {
    padding: 0 20px;
  }
  .current-usage {
    border-bottom: 1px solid @hr-border;
    padding-bottom: 18px;
    margin-bottom: 18px;
  }

  .btn-group {
    border: 1px solid #cccccc;
    border-radius: 8px;
  }
}

#total-usage-text {
  padding: 0 20px 20px 0;
  line-height: 150%;
  .total-box {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 8px;
    &.distributed-box {
      background-color: @brand-primary;
    }
    &.sandbox-box {
      background-color: #ffd260;
    }
  }
}

.ct-horizontal {
  white-space: nowrap;
}

#chart-stacked-bar {
  position: relative;
}

#chart-tooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 3px 5px;
  border-radius: 3px;
  -webkit-transition: all 0.1s ease;
  transition: all 0.1s ease;
  pointer-events: none;
  z-index: 100;
  .chartjs-tooltip-title {
    color: white;
    display: block;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding-bottom: 3px;
    margin-bottom: 3px;
  }
  .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;

    &.sandbox {
      background-color: #ffd260;
    }

    &.distributed {
      background-color: @brand-primary;
    }
  }
}
