@package-list-item-padding: 40px;
@package-list-item-actions-width: 120px;
.package-list-item(@generic-list-width) {
  &.package {
    padding-bottom: 140px;
  }
  .package-list-item {
    border-bottom: 1px solid @gray-light;
    height: 120px;
    padding-left: 40px;
    display: grid;
    grid-template-columns: [package-name] 1fr 1fr [workspace] 1fr [owner] 1fr [date] 1fr [run-job] 100px [dropdown] 80px;

    @media (max-width: @screen-sm) {
      grid-template-columns: [package-name] 1fr [date] 1fr [run-job] 1fr [dropdown] 40px;
      padding-left: 10px;
    }

    .dropdown {
      @media (max-width: @screen-sm) {
        transform: rotate(90deg);
      }
    }

    .package-name {
      padding-top: 30px;
      grid-column: 1 / span 2;
      display: flex;
      flex-direction: row;

      @media (max-width: @screen-sm) {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        grid-column: 1 / span 1;
      }

      .common-icon {
        @media (max-width: @screen-sm) {
          display: none;
        }
      }

      .package-name-container {
        display: flex;
        flex-direction: column;
        word-break: break-all;
        word-wrap: break-word;
        margin-left: 10px;
        max-width: 100%;
        overflow: hidden;
        padding-right: 10px;

        @media (max-width: @screen-sm) {
          margin-left: 0;
        }

        strong {
          padding-bottom: 3px;
          max-width: 100%;
          overflow: hidden;

          @media (max-width: @screen-sm) {
            text-align: center;
          }
          a {
            color: @gray-base;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: block;
            &:hover {
              text-decoration: underline;
            }
          }
          .inactive {
            color: @gray;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            margin-left: 3px;
          }
        }
        p {
          color: @gray;
          font-weight: 300;
          font-size: 13px;
          margin: 0 0 3px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          display: block;

          @media (max-width: @screen-sm) {
            text-align: center;
          }
        }

        span {
          @media (max-width: @screen-sm) {
            text-align: center;
          }
        }
      }
    }
    .package-owner,
    .package-workspace {
      padding-top: 30px;
      padding-right: 20px;
      overflow: hidden;

      @media (max-width: @screen-sm) {
        display: none;
      }

      span {
        color: @gray;
        font-weight: 300;
        font-size: 14px;
        padding-bottom: 3px;
      }

      a {
        color: @gray-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .version-message {
      color: @gray;
    }
    .package-version {
      font-size: 13px;
      &.disabled {
        pointer-events: none;
      }
    }
    .package-date {
      padding-top: 30px;
      font-size: 13px;
    }
    .package-run-job {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: center;
      height: auto;

      .btn {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        font-weight: 500;
      }

      .icon {
        cursor: pointer;
        width: 32px;
        height: 32px;
        padding-right: 11px;
        fill: transparent;
        stroke: #ffffff;
        stroke-width: 2;
      }
    }
  }
}
