.cross-join-editor {
  .cross-join-editor-texts {
    padding-bottom: 10px;
  }
  .cross-join-editor-schemas {
    display: flex;
    flex-direction: column;
    .cross-join-editor-schema {
      h1 {
        font-size: 18px;
        font-weight: 300;
      }
    }
  }
}
