.slider-animation {
  max-height: 1000px;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(1, 0, 1, 0);
}

.slider-animation.closed-animation {
  max-height: 0;
  transition: max-height 0.7s cubic-bezier(0, 1, 0, 1);
  padding: 0;
  border: none;
}
