.user-menu {
  position: absolute;
  bottom: 0;
  left: 0;
  width: @sidebar-width;
  height: @sidebar-width + 10px;
  padding: 8px;
  cursor: pointer;
  text-align: center;
  // .user-menu-name {
  //     font-size: 13px;
  //     margin-bottom: 20px;
  //     span {
  //         opacity: 1;
  //         color: @gray;
  //         user-select: none;
  //         font-weight: 400;
  //         display: block;
  //     }
  //     strong {
  //         font-weight: 700;
  //         color: @gray-base;
  //         user-select: none;
  //     }
  // }
  .user-menu-notifications {
    position: absolute;
    top: 70px;
    left: 75px;
    font-size: 12px;
    font-weight: 700;
    background-color: @brand-info;
    display: block;
    width: 26px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-radius: 50%;
  }
  svg {
    width: 100px;
    height: 100px;
    user-select: none;
  }
}
