.job-form-package-variables {
  .package-variables-editor {
    .package-variables-editor-body {
      .variables-editor-row .variables-editor-cell-input {
        width: 320px;
      }
    }
  }
}

.package-variables-editor,
.global-variables-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
  }

  .variables-editor-wrapper {
    padding-top: 15px;
  }
  .package-variables-editor-body {
    flex: 1 auto;
    overflow-y: auto;
    .package-variables-editor-tabs-box {
      display: flex;
      flex-direction: row;
      .package-variables-editor-tabs {
        flex: 0 auto;
        padding-top: 48px;
        position: relative;
        margin-right: -1px;
        z-index: 100000;
        div {
          padding: 15px;
          cursor: pointer;
          border-radius: 10px 0 0 10px;
          margin-bottom: 10px;
          position: relative;
          &.active {
            cursor: default;
            background-color: #ffffff;
            border: 1px solid #cccccc;
            border-right: none;

            .tab-title {
              margin-left: -1px;
              margin-top: -1px;
              display: block;
            }
          }
          &:hover:not(.active) {
            text-decoration: underline;
          }
        }
      }
      .package-variables-editor-content {
        flex: 1 auto;
        border-radius: 10px;
        background-color: #ffffff;
        padding: 5px 20px 20px 10px;
        height: auto;
        border: 1px solid #cccccc;
        z-index: 100;
        position: relative;
      }
    }

    .variables-editor-row .variables-editor-cell-input {
      width: 370px;
    }
  }
  .package-variables-editor-footer {
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
    position: relative !important;
    bottom: inherit;
  }
}

.package-variables-editor-wrapper {
  .package-variables-editor-body {
    padding: 30px;
  }

  &.modal-variables {
    .package-variables-editor-body {
      position: relative;
      margin: 30px;
      padding: 20px;
      border-radius: 10px;
      background-color: #f5f7f9;
    }
  }

  &.no-padding {
    .package-variables-editor-body {
      padding: 0;
    }
  }
}
