@accounts-menu-padding: 8px;

.accounts-menu-item {
  height: auto !important;
  padding: 0 !important;
  overflow: visible !important;
  cursor: default !important;
}

.accounts-menu-panel {
  margin-left: 50px;
  margin-bottom: -43px;
  overflow: visible !important;
  cursor: default !important;
}

.accounts-menu {
  position: relative;
  flex: 0 auto;
  text-align: left;
  .accounts-menu-wrapper {
    .accounts-menu-header {
      background-color: @gray-lighter;
      border-radius: 5px 5px 0 0;
      border-top: 1px solid @dropdown-border;
      border-left: 1px solid @dropdown-border;
      border-right: 1px solid @dropdown-border;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 8px 15px;
    }
    .accounts-menu-body {
      background-color: #fff;
      overflow-y: auto;
      overflow-x: hidden;
      border-left: 1px solid @dropdown-border;
      border-right: 1px solid @dropdown-border;
      .accounts-menu-list {
        max-height: calc(100vh - 200px);
        background-color: #fff;
        text-align: left;
        padding: 0;
        margin: 0;

        .accounts-menu-list-item {
          height: 35px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 15px;
          border-top: 1px solid @gray-lighter;
          overflow: hidden;
          width: 162px;
          cursor: pointer;

          .accounts-menu-link {
            color: @text-color;
            cursor: pointer;
            white-space: nowrap;
            text-overflow: ellipsis;
            line-height: 15px;
          }

          &.active {
            cursor: default;
            background-color: @brand-primary;

            .accounts-menu-link {
              color: #fff;
            }
          }
          &:hover {
            color: @text-color;
            background-color: #f5f5f5;
          }
        }
      }
    }
    .accounts-menu-footer {
      position: relative;
      padding: @accounts-menu-padding / 2;
      border-radius: 0 0 5px 5px;
      background-color: @gray-lighter;
      border-bottom: 1px solid @dropdown-border;
      border-left: 1px solid @dropdown-border;
      border-right: 1px solid @dropdown-border;
      .clearfix;
      a::after {
        font-family: 'FontAwesome', sans-serif;
        content: '\f08b';
        margin-left: 8px;
        color: #808080;
        vertical-align: top;
      }
      .accounts-menu-arrow {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 10px;
        top: 18px;
        left: -10px;
        margin-top: -10px;
        border-left-width: 0;
        border-right-color: @gray-lighter;
      }
    }
  }
}
