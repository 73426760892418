.components-order-editor {
  display: flex;
  flex-direction: row;
  .btn,
  .component {
    margin-right: 10px;
  }
  .btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: @gray-light;
    cursor: pointer !important;
    &.disable {
      pointer-events: none;
    }
  }
}
