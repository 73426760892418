.window-arguments {
  &-wrapper {
    display: flex;
    .window-argument {
      position: relative;
      flex: 1 auto;
      label {
        position: absolute;
        display: block;
        z-index: 1;
        font-weight: 300;
        font-size: 11px;
        top: -1px;
        left: -1px;
        right: -1px;
        width: 100%;
        text-align: center;
        pointer-events: none;
        color: @gray;
        transition: all 100ms;
        border-top: 1px solid transparent;
        -webkit-font-smoothing: antialiased;
        &:before {
          position: absolute;
          left: -1px;
          top: -1px;
          content: '';
          height: 100%;
          border-left: 1px solid @gray-lighter;
        }
        &:after {
          position: absolute;
          right: -2px;
          top: -1px;
          content: '';
          height: 100%;
          border-left: 1px solid @gray-lighter;
        }
      }
      input {
        z-index: 0;
        border: 0 none;
        border-right: 1px solid @gray-lighter;
        padding-top: 14px;
        line-height: 16px;
        text-align: center;
        width: 100%;
        transition: all 100ms;
        background-color: transparent;
        min-width: 90px;
        &::-webkit-input-placeholder {
          color: @gray-light !important;
          -webkit-font-smoothing: antialiased;
        }

        &:-moz-placeholder {
          color: @gray-light !important;
          -webkit-font-smoothing: antialiased;
        }

        &::-moz-placeholder {
          color: @gray-light !important;
          -webkit-font-smoothing: antialiased;
        }

        &:-ms-input-placeholder {
          color: @gray-light !important;
          -webkit-font-smoothing: antialiased;
        }
      }
      &.focus {
        background-color: #fff;
        label {
          top: -14px;
          background-color: #fff;
          border-top-color: @gray-lighter;
        }
        input {
          padding-top: 0;
          line-height: 30px;
        }
      }
      &:not(.hide):last-child {
        input {
          border-right: 0 none;
        }
        label {
          &:after {
            right: -1px;
          }
        }
      }
    }
  }
  &.error {
    background-color: lighten(@brand-warning, 45%);
    .tooltip-inner {
      background-color: lighten(@brand-warning, 45%);
      border-color: lighten(@brand-warning, 45%);
      color: darken(@brand-warning, 10%);
      font-weight: 400;
      padding: 5px 10px;
    }
    .tooltip-arrow {
      border-bottom-color: lighten(@brand-warning, 45%);
      border-top-color: lighten(@brand-warning, 45%);
    }
  }
}
