.package {
  border: 1px solid @gray-light;
  border-radius: 4px;
  padding: 5px;
  .package-header {
    display: flex;
    &-icon {
      flex: 0 auto;
      width: 30px;
      padding: 7px 0 0 7px;
      svg {
        color: @gray-light !important;
        fill: @gray !important;
      }
    }
    .package-header-info {
      display: flex;
      flex: auto;
      flex-flow: column;

      @media (max-width: @screen-sm) {
        max-width: 190px;
      }

      .package-header-name {
        font-weight: 900;
        font-size: 16px;
        line-height: 30px;
        padding: 0 7px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: -3px;
      }
      .package-header-workspace {
        display: block;
        font-size: 13px;
        font-weight: 400;
        line-height: 16px;
        color: @gray;
        margin: 0 0 5px 8px;
      }
      .package-header-version {
        font-size: 14px;
        margin-left: 8px;
        margin-top: 0;

        .version {
          font-weight: 400;
        }
      }
    }

    &-buttons {
      flex: 0 auto;
      padding: 5px;
      .btn {
        border-color: transparent;
      }
      .btn-group {
        margin-right: 10px;
      }
    }
  }
}
