.expression-editor-overlay {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999998;
}

xp-expression-editor.closed {
  display: none !important;
}

.expression-editor-footer-hint {
  padding-left: 15px;
  padding-bottom: 10px;
  display: block;
  padding-top: 0;
}

.expression-editor {
  border: 7px solid rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 50px;
  left: 350px;
  min-width: 760px;
  min-height: 420px;
  z-index: 999999;

  .dragHandle {
    position: absolute;
  }

  .dragHandle.corner {
    width: 15px;
    height: 15px;
    cursor: nwse-resize;
  }

  .dragHandle.right {
    height: 100%;
    cursor: ew-resize;
    width: 15px;
  }

  .dragHandle.bottom {
    height: 15px;
    width: 100%;
    cursor: ns-resize;
  }

  &.closed {
    display: none !important;
  }
  .ui-resizable-e {
    width: 12px !important;
    right: -10px !important;
  }
  .ui-resizable-s {
    height: 12px !important;
    bottom: -10px !important;
  }
  .ui-resizable-se {
    cursor: se-resize;
    width: 24px !important;
    height: 24px !important;
    right: -12px !important;
    bottom: -12px !important;
  }
  .expression-editor-wrapper {
    height: 100%;
    width: 100%;

    .expression-editor-header {
      cursor: move;
      background-color: #fff;
      flex: 0 auto;
      height: 30px;
      min-height: 30px;
      max-height: 30px;
      strong {
        font-size: 18px;
        display: inline-block;
        text-transform: uppercase;
        font-weight: 400;
        user-select: none;
      }
      .btn-group {
      }
    }
    .expression-editor-body {
      display: flex;
      flex-direction: row;
      height: calc(100% - 127px);
      width: 100%;
      .expression-editor-menu {
        border: 1px solid @gray-light;
        border-radius: 5px;
        flex: 0 auto;
        width: 240px;
        min-width: 240px;
        margin-right: 10px; //overflow: hidden;
        .expression-editor-menu-wrapper {
          display: flex;
          flex-direction: column;
          height: 100%;
          .expression-editor-menu-search {
            position: relative;
            flex: 0 auto;
            height: 30px;
            min-height: 30px;
            .expression-editor-menu-search-wrapper {
              border-radius: 5px;
              &:after {
                font-family: 'FontAwesome', sans-serif;
                content: '\f002';
                font-size: 12px;
                color: @gray-light;
                position: absolute;
                top: 7px;
                left: 10px;
              }
              input {
                border-radius: 5px 5px 0 0; //background-color: #EEE;
                width: 100%;
                line-height: 30px;
                height: 30px;
                border: 0 none;
                border-bottom: 1px solid @gray-light;
                padding-left: 28px;
                &::-webkit-input-placeholder {
                  color: @gray-light;
                  font-weight: 300;
                }
                &::-moz-placeholder {
                  color: @gray-light;
                  font-weight: 300;
                }
                &:-moz-placeholder {
                  color: @gray-light;
                  font-weight: 300;
                }
              }
            }
          }
          .expression-editor-menu-tabs {
            flex: 0 auto;
            height: 25px;
            min-height: 25px;
            display: flex;
            border-bottom: 1px solid @gray-light;
            background-color: lighten(@gray-lighter, 7%);
            .expression-editor-menu-tab {
              span {
                font-size: 11px;
                font-weight: 300;
                display: inline-block;
                padding: 0 9px;
                line-height: 24px;
                color: @gray-light;
                cursor: pointer;
                border-left: 1px solid transparent;
                border-right: 1px solid transparent;
                user-select: none;
                &:hover {
                  color: @brand-primary;
                }
              }
              &.active {
                span {
                  border-left: 1px solid @gray-light;
                  border-right: 1px solid @gray-light;
                  border-bottom: 1px solid #fff;
                  cursor: default;
                  background-color: #fff;
                  font-weight: 400;
                  min-height: 24px;
                  color: @gray;
                }
              }
              &:first-child {
                &.active {
                  span {
                    border-left: 1px solid #fff;
                  }
                }
              }
            }
          }
          .expression-editor-categories {
            flex: 1 auto;
            overflow-y: auto;
            overflow-x: visible;
            padding-top: 10px;
            .expression-editor-category {
              padding-left: 10px;
              strong {
                font-weight: 600 !important;
                cursor: pointer;
                display: block;
                font-size: 14px;
                color: @gray;
                &.expanded {
                  color: @text-color;
                }
                &:hover {
                  color: @brand-primary;
                }
              }
              .expression-editor-category-items {
                padding: 0 0 5px 15px;
                .expression-editor-category-item {
                  position: relative;
                  small {
                    font-weight: 300;
                    font-size: 13px;
                    color: @gray;
                    display: block;
                    padding: 2px 5px;
                    cursor: pointer;
                    &:hover {
                      color: @text-color;
                    }
                    .highlighted {
                      background-color: lighten(@brand-info, 10%);
                    }
                  }
                  .expression-editor-completion-info {
                    position: absolute;
                    top: 0 !important;
                  }
                  &:hover {
                    background-color: @gray-lighter;
                  }
                }
              }
            }
          }
        }
      }
      .expression-editor-code {
        flex: 1 auto;
        border: 1px solid @gray-light;
        border-radius: 5px;
        overflow-y: auto;
        textarea {
          height: 100%;
        }
        .CodeMirror-sizer {
          min-height: 240px !important;
          height: 100%;
        }
        .CodeMirror-gutters {
          height: 100% !important;
        }
        .CodeMirror-scroll {
          max-height: 100% !important;
          height: 100%;
        }
        .expression-editor-textarea {
          display: flex;
          flex-direction: column;
          height: 100%;
          .CodeMirror {
            border: 0 none;
            height: 100%;
            .CodeMirror-line {
              padding-left: 7px;
            }
            .CodeMirror-linewidget {
              width: 100% !important;
            }
            .lint-error {
              background-color: lighten(@brand-info, 30%);
              color: @brand-danger;
              border: 1px solid lighten(@brand-danger, 10%);
              padding: 10px;
              margin: 7px;
              span.fa {
                margin-right: 10px;
              }
              overflow: auto;
            }
          }
        }
      }

      .console-container {
        height: 100%;

        #terminal {
          height: 100%;

          .xterm {
            height: 100%;
          }
        }
      }
    }
    .expression-editor-footer {
      height: 62px;
      min-height: 62px;
      .btn {
        &.pull-right {
          margin-left: 15px;
        }
        &.btn-success {
          padding-right: 20px;
          padding-left: 20px;
          font-weight: 600;
        }
        &.btn-default {
          font-weight: 400;
          border: 1px solid #ddd;
        }
        &.btn-terminal {
          background-color: @gray;
          color: #fff;
          padding: 5px;
          border: 0 none;
          font-weight: 300;
        }
      }
    }
  }
}

.expression-editor-completion-info {
  position: absolute;
  z-index: 100000001;
  max-width: 460px;
  padding: 10px;
  background-color: #fff;
  border: 1px solid @gray-lighter;
  pointer-events: none;
  .expression-editor-completion-info-header {
    h3 {
      margin: 0;
      padding: 0;
      font-family: monospace;
      font-size: 24px;
      font-weight: 300;
      word-wrap: break-word;
      word-break: break-all;
      white-space: normal;
    }
  }
  .expression-editor-completion-info-body {
    padding: 10px 0;
    p {
      margin: 0;
      font-size: 13px;
    }
  }
  .expression-editor-completion-info-example {
    max-width: 500px;
    background-color: @gray-lighter; //color: @gray-lighter;
    padding: 10px;
    min-width: 100%;
    font-size: 13px;
    border-radius: 5px;
    word-wrap: break-word;
    word-break: break-all;
    white-space: normal;
  }
}

.CodeMirror-hints {
  .CodeMirror-hint {
    font-family: monospace;
    font-size: 14px;
    &.CodeMirror-hint-active {
    }
  }
}
