@charset "UTF-8";

svg {
  &.icon {
    width: 30px;
    height: 30px;
    fill: none;

    &.icon-book {
      width: 14px;
      color: @brand-primary;
      fill: @gray-base;
    }
    &.icon-chat {
      width: 16px;
      fill: @gray-base;
    }
    &.icon-video {
      width: 18px;
      fill: @gray-base;
      color: @brand-primary;
    }
  }
  &.empty-list-icon {
    color: @gray-light;
    fill: darken(@gray-light, 15%);
    use.icon-job {
      color: #5b5b5b;
      fill: @gray-light;
    }
    use.icon-cluster {
      color: white;
    }
    use.icon-schedules {
      color: #5b5b5b;
    }
  }
}
