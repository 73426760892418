.alias-editor,
.rest-api-headers-key,
.rest-api-headers-value {
  &.error {
    background-color: lighten(@brand-warning, 45%);
    .tooltip-inner {
      background-color: lighten(@brand-warning, 45%);
      border-color: lighten(@brand-warning, 45%);
      color: darken(@brand-warning, 10%);
      font-weight: 400;
      padding: 5px 10px;
    }
    .tooltip-arrow {
      border-bottom-color: lighten(@brand-warning, 45%);
      border-top-color: lighten(@brand-warning, 45%);
    }
  }
}
.alias-editor {
  width: 100%;
  display: flex;
  padding-left: 5px;

  .editor-button {
    flex: 0 auto;
    min-width: 32px;
    max-width: 32px;
  }
  base-input {
    flex: 1 auto;
  }
  .base-input-control {
    padding: 3px 10px 3px 0 !important;
  }
}
