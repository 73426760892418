.filter-variables-editor {
  .filter-section {
    position: relative;
    padding-left: 20px;
    .filter-section-connector {
      position: absolute;
      z-index: 1;
      top: 33px;
      left: 0;
      width: 100px;
      border-left: 1px solid @gray-light;
      pointer-events: none;
      .filter-section-connector-label {
        position: absolute;
        right: 90px;
        background-color: @gray-light;
        padding: 1px 5px;
        border-radius: 2px;
        cursor: pointer;
        text-transform: uppercase;
        font-size: 13px;
        transform: translate(0, -50%);
        pointer-events: auto;
        span {
          position: relative;
          display: inline-block;
          padding-right: 12px;
          &:after {
            font-family: 'FontAwesome', sans-serif;
            content: '\f0d7';
            right: 1px;
            position: absolute;
            top: 1px;
          }
        }
      }
      .filter-section-connector-line {
        border-top: 1px solid @gray-light;
        width: 20px;
        height: 58px;
        &:last-child {
          height: 0;
        }
      }
    }
    .filter-sections-connector-container {
      z-index: 0;
      width: 50px;
      border-left: 1px solid @gray-light;
      position: absolute;
      top: 0;
      left: 0;
      .filter-sections-connector-line {
        width: 60px;
        border-bottom: 1px solid @gray-light;
        &:first-child {
          //border-bottom: 1px solid transparent;
        }
      }
    }
    .filter-section {
      margin-left: 50px;
    }
  }
}
