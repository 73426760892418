.schema-mapping-salesforce {
  &-delete {
    .fields-collection-autofill {
      button {
        display: none;
      }
    }
    .fields-collection-row-add {
      .editor-button {
        pointer-events: none;
        opacity: 0.3;
      }
    }
  }
}
