.database-advanced-options-destination {
  small {
    &.input-checkbox {
      display: block;
      transform: translate(18px, -10px);
      pointer-events: none;
    }
  }
  .form-group-checkbox {
    margin-bottom: 0;
  }
  .well {
    background-color: #eee;
    box-shadow: none;
  }
}
