.plans {
  .plan-detail {
    font-size: 14px;
    white-space: normal;
    margin-bottom: 20px;
  }
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 684px;
  padding: 42px 0;
  .loader {
    padding-top: 200px;
  }
  .plan {
    align-self: stretch;
    &:hover {
      transform: scale(1.05);
      transition: all 0.2s;
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
      background-color: #fff;
      z-index: 1;
    }
    .plan-dev-enviorment {
      margin-top: 10px;
      display: block;
      white-space: normal;
      color: @brand-primary;
      em {
        font-style: normal;
        font-size: 16px;
        font-weight: 700;
      }
      span {
        font-size: @font-size-small;
        font-style: italic;
        color: lighten(@gray-base, 20%);
      }
    }
    margin-bottom: 30px;
    .plan-wrapper {
      .plan-container {
        padding: 25px;
      }
    }
    border-radius: 4px !important;
    border: 0;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
    padding: 0;
    width: 31.5%;
    .plan-heading,
    .plan-body,
    .plan-footer {
      text-align: left !important;
    }
    .plan-heading {
      .plan-price {
        font-size: 32px;
        color: @brand-primary;
        span {
          font-size: 14px;
        }
      }
      .plan-title {
        strong {
          text-transform: uppercase;
          font-size: 14px;
        }
      }
    }
    .plan-body {
      .plan-nodes {
        strong {
          font-size: 30px;
          display: block;
        }
        small {
          font-size: 16px;
        }
      }
    }
    .plan-footer {
    }
    .btn-plan {
      clear: both;
      border: 0 none;
      padding: 20px 0;
      font-weight: 700;
      color: @text-color;
      font-size: 13px;
      text-transform: uppercase;
      border-radius: 0 0 4px 4px;
    }
    &.active {
      background-color: #fff;
      box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
      .btn-plan {
        background-color: @brand-primary;
        color: #fff;
      }
      &:hover {
        transform: scale(1);
        background-color: #fff;
      }
    }
    &.recommended {
      svg {
        position: absolute;
        width: 80px;
        top: -75px;
        right: 15px;
      }
    }
  }
}

.qa-container {
  background-color: lighten(@gray-lighter, 6%);
  padding: 1px 40px;
  border-radius: @border-radius-large;
  h3 {
    margin-bottom: 30px;
    margin-top: 30px;
    font-weight: 900;
  }
  // background: red;
  ul {
    // background: red;
    padding: 0;
    list-style: none;
  }
  li {
    margin-bottom: 40px;
  }
  .question {
    color: lighten(@gray-base, 20%);
    font-weight: bold;
    text-transform: inherit;
    letter-spacing: inherit;
    font-size: 16px;
    border-bottom: 3px solid @gray-lighter;
    margin-bottom: 10px;
    display: inline-block;
  }
}
