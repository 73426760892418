.CodeMirror-hints {
  position: absolute;
  z-index: 3000;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 1px;
  border: 1px solid @gray-lighter;
  background: white;
  font-size: 90%;
  font-family: monospace;
  max-height: 20em;
  overflow-y: auto;
}

.CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  max-width: 19em;
  overflow: hidden;
  white-space: pre;
  color: black;
  cursor: pointer;
  &.keyword:before,
  &.datatype:before {
    margin-right: 5px;
    font-family: FontAwesome, sans-serif;
    font-size: 12px;
  }
  &.keyword:before {
    content: '\f111';
    color: @brand-success;
  }
  &.datatype:before {
    content: '\f1b2';
    color: @brand-danger;
  }
}

li.CodeMirror-hint-active {
  background: @brand-primary;
  color: white;
}
