.checkbox {
  font-size: 16px;
  cursor: pointer;
  display: flex;
  i {
    width: 18px;
    text-align: center;
    color: @text-color;
    font-size: 15px;
    transform: translateY(2px);
    margin-right: 10px;
    user-select: none;
  }
  label {
    user-select: none;
  }
  span {
    font-size: 14px;
    user-select: none;
  }
  input {
    display: none;
  }
  &:hover {
    color: #000;
  }
}

.input-checkbox {
  font-size: 16px;
  cursor: pointer;
  display: inline-block;
  i {
    width: 18px;
    text-align: center;
    color: @text-color;
    font-size: 15px;
    user-select: none;
  }
  label {
    user-select: none;
  }
  span {
    font-size: 14px;
    user-select: none;
  }
  input {
    display: none;
  }
  &:hover {
    color: #000;
  }

  &.disabled {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.3;
  }
}
