@select-editor-padding: 3px;
.select-editor {
  width: 100%;
  .select-variables-editor {
    padding-top: 20px;
    padding-bottom: 10px;
  }
  .fields-rows {
    display: flex;
    padding: 0 0 @select-editor-padding 0;
    .fields-cell {
      &.fields-cell-input {
        margin-right: @select-editor-padding;
        flex: 1 auto;
      }
      button {
        i {
          color: @gray-light;
        }
        &:hover {
          i {
            color: @gray;
          }
        }
        &:active {
          i {
            color: @gray-dark;
          }
        }
      }
      &.fields-cell-add,
      &.fields-cell-remove,
      &.fields-cell-move {
        flex: 0 auto;
      }
    }
  }
  .expression-editor-icon {
    right: 18px !important;
    background-color: transparent !important;
  }
  .btn-auto-fill {
    margin-right: 20px;
  }
  &-preview {
    .well;
    .table {
      margin-bottom: 0 !important;
      background-color: #fff;
      thead {
        tr {
          th {
            border-bottom-width: 0;
          }
        }
      }
    }
  }
}
