.job-preview {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .job-preview-loader {
    margin-top: 50px;
  }
}

job-view {
  height: 100%;
  overflow: auto;
  margin: 55px 0 130px 0;

  @media (max-width: @screen-sm) {
    margin: 55px 0 0 0;
  }

  .mat-mdc-tab-header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    background: #fff;
    width: 100%;
  }
}

.job-view {
  display: flex;
  flex-direction: column;
  height: 100%;

  .job-view-back {
    flex: 0;
    min-height: 46px;
    background-color: @gray-base;
    cursor: pointer;
    line-height: 46px;
    color: @gray-light;
    padding-left: 15px;
    font-size: 14px;
    font-weight: 300;
    i {
      font-size: 12px;
      margin-right: 2px;
      transform: translate(0, -1px);
    }
    &:hover {
      color: #fff;
    }
  }
  .job-view-body {
    flex: 1 auto;
    overflow-y: auto;
    max-height: 1145px;

    .loader {
      padding: 15px 0 10px;
    }
    .tab-content {
      display: flex;
      flex-direction: column;
      height: 100%;
      min-height: 100%;
      background-color: #fff;
      .job-content {
        padding: 20px 50px;
        .package-variables-editor {
          padding: 30px 50px;
        }
      }
      .job-empty {
        padding: 50px;
        text-align: center;
        border: 1px solid #cccccc;
        border-radius: 5px;
        margin: 30px;
      }
    }
  }
  .job-view-footer {
    width: 100%;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);
    background: #f5f7f9;
    padding: 10px;
    flex: 0 auto;
    min-height: 120px;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10000;
    height: 140px;

    @media (max-width: @screen-sm) {
      display: none;
    }

    .job-list-item(100%);
    .status-progress-bar {
      bottom: -3px !important;
      height: 3px !important;
    }
    .status-button {
      display: none !important;
    }
    .job-list {
      grid-template-columns: [job] 200px [package] 1fr [cluster] 190px [runstime] 210px [status] 130px [details] 0 [dropdown] 0 [end];

      &.item {
        position: absolute;
        left: 10px;
        bottom: 10px;
        background: #ffffff;
        width: calc(100% - 20px);
        height: 100px;
        border: 1px solid #eceef0;
      }
    }
    .job-list-item-job {
      position: absolute;
      top: 10px;
      left: 10px;
      padding-left: 40px;
      text-transform: uppercase;
      font-size: 12px;
      line-height: 22px;
      display: grid;
      grid-template-columns: [job] 200px [package] 1fr [cluster] 190px [runstime] 210px [status] 130px [details] 0 [dropdown] 0 [end];
      background: #f9fafb;
      border: 1px solid #eceef0;
      width: calc(100% - 20px);

      .job,
      .package,
      .cluster,
      .runtime,
      .status {
        border: none;
      }
      .package,
      .cluster {
        padding: 0 !important;
        strong {
          padding-right: 15px;
        }
      }
    }

    .job-list.item {
      @media (max-width: @screen-sm) {
        width: 100%;
      }

      .job,
      .cluster {
        @media (max-width: @screen-sm) {
          display: block;
        }
      }

      .package,
      .runtime {
        @media (max-width: @screen-sm) {
          display: none;
        }
      }
    }
  }
}

@import 'job-errors';
@import 'job-general';
@import 'job-outputs';
@import 'job-progress';
@import 'job-variables';
