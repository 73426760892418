.schedule-form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  .schedule-form-body {
    flex: 1 auto;
    overflow-y: auto;
  }
  .schedule-form-footer {
    position: relative !important;
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
  }
  .status-form-group {
    display: flex;
    align-items: center;
    padding-top: 30px;
    justify-content: center;

    .btn-group {
      border: 1px solid #e1e3e6;
      border-radius: 8px;
      width: auto;

      .btn {
        opacity: 0.7;

        &.active {
          opacity: 1;
          color: #ffffff;
          background-color: @brand-primary;
        }
      }
    }

    label {
      margin-right: 10px;
      margin-bottom: 0;
    }
  }
  .name-description-editor {
    padding: 0 10px;
  }
  .tab-content {
    padding: 50px 70px;
  }
  .row {
    margin: 0;
  }
  .input-group {
    min-width: 160px;
    border: 1px solid #d0d5dd;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    background: #fff;
    display: flex;
    flex-flow: row;

    input {
      border: none;
      border-radius: 8px 0 0 8px;
    }

    button {
      padding: 10px 15px;
      border-radius: 0 8px 8px 0;
      border-left: 1px solid #d0d5dd;
    }
  }
  .schedule-form-steps {
    padding: 30px;

    @media (max-width: @screen-sm) {
      padding: 10px;
    }
  }
  .schedule-form-content {
    padding: 0 0;

    .row-item {
      display: grid;
      grid-template-columns: [label] 150px [inputs] 1fr;
      margin-bottom: 20px;

      .inputs-item {
        column-gap: 10px;
        display: flex;

        @media (max-width: @screen-sm) {
          flex-direction: column;
          column-gap: 0;
        }

        .cron-expression.input-group {
          width: 220px;
          height: 41px;
          overflow: hidden;
        }

        .days-picker {
          width: 220px;

          .input-group {
            width: 220px;
          }
        }

        .time-picker {
          display: flex;
          flex-flow: row;
          align-items: center;

          @media (max-width: @screen-sm) {
            margin-top: 10px;
            width: fit-content;
            justify-content: center;
          }

          .time-picker-label {
            margin-right: 10px;
            font-size: 14px;
            white-space: nowrap;

            &.timezone-label {
              margin-left: 10px;
            }
          }

          .xp-select {
            width: 220px;

            &.disabled {
              background-color: #e6e6e6;
              opacity: 1;
              pointer-events: none;
              cursor: not-allowed;
            }
          }

          .input-group {
            width: 110px;
            min-width: 110px;
          }
        }
      }
    }
    .form-group {
      input[type='checkbox'] {
        margin-right: 7px;
      }
    }
  }
  .cluster-form {
    max-width: 630px;
    padding: 0 0 !important;
    margin: 0 0 !important;
    form {
      padding: 0 0 !important;
    }
    .cluster-form-body {
      padding: 0 0 !important;
    }
  }

  .invalid-cron {
    color: #ffa654;
    max-width: 400px;
  }

  .valid-cron {
    color: #808080;
    max-width: 400px;
  }

  .valid-cron,
  .invalid-cron {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }

  #datetimepicker3 {
    > .input-group-addon:first-child {
      border: 0;

      &:hover {
        background-color: white;
        cursor: auto;
      }
    }

    .disabled {
      cursor: not-allowed;
      opacity: 0.65;
    }
  }

  #datetimepicker3 {
    .input-group-addon {
      cursor: default;

      &:hover {
        background-color: #fff;
        border-color: #ccc;
      }
    }
  }
}
