/* line 21, ../sass/offline-theme-default.sass */
.offline-ui {
  font-family: @font-family-base;
  padding: @alert-padding;
  -webkit-border-radius: @alert-border-radius;
  -moz-border-radius: @alert-border-radius;
  border-radius: @alert-border-radius;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
  top: 20px;
}

.offline-ui.offline-ui-up {
  background: @alert-success-bg;
  color: @alert-success-text;
  border-left: 2px solid @alert-success-text;
}

.offline-ui.offline-ui-down {
  background: @alert-danger-bg;
  color: @alert-danger-text;
  border-left: 2px solid @alert-danger-text;
}

.offline-ui.offline-ui-down.offline-ui-connecting,
.offline-ui.offline-ui-down.offline-ui-waiting {
  background: @alert-warning-bg;
  color: @alert-warning-text;
  border-left: 2px solid @alert-warning-text;
  &:after {
    border-top-color: @alert-warning-text;
    border-left-color: @alert-warning-text;
    opacity: 1;
  }
}

.offline-ui .offline-ui-retry {
  background: lighten(@alert-warning-text, 38%);
  color: inherit;
  line-height: 3.5em;
  height: 3.5em;
  &:hover {
    background: lighten(@alert-warning-text, 34%);
  }
}
