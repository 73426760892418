.package-form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  .package-form-body {
    flex: 1 auto;
    overflow-y: scroll;

    .package-type {
      .package-type-wrapper {
        border: 1px solid @gray-light;
        border-radius: 6px;
        text-align: center;
        padding-bottom: 30px;
        .lead {
          font-size: 14px;
          padding: 40px 0 30px;
        }
        .package-type-dataflow {
          padding-top: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          padding: 10px 0;
        }
        svg {
          cursor: pointer;
          path {
            &.bg {
              fill: #fff;
            }
          }
          &.active {
            path {
              &.bg {
                fill: @brand-primary;
              }
            }
          }
        }
      }
      p {
        &.description {
          padding: 20px 0;
          font-size: 16px;
          font-weight: 300;
        }
      }
    }
    z-index: 0;
  }
  .package-form-footer {
    position: static !important;
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
    z-index: 1;
  }
}
