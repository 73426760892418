@page-header-height: 49px;
.page-header {
  //position: fixed;
  background-color: lighten(@brand-primary, 40%);
  padding: 0;
  margin: 0;
  border-bottom: 0 none;
  max-height: @page-header-height;
  display: table;
  width: 100%;
  @shadow: inset 0 1px 0 rgba(0, 0, 0, 1), 0 1px 0 rgba(0, 0, 0, 0.1);
  box-shadow: @shadow;
  .page-header-col {
    display: table-cell;
    height: @page-header-height;
    vertical-align: middle;
  }
  .comment-btn {
    background-color: @brand-warning;
  }
  .add-btn {
    .bottom-border {
      border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
      margin-bottom: 10px;
    }
    .btn {
      background-color: @gray;
    }
    &.open {
      .btn {
        background-color: @gray-darker;
      }
    }
    .dropdown-menu {
      background-color: @brand-primary;
      padding: 0;
      .new-source {
        padding: 0 10px;
        font-style: italic;
      }
      a {
        padding: 10px !important;
        &:hover {
          background-color: @gray;
          color: #fff;
        }
      }
      i {
        width: 22px;
      }
    }
  }
  .page-header-back {
    max-width: 52px;
    min-width: 52px;
    background-color: @brand-primary;
    color: #fff;
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: @link-hover-color;
    }
  }
  .page-header-title {
    position: relative;
    width: 50%;
    i {
      color: @brand-primary;
      position: absolute;
      top: 10px;
      left: 15px;
      display: block;
      font-size: 24px;
    }
    h1 {
      color: @brand-primary;
      display: inline-block;
      font-weight: 400;
      position: relative;
      padding-left: 26px;
      span {
        font-size: 20px;
        outline: none !important;
        display: inline-block;
        padding: 0 4px;
      }
    }
    h1,
    p {
      &.has-back {
        margin: 0 0 0 20px;
      }
    }
    p {
      padding-left: 26px;
      span {
        display: inline-block;
        padding: 0 4px;
      }
      &.has-back {
        color: @gray;
      }
    }
    &.editable {
      h1,
      p {
        span {
          cursor: pointer;
          &:hover {
            background-color: fadeout(@brand-primary, 80%);
          }
        }
      }
    }
  }
  .page-header-injector {
    padding: 2px 15px 0;
    width: 70%;
    .nav-tabs {
      height: @page-header-height;
      border: 0 none;
      li {
        a {
          line-height: 22px;
          display: inline-block;
          vertical-align: middle;
          padding: ((@page-header-height - 22px) / 2);
          border: 0 none;
          background-color: transparent;
          font-weight: 300;
          color: @gray-light;
          &:hover {
            border: 0 none;
            background-color: transparent;
          }
          i {
            //font-size: 22px;
            margin-right: 10px;
          }
        }
        &.active {
          a {
            border: 0 none;
            color: @brand-primary;
            border-bottom: 3px solid @brand-primary;
            &:hover {
              color: @brand-primary;
              //background-color: @body-bg;
            }
          }
        }
      }
    }
    .btn-group {
      .dropdown-menu {
        top: 90%;
        a {
          padding: 3px 10px;
        }
      }
      &:hover {
        .dropdown-menu {
          //display: block;
        }
      }
    }
  }
}
