.field-picker {
  display: flex;
  z-index: 11111;
  padding: 0 5px;

  .editor-button-open,
  .editor-button-close {
    flex: 0 auto;
    width: 32px;
    max-width: 32px;
    min-width: 32px;
  }
  .editor-picker {
    flex: 1 auto;
    position: relative;

    .auto-complete-results {
      position: absolute;
      background-color: #fff;
      width: 100%;
    }
  }
  .editor-expression {
    flex: 1 auto;
    line-height: 31px;
    padding: 0 10px;
    font-family: monospace;
    font-size: 13px;
  }
  .editor-picker-dropdown {
    z-index: 11111;
    transform: translateY(2px);
    &.open {
      .auto-complete-results {
        //border: 1px solid @gray-lighter;
        .box-shadow(0 6px 12px rgba(0, 0, 0, 0.175));
        border-radius: 0 0 5px 5px;
      }
    }
    &.direction-up {
      &.open {
        .auto-complete-results {
          .box-shadow(0 -6px 12px rgba(0, 0, 0, 0.175));
          border-radius: 5px 5px 0 0;
        }
      }
    }
  }
  &.error {
    background-color: lighten(@brand-warning, 45%);
    .tooltip-inner {
      background-color: lighten(@brand-warning, 45%);
      border-color: lighten(@brand-warning, 45%);
      color: darken(@brand-warning, 10%);
      font-weight: 400;
      padding: 5px 10px;
    }
    .tooltip-arrow {
      border-bottom-color: lighten(@brand-warning, 45%);
      border-top-color: lighten(@brand-warning, 45%);
    }
  }
  &.readonly {
    pointer-events: none;
  }
}

.function-picker {
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  width: 100%;
  padding: 0 5px;
}

.cube-rollup-picker {
  padding: 0 5px;
}
