.component-previewer {
  display: flex;
  flex-flow: column;

  .btn {
    margin: 10px 0;
    width: 180px;
    align-self: flex-end;
    padding: 10px 25px;

    .badge {
      text-transform: uppercase;
      font-size: 9px;
      border-radius: 5px;
    }
  }

  .progress-bar-container {
    width: 100%;
  }

  .payload-container {
    width: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column;

    .failed {
      color: #ff5454;
    }
  }
}
