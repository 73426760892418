.key-form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  .key-form-body {
    flex: 1 auto;
    overflow-x: auto;
  }
}
