.schema-editor {
  position: relative;
  min-width: 580px;
  .schema-field-header {
    line-height: 150%;
    color: @gray;
    div {
      padding: 0 5px;
    }
  }
  .schema-field-header,
  .schema-field-editor {
    position: relative;
    display: flex;
    padding-bottom: 5px;
    .schema-field-editor-handle {
      width: 34px;
      button {
        color: @gray;
        cursor: ns-resize;
      }
    }
    .schema-field-editor-name {
      flex: 1 auto;
      padding-right: 5px;
    }
    .schema-field-editor-alias {
      flex: 1 auto;
      padding-right: 5px;
    }
    .schema-field-editor-type {
      width: 120px;
      padding-right: 5px;
    }
    .schema-field-editor-buttons {
      min-width: 78px;
    }

    select,
    button {
      height: 40px;
    }
    select,
    option,
    input {
      width: 100%;
      padding: 20px 10px;
      border: 0 none;
    }
    &.ui-sortable-helper {
      button:not(.btn-link),
      select,
      input {
        box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2);
      }
      .schema-field-editor-handle {
        button {
          color: @brand-primary;
        }
      }
    }
  }
}
