.note-editor {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  .note-editor-body {
    flex: 1 auto;
    overflow-y: auto;
    textarea {
      min-height: 220px;
      min-width: 100%;
    }
  }
  .note-editor-footer {
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
    max-height: 80px;
    position: relative !important;
  }

  .form-group {
    background: #f5f7f9;
    border-radius: 10px;
    padding: 25px;

    label {
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #3d4651;
      margin-bottom: 30px;
    }
  }
}
