.member-form {
  display: flex;
  flex-direction: column;
  height: 100%;

  .btn-danger2 {
    width: 100%;
  }

  .member-roles {
    margin-top: 20px;

    .form-group {
      margin: 0;
      height: 50px;
    }

    xp-input-checkbox {
      width: 100%;
      height: 100%;
      display: block;
      margin: 5px 0;

      label {
        margin: 0 !important;
        padding: 5px !important;
        height: 100%;
        display: block !important;
        border: 1px solid #eceef0;
        background: #ffffff;
        transition: all 200ms ease-in;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
          background: darken(#ffffff, 5%);
        }

        &.is-selected {
          background: darken(#ffffff, 5%);
          font-weight: bold;
        }

        &.readonly {
          background: darken(#ffffff, 10%);
          cursor: not-allowed;
        }
      }

      .input-checkbox-content {
        display: flex;
        align-items: center;
        flex-flow: row;
        height: 100%;
        justify-content: space-between;
        flex-flow: row-reverse;
        padding: 0 10px;

        i {
          font-size: 20px;
        }
      }
    }
  }
}
