errors-viewer {
  .errors-viewer {
    position: absolute !important;
    z-index: 99999999;
    top: 90px;
    left: 68px;
    width: 460px;
    max-height: calc(100vh - 300px);
    overflow: auto;
    background: #ffffff;

    &.errors-viewer-new {
      top: 5px;
      left: 15px;
    }

    .errors-viewer-header {
      cursor: move;
      background: rgba(244, 75, 63, 0.2);
      position: relative;

      &.is-success {
        background-color: @brand-success-green-background;

        .errors-viewer-title {
          color: @brand-success-green;
        }
      }

      .errors-viewer-title {
        font-size: 16px;
        text-align: center;
        width: 100%;
        display: block;
        color: @brand-danger;
        font-weight: 500;
        margin: 5px 0;
      }
      .close {
        color: #3d4651;
        right: 10px;
        position: absolute;
        top: 10px;
      }
    }
    .errors-viewer-body {
      overflow-y: auto;
      max-height: calc(100vh - 400px);

      .errors-viewer-body-title {
        font-weight: 600;
        font-size: 13px;
        line-height: 16px;
        text-align: center;
        color: #3d4651;
        margin-top: 0;
        margin-bottom: 15px;
      }

      .text-success {
        color: @brand-success-green;
        background-color: @brand-success-green-background;
      }
    }
    .panel {
      .panel-body {
        padding: 0;
        overflow-y: auto;
        .list-group {
          margin-bottom: 0;
          li:last-child {
            border-bottom: 0 none;
          }
        }
        i {
          margin-right: 5px;
          color: @brand-danger;
        }
      }
    }
    .panel-clickable {
      cursor: pointer;

      &:hover {
        .panel-heading {
          background: rgba(208, 213, 221, 0.5);
          color: #262b33;
        }

        .panel-body {
          background: rgba(208, 213, 221, 0.1);
        }
      }
    }
  }
}
