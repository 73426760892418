@headers-editor-padding: 3px;
.headers-editor {
  padding: 10px 0;
  .header-row {
    display: flex;
    padding: 0 0 @headers-editor-padding 0;
    .header-cell {
      flex: 0 auto;
      &.header-cell-input {
        margin-right: @headers-editor-padding;
        flex: 1 auto;
      }
      button {
        i {
          color: @gray-light;
        }
        &:hover {
          i {
            color: @gray;
          }
        }
        &:active {
          i {
            color: @gray-dark;
          }
        }
      }
    }
    &.header-empty {
      opacity: 0.5;
      button {
        pointer-events: none;
      }
    }
  }
}
