@schedule-list-item-padding: 40px;
@schedule-list-item-actions-width: 120px;
.schedule-list-item(@generic-list-width) {
  .schedule-list-item {
    height: 126px;
    position: relative;
    display: grid;
    grid-template-columns: [schedule-name] 1fr 1fr [owner] 1fr [frequency] 1fr [last-run] 1fr [next-due] 1fr [dropdown] 80px;

    @media (max-width: @screen-sm) {
      grid-template-columns: [schedule-name] 1fr [frequency] 1fr [last-run] 1fr [next-due] 1fr [dropdown] 40px;
      padding-left: 10px;
    }

    .dropdown {
      @media (max-width: @screen-sm) {
        transform: rotate(90deg);
      }
    }

    .schedule-name {
      grid-column: 1 / span 2;
      padding-top: 30px;
      padding-left: 40px;
      display: flex;
      flex-flow: row;
      overflow: hidden;

      @media (max-width: @screen-sm) {
        padding-left: 0;
        grid-column: 1 / span 1;
      }

      .common-icon {
        @media (max-width: @screen-sm) {
          display: none;
        }
      }

      .schedule-name-container {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;

        @media (max-width: @screen-sm) {
          margin-left: 0;
        }
      }

      .schedule-description {
        padding-right: 30px;
        max-height: 55px;
        overflow: hidden;
        display: block;
        position: relative;
        word-break: break-all;
        word-wrap: break-word;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;

        @media (max-width: @screen-sm) {
          display: none;
        }
      }
      .name-container {
        padding-bottom: 3px;
        padding-right: 15px;
        white-space: nowrap;
        overflow: hidden;
        max-width: 100%;
        display: flex;
        flex-flow: column;
        align-items: flex-start;

        @media (max-width: @screen-sm) {
          display: flex;
          padding: 0;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        a {
          color: @gray-base;
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 3px;
          word-break: break-all;
          word-wrap: break-word;
          display: inline-block;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;

          @media (max-width: @screen-sm) {
            text-align: center;
          }

          &:hover {
            text-decoration: underline;
          }
        }
      }
      p {
        display: block;
        color: @gray;
        font-weight: 300;
        font-size: 13px;
      }
      .status-info {
        border-radius: 16px;
        padding: 3px 10px;
        margin-left: 20px;
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;

        @media (max-width: @screen-sm) {
          margin-left: 0;
          padding: 0;
          margin-top: 5px;
        }

        &:before {
          content: '';
          width: 5px;
          height: 5px;
          border-radius: 100%;
          margin-right: 5px;
          display: inline-block;
          margin-bottom: 2px;
        }

        &.active {
          background: rgba(18, 183, 106, 0.1);
          color: @brand-success-green;

          &:before {
            background: @brand-success-green;
          }
        }

        &.inactive {
          color: #3d4651;
          background: #f5f7f9;

          &:before {
            background: #3d4651;
          }
        }
      }
    }
    .schedule-owner {
      padding-top: 30px;
      overflow: hidden;

      @media (max-width: @screen-sm) {
        display: none;
      }

      a {
        color: @gray-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
        display: block;

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .schedule-repeat {
      padding-top: 30px;
      color: @gray;
      font-weight: 300;
      font-size: 13px;
      position: relative;
      padding-right: 10px;

      @media (max-width: @screen-sm) {
        text-align: center;
      }

      strong {
        font-size: 13px;
        font-weight: 600;
      }
      span {
        display: block;
        color: @gray;
        font-weight: 300;
        font-size: 13px;
      }
    }
    .schedule-last-run,
    .schedule-next-run {
      padding-top: 30px;
      color: @gray;
      font-weight: 300;
      font-size: 13px;

      @media (max-width: @screen-sm) {
        text-align: center;
      }

      strong {
        font-size: 13px;
        font-weight: 600;
      }
    }
    .schedule-menu {
      cursor: pointer;
      position: absolute;
      right: 0;
      svg {
        .bg {
          fill: lighten(@gray-light, 10%);
        }
        circle {
          fill: darken(@gray-light, 30%);
        }
      }
      &:hover {
        svg {
          .bg {
            fill: lighten(@gray-light, 5%);
          }
        }
        circle {
          fill: @gray-base;
        }
      }
    }
  }
}
