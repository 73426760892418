@member-list-item-image-width: 42px;
@member-list-item-actions-width: 120px;

.settings {
  .members-list-header {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: column;

    .members-list-header-description {
      width: 100%;
      margin-right: 20px;
      display: flex;
      flex-flow: column;

      h2 {
        margin-bottom: 10px;
      }
    }

    .generic-list-new-button {
      height: 52px;
      align-self: self-end;
      margin-top: -20px;
    }
  }
}

.generic-list .deactivated {
  .member-list-item {
    .advanced-role .role-icon svg {
      opacity: 0.5;
      fill: #848484;
      stroke: #848484;
    }
  }
}

.member-list-item(@generic-list-width) {
  .member-list-item {
    height: 60px;
    position: relative;
    grid-template-columns: 60px 1fr 200px 100px 100px 100px 100px 40px;
    border-left: 1px solid #eceef0;
    border-right: 1px solid #eceef0;

    @media (max-width: @screen-sm) {
      grid-template-columns: 100px 1fr 200px 40px;
    }

    a {
      color: @gray-base;
      font-size: 16px;
      font-weight: 600;
      &:hover {
        text-decoration: underline;
      }
    }
    .icon {
      width: 16px;
      height: 16px;
      cursor: pointer;
      color: @gray-light;

      &:hover {
        color: @brand-danger;
      }

      &.icon-edit {
        transform: scale(1.5);
        margin-top: 3px;
        stroke: #3f3f3f;
      }

      &.icon-delete {
        width: 16px;
        height: 16px;
        cursor: pointer;
        stroke-width: 1.25;
        stroke-linecap: round;
        stroke-linejoin: round;
        fill: transparent;
        stroke: #848484;
        margin-right: 20px;
        margin-bottom: 6px;

        &:hover {
          stroke: @brand-danger;
        }
      }
    }
    .member-image {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      img {
        width: 30px;
      }
    }
    .member-body {
      display: flex;
      height: 100%;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      flex-flow: column;
    }
    .member-role-picker,
    .icon-container {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
    .member-role-picker {
      justify-content: flex-start;
    }
    .list-member-email {
      color: darken(@gray-light, 20%);
      line-height: 1;
      margin: 0;
      font-weight: 300;
    }
    .list-member-name {
      margin-bottom: 4px;
      font-size: 16px;
    }
    .role-picker {
      &.wider {
        .xp-select {
          width: 160px;

          .mat-mdc-select {
            width: 148px;
          }
        }
      }

      .xp-select {
        width: 120px;

        .mat-mdc-select {
          width: 108px;
        }
      }
    }

    .advanced-role {
      .role-icon {
        svg {
          width: 25px;
          height: 25px;
          fill: #de4042;
          stroke: #de4042;
        }
        &.checked {
          svg {
            fill: #3ea449;
            stroke: #3ea449;
          }
        }
      }
    }
  }
}

.dropdown-menu {
  &.open {
    z-index: 9999 !important;
  }
}
