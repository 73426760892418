.alert-box-middle {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.alert-box {
  max-width: 1200px;
  .alert-box-container {
    background-color: inherit;
    border: 1px solid @gray-light;
    border-radius: 5px;
    padding: 45px 80px 45px 80px;
    margin: 30px 0 30px 30px;
    text-align: center;
    .alert-box-icon {
      margin-bottom: 10px;
    }
    h3.lead {
      margin: 10px 0 10px 0;
      font-weight: 700;
    }
    p {
    }
  }
  &.welcome {
    .alert-box-container {
      border: none;
      padding-top: 90px;
      .lead {
        margin-bottom: 30px;
      }
    }
  }
}
