.list-group {
  .list-group-item {
    border-bottom: 1px solid @gray-lighter;

    .btn-group {
      button {
      }
    }
  }
}
