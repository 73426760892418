.page {
  &.thank-you {
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    resize: vertical;
    overflow: auto;
    z-index: 3;
    position: relative;

    .container {
      flex-flow: column;
      max-width: 800px;
    }

    h1 {
      color: #fff;
      font-size: 35px;
    }
    h3 {
      color: #fff;
      font-size: 25px;
      font-weight: 300;
      margin-top: 0;
    }
  }
}
