@sidebar-width: 50px;
@sidebar-sub-width: 170px;
@nav-main-margin: -2px;

.sidebar {
  height: 100%;
  display: flex;
  flex-flow: row;

  &.is-boomerang {
    padding-bottom: 32px;
  }

  .is-desktop {
    display: initial;

    @media (max-width: @screen-sm) {
      display: none;
    }
  }

  .is-mobile {
    display: none;

    @media (max-width: @screen-sm) {
      display: initial;
      width: 100%;
    }
  }

  @media (max-width: @screen-sm) {
    left: -100vw;
    transition: all 200ms ease-in;
    height: 100vh;
    top: 0;
    position: absolute;
    width: 100vw;

    &.open {
      left: 0;
    }
  }
}

.sidebar-main {
  height: 100%;
  position: relative;
  float: left;
  width: @sidebar-width;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  background: linear-gradient(180deg, #00346c 0%, #081d34 76.52%);

  @media (max-width: @screen-sm) {
    width: 100%;
    padding-top: 50px;
  }

  .menu-top {
    width: @sidebar-width;
    display: flex;
    flex-direction: column;

    @media (max-width: @screen-sm) {
      width: 100%;
      margin-bottom: 80px;
      overflow: auto;
    }

    @media (max-width: @screen-sm) {
      width: 100%;
    }

    li {
      height: 50px;
      align-self: center;
      margin-top: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (max-width: @screen-sm) {
        width: 100%;
        margin: 0;
        display: flex;
        align-items: center;
        flex-flow: column;
        height: auto;
        padding: 10px 0;
      }

      &.with-border {
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        width: 100%;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        margin-top: -10px;
        display: none;

        .arrow-icon {
          font-size: 15px;
          margin-left: 20px;
          color: #ffffff;
        }

        .menu-icon {
          &,
          &:before,
          &:after {
            cursor: pointer;
            border-radius: 1px;
            height: 1px;
            width: 15px;
            background: #ffffff;
            position: absolute;
            display: block;
            content: '';
            left: 10px;
          }
          &:before {
            top: -7px;
            left: 0;
          }
          &:after {
            bottom: -7px;
            left: 0;
          }
        }

        .menu-icon,
        .arrow-icon {
          opacity: 0.5;
        }

        &:hover {
          .menu-icon,
          .arrow-icon {
            opacity: 1;
          }
        }
      }

      .logo-img {
        width: 35px;
      }

      a {
        display: flex;
        justify-content: center;
        width: @sidebar-width;

        &[disabled='disabled'] {
          cursor: @cursor-disabled !important;
          .opacity(0.65);
        }

        @media (max-width: @screen-sm) {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-flow: row;
        }
      }
      svg {
        opacity: 0.5;
      }
      &.active {
        svg {
          opacity: 1;
        }
        a {
          position: relative;

          &:before {
            content: '';
            width: 3px;
            background: @brand-primary;
            border-radius: 3px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 10;
          }
        }
      }

      svg,
      i {
        &.icon {
          margin-left: auto;
          margin-right: auto;
          width: 24px;
          height: 24px;

          @media (max-width: @screen-sm) {
            margin: 0;
            margin-right: 20px;
          }
        }
      }
    }
  }
  .menu-center {
    display: flex;
    align-items: center;
    flex-direction: column;

    .title {
      transform: rotate(-90deg);
      font-size: 20px;
      font-weight: 600;
      color: #ffffff;
      height: 50px;
      white-space: nowrap;
      line-height: 50px;
      text-shadow: 1px 1px #929292;
    }
  }
  .menu-bottom {
    display: flex;
    align-items: center;
    flex-direction: column;

    @media (max-width: @screen-sm) {
      position: absolute;
      top: 10px;
      flex-flow: row;
      width: 100vw;
      justify-content: space-around;
    }
  }

  .item-desc {
    display: none;

    @media (max-width: @screen-sm) {
      display: block;
      font-size: 20px;
      color: #ffffff;
    }
  }

  .user-name-placeholder {
    display: none;

    @media (max-width: @screen-sm) {
      display: block;
      width: 40px;
    }
  }
  .user-name {
    font-size: 16px;
    color: #fff;
    white-space: nowrap;
    text-transform: capitalize;
    line-height: 50px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    padding-top: 20px;

    @media (max-width: @screen-sm) {
      transform: none;
      writing-mode: inherit;
      padding-top: 0;
      margin-top: -10px;
    }

    @media (max-height: 850px) {
      max-height: 200px;
      overflow: hidden;
    }

    @media (max-height: 680px) {
      max-height: 100px;
      overflow: hidden;
    }

    .user-account-name {
      color: darken(@gray-light, 10%);
    }
  }
  .user-avatar {
    min-width: 40px;
    min-height: 40px;
    height: 40px;
    margin-bottom: 50px;
    max-width: 100%;
    cursor: pointer;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }

    @media (max-width: @screen-sm) {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }
  .user-logo {
    width: 40px;
  }
  .popover {
    overflow-y: auto;
    height: 50%;
    white-space: nowrap;
  }
  .dropup {
    position: absolute;
    bottom: 0;
    left: 0;
    svg,
    i {
      position: absolute;
      left: 20px;
      &.fa {
        font-size: 18px;
      }
    }
    span {
      padding-left: 25px;
    }
  }
}

.sidebar-sub {
  z-index: 1;
  border-right: 1px solid @gray-light;
  display: flex;
  background-color: #fff;
  width: 170px;
  height: 100vh;

  @media (max-width: @screen-sm) {
    width: 100%;
    border: none;
    background: none;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  .sub-menu {
    padding-top: 20px;
    padding-left: 15px;

    @media (max-width: @screen-sm) {
      padding: 0;
      width: 100%;
    }
  }
  .sub-menu-header {
    text-decoration: underline;

    @media (max-width: @screen-sm) {
      text-decoration: none;
      color: #ffffff;
    }
  }
  ul {
    margin-bottom: 30px;
  }
  li {
    text-decoration: none;
    margin-top: 10px;
    a {
      color: darken(@gray-light, 20%);

      @media (max-width: @screen-sm) {
        color: #ffffff;
      }

      &:before {
        @media (max-width: @screen-sm) {
          background: none !important;
        }
      }

      &:hover,
      &:visited {
        text-decoration: none;
      }
    }
    &.active {
      a {
        color: @brand-primary;

        @media (max-width: @screen-sm) {
          color: #ffffff;
        }

        &:after {
          font-family: 'FontAwesome', sans-serif;
          content: '\f0da';
          margin-left: 7px;
          font-size: 14px;
          vertical-align: top;
        }

        &:before {
          @media (max-width: @screen-sm) {
            background: @brand-primary !important;
          }
        }
      }
    }
    &[disabled] {
      a {
        pointer-events: none;
      }
    }
  }
}

.sidebar-accounts-menu {
  height: auto !important;
  border: 0;
  padding: 0;
  .popover-content {
    overflow-x: hidden;
    border: 0;
    .list-group {
      margin: 0;
    }
    padding: 0;
    .list-group-item {
      cursor: pointer !important;
      white-space: nowrap;
      min-width: 200px;
      padding: 5px 10px;
      &:hover {
        background-color: @gray-lighter;
      }
    }
  }
  .popover-title {
    padding: 12px 14px 10px;
  }
  .popover-footer {
    .btn {
      color: @gray-base;
      margin: 7px;
      &::after {
        font-family: 'FontAwesome', sans-serif;
        content: '\f08b';
        margin-left: 8px;
        color: darken(@gray-light, 30%);
        vertical-align: top;
      }
      &:hover {
        background-color: #fff;
      }
    }
  }
}
