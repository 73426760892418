//
// Buttons
// --------------------------------------------------
// Base styles
// --------------------------------------------------
.btn {
  display: inline-block;
  margin-bottom: 0; // For input.btn
  font-weight: @btn-font-weight;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
  border: 1px solid transparent;
  white-space: nowrap;
  .button-size(@padding-base-vertical; @padding-base-horizontal; @font-size-base; @line-height-base; @btn-border-radius-base);
  user-select: none;
  &,
  &:active,
  &.active {
    &:focus,
    &.focus {
      .tab-focus();
    }
  }
  &:hover,
  &:focus,
  &.focus {
    color: @btn-default-color;
    text-decoration: none;
  }
  &:active,
  &.active {
    outline: 0;
    background-image: none;
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  }
  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: @cursor-disabled;
    background-color: @gray-light;
    pointer-events: none;
    .opacity(0.65);
    box-shadow: none;
  }
  a& {
    &.disabled,
    fieldset[disabled] & {
      pointer-events: none; // Future-proof disabling of clicks on `<a>` elements
    }
  }
}

// Alternate buttons
// --------------------------------------------------
.btn-default {
  .button-variant(@btn-default-color;
            @btn-default-bg;
            @btn-default-border);
  background: #ffffff;
  border: none;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}

.btn-primary {
  .button-variant(@btn-primary-color;
    @btn-primary-bg;
    @btn-primary-border);
  border-radius: 8px;
  border: none;
}

// Success appears as green
.btn-success {
  .button-variant(@btn-success-color;
    @btn-success-bg;
    @btn-success-border);
  border: none;
  border-radius: 8px;
}

// Info appears as blue-green
.btn-info {
  .button-variant(@btn-info-color;
    @btn-info-bg;
    @btn-info-border);
  border-radius: 8px;
  border: none;
  font-weight: 600;

  &[disabled] {
    color: #ffffff;
    background: #bdc5cf;
  }
}

.btn-black {
  .button-variant(@btn-black-color; @btn-black-bg; @btn-black-border);
  border-radius: 8px;
  border: none;
  font-weight: 600;

  &[disabled] {
    color: #ffffff;
    background: #3d3d3d;
  }
}

.btn-gray {
  .button-variant(@btn-gray-color; @btn-gray-bg; @btn-gray-border);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;

  &[disabled] {
    opacity: 0.6;
    cursor: not-allowed;
  }
}

// Warning appears as orange
.btn-warning {
  .button-variant(@btn-warning-color;
    @btn-warning-bg;
    @btn-warning-border);
  border-radius: 8px;
  border: none;
}

// Danger and error appear as red
.btn-danger {
  .button-variant(@btn-danger-color;
    @btn-danger-bg;
    @btn-danger-border);
  border-radius: 8px;
  border: none;
}

// Danger and error appear as red
.btn-danger2 {
  .button-variant(@btn-danger2-color;
          @btn-danger2-bg;
          @btn-danger2-border);
  border-radius: 8px;
  border: 1px solid @btn-danger2-border;
}

// Link buttons
// -------------------------
// Make a button look and behave like a link
.btn-link {
  color: #3d4651;
  font-weight: normal;
  border-radius: 8px;
  border: 1.5px solid #bdc5cf;
  padding: 10px 20px;

  &,
  &:active,
  &.active,
  &[disabled],
  fieldset[disabled] & {
    background-color: transparent;
    box-shadow: none;
  }
  &:hover,
  &:focus {
    background-color: #f5f7f9;
  }
  &[disabled],
  fieldset[disabled] & {
    &:hover,
    &:focus {
      color: @btn-link-disabled-color;
      text-decoration: none;
    }
  }
}

// Button Sizes
// --------------------------------------------------
.btn-lg {
  .button-size(@padding-large-vertical*1.4; @padding-large-horizontal*1.4; @font-size-large*0.9; @line-height-large; @btn-border-radius-large);
}

.btn-sm {
  .button-size(@padding-small-vertical; @padding-small-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}

.btn-xs {
  .button-size(@padding-xs-vertical; @padding-xs-horizontal; @font-size-small; @line-height-small; @btn-border-radius-small);
}
.btn-xlg {
  .button-size(@padding-large-vertical*1.8; @padding-large-horizontal*1.8; @font-size-large; @line-height-large; @btn-border-radius-large);
}

// Block button
// --------------------------------------------------
.btn-block {
  display: block;
  width: 100%;
}

// Vertically space out multiple block buttons
.btn-block + .btn-block {
  margin-top: 5px;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
  &.btn-block {
    width: 100%;
  }
}

.btn-xplenty {
  padding: 14px 18px !important;
  font-size: @font-size-large*0.9;
}

.toggle-group {
  .active {
    box-shadow: inherit;
    border-color: @gray-lighter;
    &:hover {
      border-color: @gray-light;
    }
  }
}
