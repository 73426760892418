.billing {
  max-width: 600px;

  .update-credit-card-btn {
    margin-bottom: 20px;
    color: #fff;
  }
  .invoices-btn {
    margin-bottom: 20px;
    margin-left: 10px;
  }
}
