.editor-button {
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  position: relative;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &.dnd-button-schema-importer {
    width: 15px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      width: 0;
      height: 10px;
      font-size: 13px;
    }
  }

  .base-icon {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.03);
  }
  i {
    color: @gray;
    font-size: 10px;
    width: 26px;
    height: 26px;
  }
  &:hover {
    i {
      color: @text-color;
    }
    .base-icon {
      background-color: rgba(0, 0, 0, 0.5);
      i {
        color: #fff;
      }
    }
  }
  &:active {
    .base-icon {
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}
