.ordered-fields-picker {
  min-height: 32px;
  padding: 0 5px;

  &.error {
    background-color: lighten(@brand-warning, 45%);
    .tooltip-inner {
      background-color: lighten(@brand-warning, 45%);
      border-color: lighten(@brand-warning, 45%);
      color: darken(@brand-warning, 10%);
      font-weight: 400;
      padding: 5px 10px;
    }
    .tooltip-arrow {
      border-bottom-color: lighten(@brand-warning, 45%);
      border-top-color: lighten(@brand-warning, 45%);
    }
  }
}

.fields-collection-row {
  &.error {
    .multi {
      .item {
        background-color: lighten(@brand-warning, 45%) !important;
        border-color: lighten(@brand-warning, 45%) !important;
        color: @text-color !important;
      }
    }
  }
}
