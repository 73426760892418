.input-checkbox {
  padding: 0 !important;
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  &.readonly {
    pointer-events: none;
    cursor: not-allowed;

    .fa {
      opacity: 0.3;
    }
  }

  .input-checkbox-content {
    display: flex;
    align-items: baseline;
    flex-flow: row;
  }

  .input-checkbox-hint {
    color: @gray-lighter;
  }

  .input-checkbox-label {
    margin-left: 10px;
  }
}
