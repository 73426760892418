.modal-panel {
  position: absolute;
  z-index: 1071;
  width: 100%;
  left: 0;
  background-color: #fff;
  .modal-panel-wpapper {
    position: relative;
    .modal-panel-close {
      position: absolute;
      top: 42px;
      right: 42px;
      z-index: 1;
      font-size: 40px;
      color: @gray-light;
      cursor: pointer;
      &:hover {
        color: @gray;
      }
    }
    .modal-panel-body {
      position: relative;
      z-index: 0;
    }
  }
  &.animate {
    transition: top 150ms ease-in;
  }
}
