.account-settings {
  .members-list {
    li {
      img {
        width: 42px;
        height: 42px;
        margin-right: 10px;
      }
    }
  }
}
