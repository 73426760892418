.job-progress {
  padding: 0 0 0 0 !important;
  position: relative;

  .job-empty {
    text-align: center;
    font-size: 16px;
    margin: 20px 30px;
  }

  .job-progress-task {
    position: relative;
    border-bottom: 1px solid @gray-light;
    background-color: #fff;
    .job-progress-task-grid {
      min-height: 120px;
      cursor: pointer;
      grid-template-columns: 1fr 150px 150px 150px;
      display: grid;

      @media (max-width: @screen-sm) {
        grid-template-columns: 1fr 80px 80px 70px;
      }

      .job-progress-task-id,
      .job-progress-task-input,
      .job-progress-task-output,
      .job-progress-task-runtime {
        flex: 1 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        strong {
          display: flex;
          align-items: center;
          max-width: 100%;
        }
        span {
          &.name {
            flex: 1 auto;
            font-size: 18px;
            font-weight: 600;
            display: block;
            color: @text-color;
            word-break: break-all;
            word-wrap: break-word;
            padding-right: 15px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;

            @media (max-width: @screen-sm) {
              padding-right: 5px;
            }
          }
          &.id {
            color: @gray;
            font-size: 12px;
            font-weight: 300;
            i {
              background-color: lighten(@brand-warning, 20%);
            }
          }
        }
      }
      .job-progress-task-id {
        padding-left: 50px;
        flex-flow: column;
        align-items: flex-start;
        overflow: hidden;

        @media (max-width: @screen-sm) {
          padding-left: 5px;
        }
        svg {
          margin-right: 10px;
        }
        span {
          &.id {
            color: @gray;
            margin-left: 44px;
            display: block;
          }
        }
      }
      .job-progress-task-input,
      .job-progress-task-output,
      .job-progress-task-runtime {
        .loader {
          padding: 0 !important;
          transform: scale(0.5);
          height: 16px;
        }
      }
    }
    &.failed,
    &.completed,
    &.running,
    &.stopped {
      &:before {
        content: '';
        width: 0;
        height: 0;
        position: absolute;
        bottom: 0;
        left: 0;
        border-right: 10px solid transparent;
      }
    }
    &.failed {
      border-bottom: 1px solid @brand-danger;
      &:before {
        border-bottom: 10px solid @brand-danger;
      }
    }
    &.completed {
      border-bottom: 1px solid @brand-success-green;
      &:before {
        border-bottom: 10px solid @brand-success-green;
      }
    }
    &.stopping,
    &.pending_stoppage,
    &.stopped {
      border-bottom: 1px solid darken(@brand-warning, 20%);
      &:before {
        border-bottom: 10px solid darken(@brand-warning, 20%);
      }
    }
    &.running {
      border-bottom: 1px solid @brand-primary;
      &:before {
        border-bottom: 10px solid @brand-primary;
      }
    }
    &:hover {
      //background-image: linear-gradient(0deg, lighten(@gray-lighter, 5%), #FFFFFF);
    }
    &.open {
      background-image: linear-gradient(0deg, lighten(@gray-lighter, 5%), lighten(@gray-lighter, 5%));
      &:after {
        display: block;
      }
    }
  }
}
