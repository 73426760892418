.terminal-component {
  //position:fixed !important;
  height: 100%;
  top: auto !important;
  bottom: 0 !important;
  right: 0;
  z-index: 1;
  background-color: #000;
  min-height: 200px;
  //&.open {
  //	right: 0;
  //	left: @sidebar-width;
  //}
  .btn-link {
    color: @gray-dark;
  }
  .toggle {
    display: none;
  }
  .fa-terminal {
    color: #fff;
    background-color: #333;
    padding: 3px;
    border-radius: 3px;
  }
  .terminal-header {
    display: none;
    background-color: #fff;
    border-top: 1px solid @gray-dark;
    span {
      padding: 5px;
      display: inline-block;
    }
  }
  .terminal-body {
    background-color: #000;
    height: 100% !important;
    width: 100% !important;
  }
}
