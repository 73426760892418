.xp-time-picker {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  padding: 5px 0;

  .time-control {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 16px;
      transition: all 0.1s ease-in;
      border-radius: 8px;
      padding: 5px 10px;
      color: #3f3f3f;

      &:hover {
        background-color: #eceef0;
      }
    }

    input {
      width: 30px;
      text-align: center;
      border: none;
      font-size: 16px;
      margin: 5px 0;
      background-color: #ffffff;
    }
  }
}
