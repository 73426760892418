#functions-modal,
#shortcuts-modal {
  max-height: calc(100vh - 180px);
  overflow: scroll;
}

.console-container {
  padding: 20px 10px;
  position: relative;

  &.full-size {
    height: calc(100vh - 100px);
    width: calc(100vw - 50px);
  }

  #terminal {
    height: 100%;
  }

  .btn {
    top: 70px;
    position: absolute;
    background-color: white;
    padding: 4px;
    height: 35px;
    width: 35px;
    border-radius: 0;
    border: 1px solid grey;
    color: #3f3f3f;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }

  .shortcuts-btn {
    right: 64px;
  }

  .functions-btn {
    right: 30px;
  }
}

#help-shortcuts-content .shortcut {
  width: 6em;
  text-align: right;
  display: inline-block;
  line-height: 40px;
  margin-right: 5px;
}

#help-shortcuts-content kbd {
  padding: 3px 5px;
  background: #333;
  border: 1px transparent solid;
  color: #eee;
  font-size: 11px;
  font-family: Consolas, 'Liberation Mono', Menlo, Courier, monospace;
  border-radius: 2px;
}

#help-shortcuts-content kbd em {
  color: #bbb;
  padding: 0 3px;
}

.function .description {
  display: block;
  margin-top: 3px;
  color: @gray;
  font-size: @font-size-base - 1;
}

.ngdialog-theme-default {
  &.xplenty-console-shortcuts,
  &.xplenty-console-functions {
    padding-top: 100px;

    .title {
      margin: 0;
    }

    .modal-footer {
      padding-bottom: 0;
    }
  }

  &.xplenty-console-functions {
    .ng-dialog-content {
      padding: 0;
    }
  }
}

.search-query {
  width: 100%;
  height: 30px;
  background-color: #f7f7f7;
  border: 1px solid #c3c3c3;
  padding: 5px;

  &:focus {
    border-color: @brand-primary;
  }
}

.functions-list {
  max-height: 300px;
  overflow: auto;

  .function {
    border: 1px solid lightgrey;
    display: flex;
    width: 100%;
    margin: 0;

    &:not(:first-child) {
      border-top: 0;
    }

    .function-container {
      padding: 5px;
      width: 100%;
      cursor: pointer;
      border-bottom: 3px solid transparent;
      color: #ccc;
      margin-right: 2px;
      line-height: 1.42857143;
      border-radius: 4px 4px 0 0;

      &:hover {
        border-color: #e6e6e6 #e6e6e6 #ddd;
        background-color: #e6e6e6;
      }
    }

    .title {
      color: @brand-primary;
    }

    .description {
      margin-top: 30px;
    }
  }
}

.browser-bar {
  width: 100%;
  height: 34px;
  position: relative;
  background-color: #eff1f4;
  border-radius: 5px 5px 0 0;

  .actions {
    margin-left: 5px;
    height: 100%;
    display: flex;
    align-items: center;

    .dot {
      border-radius: 100%;
      height: 13px;
      width: 13px;
      margin-left: 10px;

      &.red {
        background-color: #fc605b;
      }
      &.yellow {
        background-color: #fdbd41;
      }
      &.green {
        background-color: #33c748;
      }
    }
  }
}

.terminal {
  font-family: monospace;
  color: rgba(170, 170, 170, 0.99);
  background-color: #000;
  font-size: 12px;
  line-height: 14px;
}

.terminal {
  padding: 10px;
  position: relative;
  overflow: auto;
}

.xterm .xterm-helpers {
  position: relative;
}

.xterm {
  * {
    font-family: monospace !important;
  }
}
