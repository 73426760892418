assisted-search {
  width: 100%;
  margin: 0 30px 0 0;

  @media (max-width: @screen-sm) {
    margin: 0 0 10px;
  }

  .assisted-search {
    margin: 0;
  }
}

.assisted-search {
  border: 1px solid @gray-light;
  border-radius: 8px;
  position: relative;
  flex: 1;
  margin: 0 30px;
  display: flex;
  align-items: center;
  flex-direction: inherit;
  -webkit-font-smoothing: antialiased;
  width: 100%;
  z-index: 500;

  &:hover,
  &.search-focused {
    border: 1px solid @brand-primary;

    .submit.btn {
      border-left: 1px solid @brand-primary;
    }
  }

  svg {
    width: 20px;
    height: 100%;
    margin-left: 10px;
    position: absolute;
    top: 0;

    .clear-query-button {
      cursor: pointer;
    }
  }

  .submit.btn {
    border-left: 1px solid @gray-light;
    line-height: 50px;
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
    border-radius: 0 8px 8px 0;
    height: 100%;
    padding: 0 28px;

    &:hover {
      background-color: @brand-primary;
      border-left: 1px solid @brand-primary;
      color: #fff !important;
    }
  }

  input.main-input {
    height: 30px;
    width: 100%;
    font-size: 18px;
    border: none;
    overflow: hidden;
    background: transparent;
    margin: 0 0 0 45px;
    padding: 0;
    outline: 0;
    z-index: 2;
    letter-spacing: 0;
    word-spacing: 0;
    position: relative;
    font-family: @font-family-sans-serif;
    direction: ltr;
  }

  .background-container {
    color: transparent;
    position: absolute;
    left: 45px;
    right: 105px;
    top: 13px;
    z-index: 1;
    outline: rgb(85, 84, 89) none 0;
    font-size: 18px;
    font-family: @font-family-sans-serif;
    letter-spacing: 0;
    word-spacing: 0;
    margin: 0;
    white-space: pre;
    overflow: hidden;
    .hint-value {
      color: @input-color-placeholder;
    }
  }

  .hint-menu-container {
    position: absolute;
    left: 40px;
    top: 33px;

    @media (max-width: @screen-sm) {
      left: 5px;
    }

    .arrow {
      &::after {
        content: '';
        display: block;
        width: 11px;
        height: 11px;
        background-clip: padding-box;
        background-color: @dropdown-bg;
        transform: rotate(45deg);
      }
      position: absolute;
      top: 7px;
      left: 20px;
      z-index: 40;
    }

    .arrow-border {
      &::after {
        content: '';
        display: block;
        width: 12px;
        height: 11px;
        background-clip: padding-box;
        transform: rotate(45deg);
      }
      position: absolute;
      top: 6px;
      left: 20px;
    }

    .hint-menu {
      .box-shadow(0 0px 12px rgba(0, 0, 0, 0.175));
      position: absolute;
      background-color: @dropdown-bg;
      top: 12px;
      left: 0;
      min-width: 400px;
      padding: 0;
      border: 1px solid @dropdown-border;
      border-radius: @border-radius-base;

      @media (max-width: @screen-sm) {
        min-width: auto;
      }

      &.with-help {
        width: 360px;
        padding: 10px 20px;

        @media (max-width: @screen-sm) {
          width: 80vw;
        }

        .help-title {
          font-weight: 600;
          margin: 5px 0 6px 0;
        }
        .help-text {
          font-size: @font-size-base;
          margin: 5px 0 6px 0;
        }
      }
      .section-header {
        position: relative;
        margin: 10px 20px;
        font-size: @font-size-base;
        hr {
          width: 100%;
          top: 10px;
          margin: 0 !important;
          position: absolute;
        }
        .header-label {
          text-transform: capitalize;
          color: @dropdown-header-color;
          background: @dropdown-bg;
          z-index: 20;
          position: relative;
          padding: 2px 10px 0 0;
          margin: 0;
          font-size: 14px;
        }
      }
    }
  }

  .background-container,
  .hint-menu.with-help {
    .modifier {
      position: relative;
      z-index: 10;
      display: inline-block;
      box-sizing: border-box;
      border-radius: @border-radius-base;
      border: none;
      background: @alert-info-bg;
      &.incomplete {
        background: @alert-warning-bg;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  .hint-menu.with-help .modifier {
    cursor: pointer;
    font-size: @font-size-base*0.95;
  }

  ul.search-input-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
    overflow-y: scroll;
    position: relative;
    max-height: 240px;
    box-sizing: border-box;

    li {
      color: @dropdown-header-color;
      margin: 0;
      padding: 10px 20px;
      cursor: pointer;
      img {
        max-height: 20px;
        max-width: 20px;
        border-radius: @border-radius-base;
        margin-right: 4px;
        margin-top: -4px;
      }
      .modifier-option {
        color: @dropdown-link-color;
        font-weight: 600;
      }
      .modifier-value {
        color: @dropdown-header-color;
        margin-left: 3px;
      }
      &:hover {
        .modifier-value {
          color: @dropdown-header-color;
        }
        .modifier-option {
          color: @dropdown-link-hover-color;
        }
        background-color: @dropdown-link-hover-bg;
      }
      &.current {
        .modifier-value {
          color: @dropdown-link-disabled-color;
        }
        .modifier-option {
          color: @dropdown-link-active-color;
        }
        background-color: @dropdown-link-active-bg;
      }
    }
  }
}
