.job-general {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  .job-item-container {
    flex: 20%;
    position: relative;
    border-radius: @border-radius-large;
    margin: 10px;
    padding: 40px;
    border: 1px solid @gray-lighter;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    &.runtime {
      flex-direction: row;
      justify-content: space-between;
      p {
        margin-left: 30px;
      }
    }
  }
  .job-item-title {
    position: absolute;
    bottom: -10px;
    left: 0;
    background-color: lighten(@gray-lighter, 4%);
    border-top: 1px solid @gray-lighter;
    width: 100%;
    font-size: 10px;
    text-transform: uppercase;
    padding: 10px 15px;
    // letter-spacing: 1px;
    color: lighten(@gray-base, 30%);
  }
  .job-data {
    &.job-id {
      font-size: 40px;
    }
  }
  .job-status-percent {
    font-size: 50px;
    font-weight: 100;
  }
}
