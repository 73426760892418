@keyframes icon-animation {
  0% {
    background-color: red;
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes step-dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -123;
  }
}

.steps .small-padding .step .step-body .step-body-container {
  padding: 20px;
}

.steps {
  display: flex;
  flex-direction: column;
  .step {
    position: relative;
    z-index: 0;
    border-radius: 10px;

    &:hover {
      .step-header {
        background: #f5f7f9;
      }
    }

    &.active {
      background: #f5f7f9;
      border-radius: 10px;
    }

    .step-header {
      display: flex;
      flex-direction: row;
      cursor: pointer;
      border-radius: 10px;
      padding: 5px;
      height: 70px;

      .step-header-index {
        position: relative;
        flex: 1 auto;
        width: 60px;
        min-width: 60px;
        max-width: 60px;
        height: 60px;
        text-align: center;

        span {
          position: absolute;
          top: 8px;
          left: 8px;
          display: inline-block;
          width: 44px;
          height: 44px;
          line-height: 44px;
          font-weight: 600;
          font-size: 18px;
          color: #bdc5cf;
          background: #f5f7f9;
          border-radius: 10px;
          user-select: none;

          transition: all 0.25s ease-in-out;
        }
        &:not(.loading) {
          svg {
            top: 8px;
            left: 8px;
            position: absolute;
            width: 44px;
            height: 44px;
            &.circleFill {
              z-index: 1;
              stroke-dasharray: 200;
              stroke-dashoffset: 200;
              transition: stroke-dashoffset 1s;
              transform: rotate(-90deg);
              stroke: @gray-lighter;
              stroke-width: 3px;
            }
            &.filled {
              stroke-dashoffset: 60px / 2;
            }
            &.circleTrack {
              stroke: @gray-lighter;
              z-index: 0;
              stroke-width: 2px;
            }
          }
        }
        i {
          line-height: 16px;
          text-align: center;
          position: absolute;
          font-size: 9px;
          top: 8px;
          right: 8px;
          height: 16px;
          width: 16px;
          border-radius: 50%;
          z-index: 2;
          color: #fff;
          background-color: @gray-lighter;
        }
        &.valid {
          span {
            color: @brand-primary;
            background: @brand-info;
          }
          i {
            background-color: @brand-success-green;
          }
          svg {
            &.circleFill {
              stroke: @brand-primary;
            }
            &.circleTrack {
              stroke: @brand-primary;
            }
          }
        }
        &.active {
          &.valid {
            span {
              background: #f5f7f9;
              color: @brand-primary;
            }
          }
          span {
            color: @text-color;
            font-weight: 900;
          }
        }
        &.error {
          span {
            color: @brand-warning;
          }
          svg {
            &.circleFill {
              stroke: @brand-warning;
            }
            &.circleTrack {
              stroke: @brand-warning;
            }
          }
          i {
            background-color: @brand-danger;
          }
        }
        &.loading {
          svg {
            &.filled {
              top: 8px;
              left: 8px;
              width: 44px;
              height: 44px;
              position: absolute;
              animation: rotate 2s linear infinite;
              transform-origin: center center;
              stroke: @brand-primary;
              stroke-width: 3px;
              z-index: 1;
              .path {
                animation: step-dash 1.5s ease-in-out infinite;
                stroke-linecap: round;
              }
            }
            &.circleTrack {
              stroke: @gray-lighter;
              z-index: 0;
              stroke-width: 2px;
              top: 8px;
              left: 8px;
              width: 44px;
              height: 44px;
              position: absolute;
            }
          }
        }
      }
      .step-header-title {
        flex: 0 auto;
        line-height: 60px;
        font-size: 18px;
        white-space: nowrap;
        padding-left: 5px;
        user-select: none;
      }
      .step-header-tags {
        flex: 1 auto;
        font-size: 13px;
        font-weight: 300;
        margin: 8px 10px;
        padding-left: 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        border-left: 1px solid @gray-lighter;
        overflow: hidden;
        .step-header-tag {
          background-color: lighten(@gray-lighter, 5%);
          margin: 1px 2px 2px 1px;
          padding: 0 5px;
          flex: 0 auto;
          border-radius: 3px;
          color: @gray;
          .tag-icon {
            width: 14px;
            height: 14px;
            margin: -1px 5px 0 0;
          }
          svg {
            padding: 2px 0;
            height: 18px !important;
            width: 12px !important;
            float: left;
            margin: 0 5px 0 1px;
          }
          span,
          b {
            transform: translate(0, 2px);
            display: inline-block;
            font-weight: 900;
            color: @text-color;
            max-width: 280px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 12px;
            height: 12px;
          }
          span {
            color: @gray;
            font-weight: 300;
          }
        }
      }
    }
    .step-body {
      overflow: hidden;
      margin-left: 60px / 2;
      border-left: 1px solid @gray-lighter;

      &.xp-step-body {
        transition: height 0.2s ease-out;
        will-change: height;

        &.expand {
          transition: height 0.2s ease-in;
        }
      }
      .step-body-container {
        padding: (60px / 3) 60px;

        @media (max-width: @screen-sm) {
          padding: 5px;
        }

        &.wider {
          padding: (60px / 3) 0;

          @media (max-width: @screen-sm) {
            padding: 5px;
          }
        }
      }
      .step-buttons {
        padding: 0 60px (60px / 3);

        @media (max-width: @screen-sm) {
          padding: 5px;
        }
        .btn-default {
          margin-right: 10px;
        }
      }
      &.visible {
        overflow: visible;
        height: auto !important;
      }
      .version {
        color: @brand-primary;
        font-weight: 600;
      }
    }
    &.active {
      z-index: 1;
      .step-header-title {
        font-weight: 900;
      }
    }
    &.valid {
    }
    &.lock {
      cursor: not-allowed;
      * {
        pointer-events: none;
      }
    }
    &.error {
      .step-header {
        .step-header-title {
          color: @brand-warning;
          font-weight: 900;
        }
        &:hover {
          span {
            //background-color: @gray-lighter;
          }
        }
      }
    }
    &:last-child {
      .step-body {
        border-left: 1px solid transparent;
      }
    }
  }
}
