@wizard-tabs-color: @gray-light;
@wizard-tab-color: @gray-light;
@wizard-tab-color-active: @brand-primary;
@wizard-tab-number-font-size: 12px;
@wizard-height: 40px;

.wizard {
  overflow: hidden;
  width: 100%;
  .wizard-tabs {
    display: flex;
    height: @wizard-height;
    overflow: hidden;
    margin-bottom: 15px;
    //background-color: @wizard-tabs-color;
    .wizard-tab {
      position: relative;
      cursor: pointer;

      //width: 180px;
      height: @wizard-height;
      line-height: @wizard-height;
      border: 0 none;

      padding: 0 10px 0 45px;
      border-radius: 0;

      .wizard-tab-number {
        position: absolute;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        top: (@wizard-height - 20px) / 2;
        left: 18px;
        font-size: @wizard-tab-number-font-size;
        font-weight: 600;
        border-radius: 50%;
        pointer-events: none;
        background-color: rgba(0, 0, 0, 0.05);
      }

      &:first-child {
        padding: 0 10px 0 35px;
        border-radius: 4px 0 0 4px;
        .wizard-tab-number {
          left: 10px;
        }
      }
      &:last-child {
        padding: 0 10px 0 45px;
        border-radius: 0 4px 4px 0;
      }
      &:after {
        border-left-color: #fff !important;
      }
      &:before {
        border-left-color: @wizard-tab-color !important;
      }

      &:hover {
        background-color: darken(#fff, 5%);
        &:after {
          border-left-color: darken(#fff, 5%) !important;
        }
      }

      // &:hover {
      // 	background-color: darken(@wizard-tab-color, 10%);
      // 	&:after,
      // 	&:before {
      // 		border-left-color: darken(@wizard-tab-color, 10%);
      // 	}
      // }
      // &:after,
      // &:before {
      // 	left: 100%;
      // 	top: 50%;
      // 	position: absolute;
      // 	border: solid transparent;
      // 	content: " ";
      // 	z-index: 1;
      // }
      // &:after {
      // 	border-left-color: @wizard-tab-color;
      // 	border-width: @wizard-height / 2;
      // 	margin-top: -(@wizard-height / 2);
      // }
      // &:before {
      // 	border-left-color: darken(@wizard-tabs-color, 30%);
      // 	border-width: @wizard-height / 2;
      // 	margin-top: -(@wizard-height / 2);
      // 	border-width: 4px;
      // }
      &.active {
        background-color: @brand-primary;
        cursor: default;
        color: #fff;
        .wizard-tab-number {
          background-color: #fff;
          color: @brand-primary;
        }
        box-shadow: none;
        &:after,
        &:before {
          border-left-color: @brand-primary !important;
        }
      }
    }
  }
  .wizard-wrapper {
    transition: all 250ms ease-out;
    .wizard-step {
      float: left;
    }
  }
}

/** The Magic **/
.btn-breadcrumb .btn:not(:last-child):after {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: (@wizard-height / 2) solid transparent;
  border-bottom: (@wizard-height / 2) solid transparent;
  border-left: 10px solid white;
  position: absolute;
  top: 50%;
  margin-top: -(@wizard-height / 2);
  right: -9px;
  z-index: 3;
}
.btn-breadcrumb .btn:not(:last-child):before {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  border-top: (@wizard-height / 2) solid transparent;
  border-bottom: (@wizard-height / 2) solid transparent;
  border-left: 12px solid rgb(173, 173, 173);
  position: absolute;
  top: 50%;
  margin-top: -(@wizard-height / 2);
  margin-left: 1px;
  right: -9px;
  z-index: 3;
}

/** Default button **/
.btn-breadcrumb .btn.btn-default:not(:last-child):after {
  border-left: 10px solid #fff;
}
.btn-breadcrumb .btn.btn-default:not(:last-child):before {
  border-left: 10px solid #ccc;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):after {
  border-left: 10px solid @wizard-tabs-color;
}
.btn-breadcrumb .btn.btn-default:hover:not(:last-child):before {
  border-left: 10px solid @wizard-tabs-color;
}

/** Primary button **/
.btn-breadcrumb .btn.btn-primary:not(:last-child):after {
  border-left: 10px solid #428bca;
}
.btn-breadcrumb .btn.btn-primary:not(:last-child):before {
  border-left: 10px solid #357ebd;
}
.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):after {
  border-left: 10px solid #3276b1;
}
.btn-breadcrumb .btn.btn-primary:hover:not(:last-child):before {
  border-left: 10px solid #285e8e;
}

/** Success button **/
.btn-breadcrumb .btn.btn-success:not(:last-child):after {
  border-left: 10px solid #5cb85c;
}
.btn-breadcrumb .btn.btn-success:not(:last-child):before {
  border-left: 10px solid #4cae4c;
}
.btn-breadcrumb .btn.btn-success:hover:not(:last-child):after {
  border-left: 10px solid #47a447;
}
.btn-breadcrumb .btn.btn-success:hover:not(:last-child):before {
  border-left: 10px solid #398439;
}

/** Danger button **/
.btn-breadcrumb .btn.btn-danger:not(:last-child):after {
  border-left: 10px solid #d9534f;
}
.btn-breadcrumb .btn.btn-danger:not(:last-child):before {
  border-left: 10px solid #d43f3a;
}
.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):after {
  border-left: 10px solid #d2322d;
}
.btn-breadcrumb .btn.btn-danger:hover:not(:last-child):before {
  border-left: 10px solid #ac2925;
}

/** Warning button **/
.btn-breadcrumb .btn.btn-warning:not(:last-child):after {
  border-left: 10px solid #f0ad4e;
}
.btn-breadcrumb .btn.btn-warning:not(:last-child):before {
  border-left: 10px solid #eea236;
}
.btn-breadcrumb .btn.btn-warning:hover:not(:last-child):after {
  border-left: 10px solid #ed9c28;
}
.btn-breadcrumb .btn.btn-warning:hover:not(:last-child):before {
  border-left: 10px solid #d58512;
}

/** Info button **/
.btn-breadcrumb .btn.btn-info:not(:last-child):after {
  border-left: 10px solid #5bc0de;
}
.btn-breadcrumb .btn.btn-info:not(:last-child):before {
  border-left: 10px solid #46b8da;
}
.btn-breadcrumb .btn.btn-info:hover:not(:last-child):after {
  border-left: 10px solid #39b3d7;
}
.btn-breadcrumb .btn.btn-info:hover:not(:last-child):before {
  border-left: 10px solid #269abc;
}
