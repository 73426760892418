.main-wrapper {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  width: 100vw;
  height: 100vh;

  .mobile-icon {
    display: none;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    margin: 10px;
    border-radius: 10px;
    background: transparent;
    border: none;
    z-index: 99;
    position: absolute;
    top: -5px;
    left: 5px;

    @media (max-width: @screen-sm) {
      display: flex;
    }

    &.active {
      background-color: #8b8b8b;

      span,
      span:before,
      span:after {
        background: #000000;
      }
    }

    span,
    span:before,
    span:after {
      cursor: pointer;
      border-radius: 1px;
      height: 3px;
      width: 30px;
      background: #000000;
      position: absolute;
      display: block;
      content: '';
      transition: all 200ms ease-in;
    }
    span:before {
      top: -10px;
    }
    span:after {
      bottom: -10px;
    }

    &.open {
      span,
      span:before,
      span:after {
        background: #ffffff;
      }
      span {
        transform: rotate(45deg);
      }

      span:before {
        transform: rotate(-90deg);
        top: 0;
      }
      span:after {
        display: none;
      }
    }
  }

  &.is-boomerang {
    margin-top: 32px;
    height: calc(100vh - 32px);
  }

  .sidebar-wrapper {
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transition: left 250ms ease-out;
  }

  .app-container-welcome {
    margin-left: 50px;
  }
  .page-content-wrapper {
    position: absolute;
    top: 0;
    z-index: 0;
    width: calc(100% - 50px);
    left: 50px;
    transition: left 250ms ease-out;

    @media (max-width: @screen-sm) {
      left: 0;
      width: 100%;
    }

    &.is-settings-route {
      width: calc(100% - 220px);
      left: 220px;

      @media (max-width: @screen-sm) {
        left: 0;
        width: 100%;
      }
    }

    .page-scroll-wrapper {
      position: relative;
      z-index: 0;
      height: calc(100vh - 50px);
      overflow: auto;
    }
  }
  .sidebar-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.75);
  }
  .page-top-header {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid @gray-light;
  }
  .sidebar-toggle {
    position: absolute;
    top: 10px;
    font-size: 22px;
    z-index: 2;
    cursor: pointer;
    transition: left 250ms ease-out;

    &.open {
      i {
        color: #fff;
      }
    }
  }
}

body {
  overflow: hidden;
  overflow-x: auto;
  overflow-anchor: none;
}
