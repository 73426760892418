.account-form {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100%;

  .account-form-body {
    flex: 1 auto;
    overflow-y: auto;
  }

  .account-form-footer {
    height: 80px !important;
    min-height: 80px !important;
  }
}
