.angular-json-explorer {
  font-family: monospace;
  font-size: 13px;
  font-weight: 300;
  white-space: pre-wrap;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  .hidden {
    display: none;
  }
  .copy-path {
    // position: absolute;
    // top: 0;
    // right: 0;
    .label;
    .label-default;
    cursor: pointer;
    display: none;
    &:hover {
      .label-primary;
    }
  }
  .property {
    cursor: pointer;
    &:hover {
      .copy-path {
        display: inline-block;
      }
    }
  }
  .null {
    color: red;
  }
  .bool {
    color: blue;
  }
  .num {
    color: blue;
  }
  .string {
    color: green;
    white-space: pre-wrap;
    overflow-wrap: break-word;
  }
  .collapser {
    position: absolute;
    left: -1em;
    cursor: pointer;
    -moz-user-select: none;
    color: rgb(249, 39, 83);
    text-decoration: none;
  }
  .q {
    display: inline-block;
    width: 0;
    color: transparent;
  }
  li {
    position: relative;
  }
  .error {
    -moz-border-radius: 8px;
    border: 1px solid #970000;
    background-color: #f7e8e8;
    margin: 0.5em;
    padding: 0.5em;
  }
  .errormessage {
    font-family: monospace;
  }
  ul {
    list-style: none;
    margin: 0 0 0 2em;
    padding: 0;
  }
  .callback + #json {
    padding-left: 1em;
  }
  .callback {
    font-family: monospace;
    color: #a52a2a;
  }
  h1 {
    font-size: 1.2em;
  }
}
