.split-panes {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: absolute;
}

.split-panes > .split-handler {
  background: transparent;
  position: absolute;
  z-index: 999;
}

/* Horizontal */

.split-panes.horizontal > .split-handler {
  width: 4px;
  top: 0;
  left: 50%;
  bottom: 0;
  cursor: ew-resize;
}

.split-panes.horizontal > .split-pane1,
.split-panes.horizontal > .split-pane2 {
  position: absolute;
  height: 100%;
}

.split-panes.horizontal > .split-pane1 {
  width: 50%;
}

.split-panes.horizontal > .split-pane2 {
  left: 50%;
  right: 0;
  border-left: 1px solid @gray-light;
}

/* Vertical */

.split-panes.vertical > .split-handler {
  height: 4px;
  top: 50%;
  left: 0;
  right: 0;
  cursor: ns-resize;
}

.split-panes.vertical > .split-pane1,
.split-panes.vertical > .split-pane2 {
  position: absolute;
  width: 100%;
}

.split-panes.vertical > .split-pane1 {
  height: 50%;
}

.split-panes.vertical > .split-pane2 {
  top: 50%;
  bottom: 0;
  border-top: 1px solid @gray-light;
}
