.expressions {
  background-color: @gray-lighter;
  padding: 0;
  li {
    a {
      color: @text-color;
      padding: 4px 7px;
    }
  }
}
