schema-importer-data-preview {
  width: calc(100% - 20px);
}

.data-preview {
  .well {
    display: flex;
    justify-content: center;
    background: #ffffff;
  }

  .data-preview-header {
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .fa-exclamation-circle {
      margin-left: 5px;
      color: @gray-lighter;

      &:hover {
        color: @gray-light;
      }
    }

    .btn {
      width: auto;
      padding: 1px 10px;
    }

    .btn-group {
      margin-left: 10px;
    }
  }
  .data-preview-table {
    .data-preview-table-wrapper {
      width: 100%;
      max-height: 360px;
      border-radius: 5px;
      padding-bottom: 10px;

      .data-preview-table-index {
        text-align: center;
        font-weight: 400;
        background: #eceef0;
      }
      .schema-importer-table;
      .data-preview-table-index {
        width: 30px;
      }

      overflow: auto !important;
    }
  }
  .data-preview-json {
    border: 1px solid @gray-lighter;
    border-radius: 5px;
  }
}

.job-responses-table {
  .body-cell {
    position: relative;
    width: 620px;
    max-width: 620px;

    .expand-button {
      position: absolute;
      top: 15px;
      right: 15px;
      padding: 5px;
      font-size: 12px;
    }
  }
}
