@generic-list-item-height: 52px;
@generic-list-item-checkbox-width: 32px;
@generic-list-item-actions-width: 310px;
@generic-list-item-icon-width: @generic-list-item-height;
.generic-list-item(@generic-list-width) {
  .generic-list-item {
    position: relative;
    border-bottom: 1px solid #eceef0;

    &.clusters {
      display: inline-block;
    }

    &.workspaces,
    &.clusters {
      border: none;
    }

    &:first-child {
      border-top: none;

      &.workspaces,
      &.clusters {
        border-top: none;
      }
    }

    .dropdown {
      height: 100%;

      &.xp-dropdown {
        font-size: 30px;
        cursor: pointer;
        opacity: 0.5;
        margin-top: 6px;
        display: flex;
        justify-content: flex-end;

        &:hover,
        &[aria-expanded] {
          opacity: 0.8;
        }
      }

      &.xp-dropdown-full {
        font-size: 23px;
        margin-top: 0;
        height: 100%;

        display: flex;
        justify-content: center;
        align-items: center;

        .fa {
          cursor: pointer;
          opacity: 0.7;

          &:hover {
            opacity: 1;
          }
        }

        &.dropdown-disable {
          pointer-events: none;
          cursor: pointer;

          .fa {
            display: none;
          }
        }
      }

      &.open {
        div {
          opacity: 1;
          &:hover {
            opacity: 1;
          }
        }
      }
      div {
        cursor: pointer;
        height: 100%;
        width: 18px;
        background: @gray-light;
        opacity: 0.4;
        display: flex;
        justify-content: center;
        align-items: center;

        &:hover {
          opacity: 1;
        }
        i {
          width: 5px;
          height: 5px;
          display: block;
          border-radius: 50%;
          background: @gray-base;
          box-shadow: 0 10px @gray-base, 0 -10px @gray-base;
        }
        &:hover {
          .box-shadow(0);
        }
      }
    }
    .dropdown-menu {
      position: absolute;
      transform: translateX(-95%);
      &.connection-dropdown {
        transform: translateX(-5%);
      }
      top: 80%;
      overflow: hidden;
      &.top-position {
        top: auto !important;
        bottom: 80% !important;
      }
    }
    li {
      cursor: pointer;
      &.divider {
        padding: 0;
      }
      &:hover {
        background-color: lighten(@gray-lighter, 5%);
      }
      .icon-delete {
        & + span {
          color: @brand-danger;
        }
      }
      .icons-master-expanded_icon-list-archive {
        & + span {
          color: @brand-warning;
        }
      }

      &:hover {
        .icon-delete {
          & + span {
            color: @brand-danger;
          }
        }
        .icons-master-expanded_icon-list-archive {
          & + span {
            color: @brand-warning;
          }
        }
      }
      a {
        vertical-align: middle;
        color: @dropdown-link-color;
        display: inline-flex;
        .icon-delete {
          & + span {
            color: @brand-danger;
          }
        }
        .icons-master-expanded_icon-list-archive {
          & + span {
            color: @brand-warning;
          }
        }
        &:hover {
          background-color: inherit;
          .icon-delete {
            & + span {
              color: @brand-danger;
            }
          }
          .icons-master-expanded_icon-list-archive {
            & + span {
              color: @brand-warning;
            }
          }
        }
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        fill: #999999;
        &.icon-delete {
          color: @brand-danger;
          fill: @brand-danger;
        }
        &.icons-master-expanded_icon-list-archive {
          fill: @brand-warning;
          color: @brand-warning;
        }
      }
    }
    div {
      box-sizing: border-box;
    }
    &.clusters {
      float: left;
      .cluster-list-square {
        width: 240px;
      }
    }
    *[class*='-item'] {
      display: grid;
      flex-direction: row;

      *[class*='-checkbox'] {
        flex: 0 auto;
        width: 50px;
        height: 70px;
        text-align: center;
        cursor: pointer;
        i {
          color: @gray;
          line-height: 70px;
        }
        &:hover {
          i {
            color: @gray-base;
          }
        }
      }
      *[class*='-img'] {
        flex: 0 auto;
        width: 70px;
        height: 50px;
        img {
          width: 50px;
          height: 50px;
        }
      }
      *[class*='-body'] {
        flex: 1 auto;
        strong {
          font-size: 16px;
          line-height: 1;
          font-weight: 600;
        }
      }
      *[class*='-actions'] {
        flex: 0 auto;
        .btn {
          i {
            margin-right: 5px;
          }
          color: @text-color;
          text-decoration: none;
          &:hover {
            background-color: #f5f5f5;
          }
        }
        .fa-play {
          color: @brand-success;
        }
        .btn-danger,
        .fa-power-off {
          color: @brand-danger;
        }
        .fa {
          font-size: 16px;
        }
      }
    }
    &:not(.clusters) {
      > div.inactive {
        opacity: 0.5;
      }
      > div.pending-removal {
        background-color: @alert-danger-bg;
        &.package-list-item {
          background-color: @alert-warning-bg;
        }
        &.account-list-item {
          background-color: @alert-warning-bg;
        }
      }

      > div.selected {
        background-color: @alert-warning-bg;
        &.package-list-item {
          background-color: @alert-warning-bg;
        }
        &.account-list-item {
          background-color: @alert-warning-bg;
        }
      }
    }
    &.clusters {
      > div.pending-removal {
        .cluster-list-item-body {
          background-color: @alert-danger-bg;
        }
        .cluster-list-item-header,
        .cluster-list-item-footer {
          background-color: @alert-danger-bg;
          border-color: @alert-danger-text;
          button {
            border-top: 1px solid @alert-danger-text;
            background-color: @alert-danger-bg;
            &.cluster-list-item-run {
              border-left: 1px solid @alert-danger-text;
            }
          }
        }
        border-color: @alert-danger-text;
      }
    }
  }
  .package-list-item(@generic-list-width);
  .workspace-list-item(@generic-list-width);
  .connection-list-item(@generic-list-width);
  .cluster-list-item(@generic-list-width);
  .job-list-item(@generic-list-width);
  .schedule-list-item(@generic-list-width);
  .member-list-item(@generic-list-width);
  .account-list-item(@generic-list-width);
  .hook-list-item(@generic-list-width);
}

.generic-list-item-menu {
  &.bar {
    cursor: pointer;
    height: 100%;
  }
  svg {
    .bg {
      fill: @gray-light;
      opacity: 0.6;
    }
    circle {
      fill: darken(@gray-light, 30%);
    }
  }
  &:hover {
    svg {
      .bg {
        fill: lighten(@gray-light, 5%);
      }
    }
    circle {
      fill: @gray-base;
    }
  }
}
