.bing-ads-accounts {
  padding-bottom: 30px;
  .bing-ads-label {
    line-height: 18px;
    padding-left: 5px;
    color: @gray;
    font-weight: 300;
  }
  .bing-ads-list {
    border: 1px solid @gray-light;
    border-radius: 5px;
    .bing-ads-list-header {
      border-radius: 7px 7px 0 0;
      height: 32px;
      border-bottom: 1px solid @gray-light;
      background-color: #f9f9f9;
      position: relative;
      .search-btn {
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 1;
        button {
          background-color: transparent;
          border: 0 none;
        }
      }
      input {
        top: 4px;
        left: 4px;
        padding-left: 22px;
        position: absolute;
        border: 1px solid @gray-light;
        width: 50%;
        line-height: 19px;
        z-index: 0;
        border-radius: 4px 0 0 0;
      }
      .btn-remove {
        position: absolute;
        top: 5px;
        right: 5px;
        height: 22px;
        line-height: 16px;
        padding: 0 7px;
        color: @gray;
        i {
          font-size: 10px;
        }
      }
    }
    .bing-ads-list-scroll {
      padding: 10px;
      overflow-y: scroll;
      height: 400px;
      border-radius: 0 0 7px 7px;
    }
    .loader {
      margin-top: 70px;
    }
    .bing-ads-list-item {
      .highlighted {
        background-color: lighten(@brand-info, 15%);
      }
      i {
        cursor: pointer;
        width: 12px;
        text-align: center;
        &.fa-refresh {
          font-size: 12px;
          font-weight: 400;
        }
      }
      a {
        display: none;
      }
      &.loaded {
        strong {
          color: @text-color;
          font-weight: 600;
        }
      }
      strong {
        display: inline-block;
        padding-left: 3px;
        color: @gray;
        font-weight: 400;
        // &:hover {
        //     color: @brand-primary;
        // }
      }
      div.collapse-wrapper {
        padding: 2px 0 2px 33px;
        div {
          &.selected {
            span {
              color: @brand-primary;
            }
          }
          span {
            // &:hover {
            //     color: @brand-primary;
            // }
          }
        }
        small {
          display: inline-block;
          margin-bottom: 7px;
        }
      }
      &:hover {
        a {
          display: inline-block;
        }
      }
    }
  }
}
