.form-control {
  font-weight: 400 !important;
  font-size: 14px;

  .base-input-control {
    padding: 0;
  }

  &.xp-select {
    padding: 0 5px;

    .mat-select-arrow {
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid;
      margin: 0 4px;
    }
  }

  &.btn-default {
    width: 50%;
    display: block;
  }
}
.form-group {
  position: relative;

  label {
    .fa-exclamation-circle {
      margin-left: 5px;
    }
  }

  .gray-text {
    color: #767676;
  }

  .file-name {
    margin-left: 53%;
    display: block;
    position: absolute;
    top: 35px;
    color: #cccccc;
  }

  .form-control-info {
    position: absolute;
    top: 38px;
    right: -20px;
    font-size: 16px;
    color: @gray-lighter;
    &:hover {
      color: @gray-light;
    }
  }

  xp-loader-circle {
    position: absolute;
    right: 10px;
    top: 38px;
    opacity: 0.5;
  }

  .tooltip {
    max-width: 200px !important;
    .tooltip-inner {
      width: 200px !important;
      max-width: 200px !important;
      font-size: 13px !important;
      font-style: italic;
      text-align: left;
    }
  }
}

.bootstrap-select {
  & > .dropdown-toggle {
    line-height: @input-line-height-base;
    &.btn-lg {
      line-height: @input-line-height-large;
    }
    &.btn-sm {
      line-height: @input-line-height-small;
    }
  }
  &.open > .dropdown-toggle.btn-default {
    color: @gray-base;
    background-color: @input-bg;
    border-color: @gray-light;
  }
}

.label {
  font-weight: 400;
}

textarea {
  max-width: 100%;
}

textarea {
  &.textarea-lg {
    font-size: 18px;
  }
}

textarea,
select,
input {
  color: @gray-base !important;
}

.input-group-addon {
  .btn-default;
  cursor: pointer;
}

label {
  font-weight: 400;
  color: @gray-base;
}

.form-group {
  small {
    padding: 5px 0;
    display: inline-block;
    font-size: 13px !important;
    color: @gray;
    font-weight: 300;
  }
}

.input-danger {
  border-color: @brand-danger;
  background-color: lighten(@brand-danger, 35%);
}

textarea:hover,
input:hover,
textarea:active,
input:active,
textarea:focus,
input:focus,
button:focus,
button:active,
button:hover {
  outline: 0 !important;
  -webkit-appearance: none;
}

.alert {
  border-right: none;
  border-top: none;
  border-bottom: none;
  border-left: 2px solid;
  max-height: calc(100vh - 200px);

  &.dialog {
    border: 1px solid transparent;
    background: #ffffff;
    box-shadow:
      0 20px 24px -4px rgba(16, 24, 40, 0.08),
      0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
  }
  .fa-stack {
    margin-right: 0.25rem;
    font-size: 75%;
    margin-top: -0.25rem;
  }

  &.alert-info {
    border: 1px solid #0a4a97;
  }
}

pre {
  &.input {
    color: @input-color;
    background-color: @input-bg-disabled;
    border-color: @input-border;
    &:hover {
      border-color: @input-border-focus;
    }
  }
}

input[readonly] {
  cursor: not-allowed;
  background-color: #e6e6e6;
  opacity: 1;
}

xp-input[readonly='true'] {
  cursor: not-allowed;
  background-color: #e6e6e6;
  opacity: 1;
}
.form-control[disabled='false'] {
  cursor: default !important;
  background-color: #fff !important;
  opacity: 1;
}

.form-control::-webkit-input-placeholder,
.CodeMirror-wrap .CodeMirror-placeholder {
  -webkit-font-smoothing: antialiased;
}
