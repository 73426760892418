list-item-snippet {
  display: flex;
  justify-content: center;
}

.generic-list-item-snippet {
  display: flex;
  align-items: center;
  text-align: left;
  justify-content: space-between;
  color: @text-color;
  width: fit-content;
  background: #f5f7f9;
  border-radius: 20px;

  .item-snippet-wrapper {
    min-width: 0;
    display: flex;
    flex: 3;
    padding: 5px;

    &.inactive {
      opacity: 0.5;
    }
  }
  .item-snippet-container {
    display: flex;
    align-items: center;
    width: auto;
  }

  .item-snippet-icon {
    max-width: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;

    img {
      max-width: 32px;
    }
  }
  &.package-snippet {
    .item-snippet-icon {
      svg {
        &.icon {
          color: #999;
        }
      }
    }
  }
  &.member-snippet {
    .item-snippet-icon {
      img {
        max-width: 48px;
      }
    }
  }
  &.account-snippet {
    .item-snippet-icon {
      img {
        max-width: 48px;
      }
    }
  }
  &.hook-snippet {
    .item-snippet-icon {
      img {
        max-width: 48px;
      }
    }
  }
  &.connection-snippet {
    .item-snippet-icon {
      img {
        max-width: 48px;
      }
    }
  }
  .item-snippet-name {
    font-weight: 600;
    padding: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-flow: column;
    align-items: flex-start;

    .item-snippet-description {
      margin: 0;
      color: @gray;
      font-weight: 300;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .inactive {
      color: #808080;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      margin-left: 3px;
    }
    code {
      padding: 0;
      background-color: transparent;
    }
  }
  .item-snippet-owner {
    padding: 15px;
  }
}
