.cluster-form {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: 100%;

  .cluster-form-body {
    flex: 1 auto;
    overflow-y: auto;

    @media (max-width: @screen-sm) {
      padding: 5px;
    }
  }
  .cluster-form-footer {
    position: relative !important;
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
  }
  .cluster-form-slider {
    text-align: center;
    padding: 0 30px;
    strong {
      font-size: 15px;
      display: block;
      padding-bottom: 5px;
    }
    small {
      font-size: 13px;
      display: block;
      padding-bottom: 20px;
    }
  }
  .cluster-form-cat {
    min-height: 120px;
    text-align: center;
    position: relative;

    span {
      display: block;
      font-size: 14px;
      color: @brand-primary;
      font-weight: 600;
    }
    .cluster-form-cat-nodes {
      position: absolute;
      top: 45px;
      left: 50%;
      font-size: 35px;
      font-weight: 300;
      transform: translate(90px, 0);
    }
  }
  .mat-mdc-slider {
    width: 450px;
  }

  .form-group {
    .cluster-form-terminate-checkbox {
      margin: 10px 0;
      display: flex;
      justify-content: center;
    }

    &.cluster-form-terminate {
      display: flex;
      flex-direction: column;
      padding: 20px 10px;
      margin-bottom: 0;
      align-items: center;

      @media (max-width: @screen-sm) {
        padding: 5px;
        width: auto;
      }
      div {
        &.cluster-form-terminate-checkbox {
          input {
            margin-right: 7px;
          }
          label {
            text-transform: none;
          }
        }
        &.cluster-form-terminate-select {
          display: flex;
          flex-direction: row;

          .cluster-form-terminate-select-label {
            display: block;
            width: 50px;
            line-height: 40px;
            margin-right: 5px;
          }
          .cluster-form-terminate-select-control {
            display: block;
            flex: 1 auto;
            width: 300px;
          }
        }
      }
    }
    &.re-use {
      display: flex;
      padding: 0 10px 20px;

      .cluster-form-re-use-select-label {
        display: block;
        width: 50px;
        line-height: 40px;
        margin-right: 5px;
        min-width: 50px;
      }
      div {
        flex: 1 auto;
        small {
          padding: 5px 10px;
          display: block;
          height: 36px;
          min-height: 36px;
        }
      }
    }
  }
}
