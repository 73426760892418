.settings {
  max-width: 100%;

  @media (max-width: @screen-sm) {
    width: 100%;
    max-width: 100%;
  }

  .form-group {
    max-width: 600px;
  }

  &.container {
    margin: 0;
  }
  .settings-header {
    padding: 10px 20px;
    display: none;
  }
  .settings-body {
    padding: 20px 20px 0 20px;
    &.billing {
      padding: 40px 20px 0 20px;
      margin-bottom: 20px;
    }

    &.settings-tokens {
      max-width: 800px;

      .btn-info {
        margin-right: 10px;
      }
    }

    &.settings-mfa,
    &.settings-password {
      max-width: 800px;
    }
  }
  .account-actions {
    .btn-group {
      button {
        &:last-child {
          padding-right: 0;
        }
      }
    }
  }
  h2 {
    border-bottom: 1px solid lighten(@gray-light, 5%);
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: 600;
    line-height: 14px;
    padding: 0 0 7px;
    margin: 7px 0 20px;
    color: @brand-primary;
  }
  .delete {
    width: 600px;
    .btn {
      &:not(:nth-child(2)) {
        margin-left: 20px;
      }
    }
  }
  hr {
    border-top-color: lighten(@gray-light, 5%);
  }
  .small {
    color: @gray;
    .text-danger {
      color: #ed1c24;
    }
  }
  .table {
    thead {
      tr {
        th {
          font-weight: 400;
        }
      }
    }
    tbody {
      tr {
        td {
          vertical-align: middle;
        }
      }
    }
  }
  .nav-stacked {
    li {
      margin: 0;
      border-top: 1px solid #eee;
      &:first-child {
        border-top: 0 none;
      }
      &:focus {
        border-top: 1px solid #eee;
      }
      a {
        border-radius: 0;
        margin-top: -1px;
        background-color: transparent;
        color: @gray;
        border-left: 3px solid #fff;
        font-size: 14px;
        font-weight: 300;
        padding: 16px 18px;
        transition: border-color 50ms ease;
        &:active,
        &:focus {
          background-color: transparent;
          color: @gray-darker;
        }
        &:hover {
          border-left-color: @gray-light;
        }
        .badge {
          float: right;
          color: #fff;
          background-color: @gray-light;
        }
      }
      &.active {
        a {
          border-left-color: @brand-primary;
        }
      }
    }
  }
  .list-webhooks {
    list-style-type: none;
    padding-left: 20px;
  }
  .media {
    overflow: visible;
    .media-body {
      overflow: visible;
    }
  }
  .members-add {
    padding-bottom: 20px;
  }
  .members-item {
    padding: 40px;
    img {
      float: left;
      margin-right: 40px;
      max-width: 140px;
    }
    h1 {
      padding: 20px 0 0;
    }
  }

  .form-control-feedback {
    top: 38px !important;
    position: absolute;
    right: 0;
    cursor: pointer;
    z-index: 1;
    pointer-events: all;
  }

  .public-key-container {
    max-width: 600px;
  }
}
