run-package-editor {
  #package-version-picker {
    margin-top: 20px;
  }
}

.form-group-options {
  padding-left: 5px;
  .radio {
    padding-bottom: 5px;
  }
}
