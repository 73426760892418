.key-list-item {
  border-bottom: 1px solid @gray-light;
  padding: 30px;
  grid-template-columns: 1fr 80px;

  .icon-delete {
    width: 16px;
    height: 16px;
    cursor: pointer;
    color: @gray-light;
    transform: translateY(-50%);
    top: 50%;
    position: absolute;
    right: 20px;
    &:hover {
      color: @brand-danger;
    }
  }
}
