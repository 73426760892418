.navbar {
  .flex {
    display: flex;
    justify-content: space-between;
    padding: 6px 10px 0 5px;

    .flex-box {
      flex: 0 auto;
      &.navbar-sidebar-toggle {
        width: 60px;
      }
      &.navbar-search {
        flex: 1 auto;
        width: 100%;
      }
      &.navbar-new-btn {
        width: 90px;
        button {
          float: right;
        }
      }
    }
  }
}
