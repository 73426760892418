@numbers-column-width: 90px;
.job-outputs {
  padding: 30px 0 0 0 !important;
  .job-outputs-header {
    display: flex;
    border-bottom: 1px solid @gray-light;
    .job-outputs-header-toggle {
      flex: 0 auto;
      width: @numbers-column-width / 4;
    }
    .job-outputs-header-id {
      flex: 0 auto;
      width: @numbers-column-width;
      text-align: center;
    }
    .job-outputs-header-name {
      flex: 1 auto;
      padding-left: 20px;
    }
    .job-outputs-header-records {
      text-align: center;
      flex: 0 auto;
      width: @numbers-column-width;
    }
    .job-outputs-header-id,
    .job-outputs-header-name,
    .job-outputs-header-records {
      span {
        display: inline-block;
        padding: 6px;
        text-transform: uppercase;
        font-size: 12px;
      }
    }
  }
  .job-output-item {
    border-bottom: 1px solid @gray-light;
    &:nth-of-type(odd) {
      background-color: @table-bg-accent;
    }
    .job-output-item-grid {
      display: flex;
      pointer-events: none;
      .job-outputs-header-toggle {
        flex: 0 auto;
        width: @numbers-column-width / 4;
        text-align: right;
        i {
          line-height: 62px;
        }
      }
      .job-output-item-id {
        flex: 0 auto;
        width: @numbers-column-width;
        text-align: center;
      }
      .job-output-item-name {
        flex: 1 auto;
        font-size: 16px;
        padding-left: 20px;
      }
      .job-output-item-records {
        flex: 0 auto;
        width: @numbers-column-width;
        text-align: center;
      }
      .job-output-item-id,
      .job-output-item-records {
        span {
          display: inline-block;
          padding: 20px 6px;
        }
      }
      .job-output-item-name {
        display: flex;
        svg {
          margin-top: 15px;
          margin-left: 6px;
          flex: 0 auto;
        }
        div {
          flex: 1 auto;
          display: flex;
          flex-direction: column;
          transform: translate(15px, 10px); //    transform: translate(10px, -10px);
          span {
            display: inline-block;
            font-weight: 600;
            width: 900px;
            max-width: 900px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          small {
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
    &.can-preview {
      .job-output-item-grid {
        cursor: pointer;
        pointer-events: auto;
        &:hover {
          background-color: lighten(@gray-lighter, 5%);
        }
      }
    }
    .job-output-item-preview {
      border-top: 1px solid @gray-lighter;
      background-color: lighten(@gray-lighter, 5%);
      .data-preview-header {
        //display: none;
        height: 50px;
        padding: 10px;
        strong {
          display: inline-block;
          line-height: 30px;
        }
      }
      pre {
        border-radius: 0;
        margin-bottom: 0 !important;
        min-height: 260px;
      }
      .alert {
        margin-bottom: 0 !important;
        border-radius: 0;
      }
      .btn-group {
        .pull-right;
        .clearfix;
      }
    }
  }
}
