.cluster-icon {
  width: 3em;
  height: 3em;
  color: @brand-primary;
  border-radius: 0.2em;
  border: 0.2em solid @brand-primary;
  text-align: center;
  background-color: lighten(@brand-primary, 45%);
  span {
    font-size: 1em;
    line-height: 2.7em;
    font-weight: 700;
    &.sandbox {
      display: none;
    }
  }
  &.sandbox {
    border-color: @brand-info;
    background-color: lighten(@brand-info, 25%);
    span {
      color: @brand-info;
      &.sandbox {
        display: inline-block;
        font-size: 1.2em;
        line-height: 2.3em;
        font-weight: 700;
      }
      &.production {
        display: none;
      }
    }
  }
}
