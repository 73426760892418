members-invitation,
members-confirmation,
reset-password {
  z-index: 2;
}

.members-invitation {
  .checkbox {
    padding-left: 0 !important;
  }
  small {
    font-weight: 300;
    display: block;
    padding: 10px 5px;
    color: @gray;
  }
}

.email-confirmation {
  small {
    margin-bottom: 20px;
    margin-top: -20px;
  }
}
