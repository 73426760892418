.base-editor {
  //min-width: @modal-lg;
  display: flex;
  flex-direction: column;
  height: 100%;
  .base-editor-tabs {
    flex: 0 auto;
    height: 50px;
  }
  .base-editor-body {
    overflow-y: scroll;
    flex: 1 auto;
    position: relative;
    .base-editor-connections {
      padding: 30px 80px;
      .generic-list-modal {
        padding: 0 !important;
        .generic-list-body {
          .connection-list-item {
            margin-left: 25px;
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  .base-editor-footer {
    flex: 0 auto;
    height: 80px;
    min-height: 80px;
  }
  h4 {
    margin: 20px 5px 10px;
    font-size: 18px;
  }
  .explanation {
    display: inline-block;
    padding: 0 5px 10px;
  }
  .bootstrap-select {
    button {
      padding: 12px 12px;
      font-size: 15px;
      line-height: 15px;
      color: @text-color;
    }
  }
  .tab-content {
    position: relative;
    padding-bottom: 50px;
    overflow-y: scroll;
  }
  .tab-content-shadow {
    position: absolute;
    margin-top: -30px;
    height: 30px;
    width: 100%;
    .box-shadow(inset 0 -30px 30px -30px rgba(0, 0, 0, 0.3));
    pointer-events: none;
  }
  .base-editor-body {
    form {
      padding: 60px 120px;
    }
  }
  .base-editor-column-mappings {
    padding: 60px 120px;
  }
  .base-editor-body-transformation {
    background-color: #fff;
  }
  .form-group {
    label:not(.checkbox) {
      line-height: 18px;
      padding-left: 5px;
      color: @gray;
      font-weight: 300;
    }
  }
  .margin-bottom {
    .clearfix;
    //margin-bottom: 20px;
  }
  .CodeMirror {
    height: auto;
    padding: 0;
    min-height: 96px;
    .box-shadow(0px 0px);
    .CodeMirror-scroll {
      min-height: 98px;
    }
    .CodeMirror-code {
      min-height: 82px;
      font-family: 'Menlo', sans-serif;
      color: @text-color;
    }
    .CodeMirror-gutters {
      min-height: 98px;
      min-width: 29px;
    }
    .CodeMirror-placeholder {
      color: #ccc;
    }
  }
  .component-wrapper {
    // border: 1px solid fade(@gray-light, 25%);
    // background-color: fade(@gray-light, 15%);
    padding: 8px 8px 0 8px;
  }
  .tab-content {
    background-color: #fff;
    //padding: 0 20px;
    .tab-pane {
      padding: 20px;
      //background-color: @gray-lighter;
    }
  }
  .step-1,
  .step-2,
  .step-3,
  .step-4,
  .step-5 {
    padding: 20px 80px 40px;
    //border-radius: 5px;
    margin: 0 -20px;
  }
  .step-1 {
    background-color: #fff;
  }
  .step-2 {
    background-color: darken(#fff, 3%);
  }
  .step-3 {
    background-color: darken(#fff, 5%);
  }
  .step-4 {
    background-color: darken(#fff, 7%);
  }
  .step-5 {
    background-color: darken(#fff, 9%);
  }
  .generic-list {
    &.generic-list-connections {
      .generic-list-body {
        padding: 20px 55px;
        .generic-list-item {
          &.connections {
            cursor: pointer;
            span {
              cursor: pointer;
            }
            .connection-bar {
              display: none;
            }
            .connection-logo {
              pointer-events: none;
            }
            &:hover {
              .connection-logo {
                border-bottom-color: @gray;
              }
              .connection-border {
                fill: @gray;
                &.connection-border-putzka {
                  stroke: @gray;
                  fill: transparent;
                }
              }
            }
            &.selected {
              .connection-logo {
                border-bottom-color: @brand-primary;
              }
              .connection-border {
                fill: @brand-primary;
                &.connection-border-putzka {
                  stroke: @brand-primary;
                  fill: @brand-primary;
                }
              }
            }
          }
        }
      }
    }
  }
  .modal-footer {
    .component-name {
      transform: translate(52px, -5px);
      width: 55%;
      margin-top: 24px;
      position: relative;
      input {
        border: 0 none;
        border-bottom: 1px solid @gray-lighter;
        font-size: 22px;
        line-height: 32px;
        font-weight: 600;
        width: 100%;
      }
      &.invalid {
        input {
          border-bottom: 1px solid @brand-danger;
          background-color: fade(@brand-warning, 10%);
        }
      }
      &:after {
        font-family: 'FontAwesome', sans-serif;
        content: '\f040';
        color: @gray;
        display: block;
        font-size: 16px;
        position: absolute;
        right: 6px;
        top: 6px;
      }
      .tooltip {
        background-color: fade(@brand-warning, 10%);
        white-space: nowrap;
        .tooltip-arrow {
          border-right-color: @brand-danger;
        }
        .tooltip-inner {
          background-color: fade(@brand-warning, 10%);
          border-color: @brand-danger;
          color: @brand-danger;
        }
      }
    }
  }
}
