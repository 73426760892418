.packages-picker {
  svg {
    width: 24px;
    height: 24px;
  }

  .packages-picker-list {
    padding: 20px 0;

    xp-package {
      display: block;
      border-radius: 0;
      border-bottom: 0 none;
      background: #ffffff;
      padding: 15px;

      &:first-child {
        border-radius: 8px 8px 0 0;
      }
      &:last-child {
        border-radius: 0 0 8px 8px;
        border-bottom: 1px solid @gray-light;
      }
      &:only-child {
        border-radius: 8px;
      }
    }
  }
  small {
    font-size: 13px;
    font-weight: 300;
    color: @gray;
  }
}
