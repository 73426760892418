@workspace-list-item-padding: 40px;
@workspace-list-item-actions-width: 120px;
.workspace-list-item(@generic-list-width) {
  &.workspace {
    padding-bottom: 140px;
  }
  .workspace-list-item {
    height: 80px;
    padding-left: 40px;
    border-radius: 20px;
    border: 1px solid #c2c2c2;
    margin-left: 35px;
    margin-bottom: 15px;
    background-color: #f6f6f6;

    .workspace-background {
      display: none;
    }

    .workspace-name {
      align-items: center;
      display: flex;
      margin-top: 60px;
      z-index: 1;

      .icon {
        width: 23px;
        height: 23px;
        &.icon-workflow {
          height: 32px;
        }
        float: left;
        margin-right: 15px;
        color: #999;
      }
      .name-description-container {
        display: flex;
        flex-direction: column;
        word-break: break-all;
        word-wrap: break-word;
        max-width: 100%;

        .name-container {
          padding-bottom: 3px;
          height: 26px;
          overflow: hidden;

          .workspace-title {
            color: @gray-base;
            font-size: 16px;
            font-weight: 600;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 100%;
            display: block;

            &:hover {
              text-decoration: underline;
            }
          }
          .inactive {
            color: @gray;
            font-weight: 300;
            font-size: 12px;
            line-height: 20px;
            margin-left: 3px;
          }
        }
        .workspace-description {
          color: @gray;
          font-weight: 300;
          font-size: 13px;
          margin: 0 0 3px;
          height: 37px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
          display: block;
        }
      }
    }
    .workspace-owner {
      align-items: center;
      display: flex;
      padding-right: 20px;
      flex: 1;

      span {
        display: block;
        color: @gray;
        font-weight: 300;
        font-size: 14px;
        padding-bottom: 3px;
      }
      a {
        color: @gray-base;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .workspace-date {
      align-items: center;
      display: flex;
      flex: 1;
      color: @gray;
      font-weight: 300;
      font-size: 13px;
    }
    .packages-statuses {
      display: flex;
      flex-flow: row;
      align-items: center;
      justify-content: flex-end;
      margin-right: 20px;
      position: absolute;
      right: 25px;
      top: 35px;

      .packages-statuses-container {
        background-color: #fff;
        height: 26px;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
      }

      .packages-status {
        color: grey;
        height: 100%;
        display: inline-block;
        font-size: 13px;
        margin: 0 2px;
        position: relative;
        padding: 3px 3px 3px 15px;

        &.total {
          border-right: 1px solid #e4e4e4;
          padding-right: 10px;
          background: #f5f7f9;
          border-bottom-left-radius: 8px;
          border-top-left-radius: 8px;
          margin-left: 0;
          padding-left: 8px;

          &:before {
            content: none;
          }
        }

        &.failed {
          &:before {
            color: #f00;
            content: '\58';
            font-size: 10px;
          }
        }

        &.pending {
          &:before {
            color: #636363;
            content: '\f04c';
            font-size: 7px;
          }
        }

        &.success {
          &:before {
            color: #0f0;
            content: '\f00c';
            font-size: 10px;
          }
        }

        &:before {
          font-family: 'FontAwesome', sans-serif;
          content: '';
          display: inline-block;
          position: absolute;
          margin: 0;
          left: 2px;
          top: 5px;
          line-height: 15px;
        }
      }
    }

    .workspace-icon {
      display: flex;
      flex-flow: row;
      align-items: center;
      width: 100px;

      .workspace-icon-container {
        padding: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #3d4651;
        border-radius: 10px;

        .icon {
          fill: transparent;
          stroke: #ffffff;
        }
      }
    }

    .workspace-description {
      display: none;
    }

    .dropdown {
      div {
        background: none;
        i {
          background: #232323;
          box-shadow: 0 10px #232323, 0 -10px #232323;
        }
      }
    }
  }

  .workspace-list-item.folders {
    width: 280px;
    height: 170px;
    margin-left: 0;
    flex-direction: column;
    padding: 15px;
    position: relative;
    border: none;
    background-color: transparent;
    display: flex;

    .workspace-background {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      z-index: 0;
    }

    .workspace-icon {
      position: absolute;
      top: 30px;
      left: 15px;

      &-container {
        img {
          width: 15px;
          height: 15px;
        }
      }
    }

    .dropdown {
      position: absolute;
      height: 30px;
      padding: 5px;
      top: 27px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .fa {
        font-size: 24px;
      }
    }

    &:hover {
      .workspace-name .workspace-title {
        text-decoration: underline;
      }
    }

    .workspace-date {
      display: none;
    }

    .workspace-owner {
      display: none;
    }

    .workspace-description {
      display: block;
      z-index: 1;
      font-size: 11px;

      .workspace-owner-description {
        color: #000000;
      }
    }
  }
}

.generic-list-body.workspaces.folders {
  max-width: 100%;
  flex-wrap: wrap;
  margin-left: 0;
  justify-content: space-between;
  border: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, 280px);

  @media (max-width: @screen-sm) {
    justify-content: center;
  }

  workspace-list-item {
    .generic-list-item {
      border-top: none;
    }
  }
}
