.variable-form-control {
  height: 34px;
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6 {
    .btn:not(.btn-link) {
      transition: opacity 250ms ease;
      width: 45%;
      margin-left: 5%;
      display: block;
      opacity: 0.5;
      float: left;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      &:hover {
        opacity: 1;
      }
    }
    .btn-link {
      margin-left: -20px;
      &:disabled {
        color: @gray-light;
        font-style: italic;
      }
    }
    .variable-equal {
      position: absolute;
      top: 0;
      right: -2%;
      font-size: 24px;
      text-decoration: none;
      border-bottom: 0 none;
    }
  }
  &:hover {
    .btn {
      opacity: 0.8;
    }
  }
}
